type a11yPropsType = {
    id: string;
    'aria-controls': string;
}
export const a11yProps = (index: number): a11yPropsType => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const formatPrice = (price: string): string => {
    if ((Math.round(parseInt(price)).toString().length % 2) == 0) {
        if (Math.round(parseInt(price)).toString().length == 4) {
            const newPrice = price.slice(0, 1) + " " + price.slice(1, price.length);
            return newPrice;
        }
        if (Math.round(parseInt(price)).toString().length == 6) {
            const newPrice = price.slice(0, 3) + " " + price.slice(3, 6);
            return newPrice;
        }
        return price;
    }
    if ((Math.round(parseInt(price)).toString().length % 2) == 1) {
        if (Math.round(parseInt(price)).toString().length == 5) {
            const newPrice = price.slice(0, 2) + " " + price.slice(2, price.length);
            return newPrice;
        }
        if (Math.round(parseInt(price)).toString().length > 5) {
            const newPrice = price.slice(0, 3) + " " + price.slice(3, 6) + " " + price.slice(6, price.length);
            return newPrice;
        }
        return price;
    }

    return price;
}