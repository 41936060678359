export const checkpoints = [
  {
    route: "/",
  },
  {
    route: "/services",
  },
  {
    route: "/services/repairs",
  },
  {
    route: "/services/checkout",
  },
];
