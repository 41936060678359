/* eslint-disable @typescript-eslint/no-explicit-any */
import { Info } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { getServiceCluster } from "../../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import ServicesInfoDialog from "../../../../../library-components/Dialogs/ServicesInfoDialog";
import { ServiceClusterType } from "../../../../enumerations/enumerations";
import { formatPrice } from "../../../PanelFunctions";
import AddRemoveButton from "../../SubComponents/AddRemoveButton";
import { useStylesServicingCartOptions } from "../../Styles/ServicingStyles";
import { useHistory } from "react-router-dom";

const ServicingAndMOTCartOptionsMobile: FC = () => {
  const [open, setOpen] = useState(false);
  const classes = useStylesServicingCartOptions();

  const [serviceId, setServiceId] = useState("");
  const heavyCampaigns = ["fleggaardleasing0"]

  const [mileage, setMileage] = useState("");
  const [serviceDsc, setServiceDsc] = useState("");

  const { state } = useContext(GlobalContext);
  const history = useHistory();
  const [readyToRender, setReadyToRender] = useState(false)
  const [activeButtons, setActiveButtons] = useState<number[]>([]);

  const [loadServiceCluster, result] = useLazyQuery<Partial<any>>(
    getServiceCluster,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleDialog = (serviceId: string, serviceDsc: string) => {
    setServiceId(serviceId);
    setServiceDsc(serviceDsc);
    setOpen(!open);
  };

  useEffect(() => {
    loadServiceCluster({
      variables: {
        service_cluster: ServiceClusterType.Servicing,
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  }, []);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getServiceCluster?.node === null) {
        alert(result?.data?.getServiceCluster?.message);
      }
      if (result?.data?.getServiceCluster?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  useEffect(() => {
    result?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
      cat.service_subcategory?.map((cat: any, index: any) => {
        cat?.service_details.map((cat: any) => {
          if (state.queryParams?.service_ids?.includes(cat.service_id)) {
            const b = activeButtons;
            b.push(cat.service_id)
            setActiveButtons(b)
          }
        })
      })
    })
    setReadyToRender(result?.data?.getServiceCluster?.node?.length > 0)
  }, [result?.data]);

  return (
    <Grid item container md={12} className={classes.rootMobile}>
      {readyToRender && result?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
        return (
          <Grid container key={index}>
            {cat.service_subcategory?.map((cat: any, index: any) => {
              return (
                <Grid item container key={index}>
                  {cat?.service_details.map((cat: any) => {
                    if (cat.service_dsc === "Basis service") {
                      if (!heavyCampaigns.includes(state.basketData?.campaign_code)) {
                        return (
                          <>
                            <Grid
                              item
                              container
                              key={cat.service_dsc}
                              className={classes.cardWrapper}
                            >
                              <Grid item className={classes.cardHeader}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontWeight={"Bold"}
                                  fontSize={14}
                                >
                                  {cat.service_dsc}
                                </Typography>
                                <IconButton
                                  className={classes.infoButton}
                                  onClick={() =>
                                    handleDialog(cat.service_id, cat.service_dsc)
                                  }
                                >
                                  <Info className={classes.infoButtonIcon} />
                                </IconButton>
                              </Grid>
                              <Grid item className={classes.cardText}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontWeight={"Regular"}
                                  fontSize={12}
                                >
                                  {cat.service_long_dsc}
                                </Typography>
                              </Grid>
                              <Grid item className={classes.cardPrice}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontSize={24}
                                  className={classes.cardPriceTextWeight}
                                >
                                  {!cat.hide_price && formatPrice(
                                    cat.total_price === "0"
                                      ? ""
                                      : cat.total_price + " DKK"
                                  )}
                                </Typography>
                                <AddRemoveButton
                                  serviceId={cat.service_id}
                                  mileage={mileage}
                                  defaultActive={activeButtons.includes(cat.service_id.toString())}
                                  setMileage={(mileage: string) =>
                                    setMileage(mileage)
                                  }
                                  ifMileage={cat.mileage}
                                />
                              </Grid>
                            </Grid>
                          </>
                        );
                      }
                    } else if (cat.service_dsc !== "Basis service") {
                      return (
                        <>
                          <Grid
                            item
                            container
                            key={cat.service_dsc}
                            className={classes.cardWrapper}
                          >
                            <Grid item className={classes.cardHeader}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontWeight={"Bold"}
                                fontSize={14}
                              >
                                {cat.service_dsc}
                              </Typography>
                              <IconButton
                                className={classes.infoButton}
                                onClick={() =>
                                  handleDialog(cat.service_id, cat.service_dsc)
                                }
                              >
                                <Info className={classes.infoButtonIcon} />
                              </IconButton>
                            </Grid>
                            <Grid item className={classes.cardText}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontWeight={"Regular"}
                                fontSize={12}
                              >
                                {cat.service_long_dsc}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.cardPrice}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontSize={24}
                                className={classes.cardPriceTextWeight}
                              >
                                {!cat.hide_price && !heavyCampaigns.includes(state.basketData?.campaign_code) && formatPrice(
                                  cat.total_price === "0"
                                    ? ""
                                    : cat.total_price + " DKK"
                                )}
                              </Typography>
                              <AddRemoveButton
                                serviceId={cat.service_id}
                                mileage={mileage}
                                defaultActive={activeButtons.includes(cat.service_id.toString())}
                                setMileage={(mileage: string) =>
                                  setMileage(mileage)
                                }
                                ifMileage={cat.mileage}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    }
                  })}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
      <ServicesInfoDialog
        open={open}
        close={() => setOpen(false)}
        content={{ service_id: serviceId, service_dsc: serviceDsc }}
      />
    </Grid>
  );
};

export default ServicingAndMOTCartOptionsMobile;
