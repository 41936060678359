/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { computeService } from "../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { ComputeServiceDataResponse } from "../../../../../interfaces-and-unionTypes/Interfaces";
import { SimpleButton } from "../../../../library-components/Buttons/Buttons";
import InfoDialog from "../../../../library-components/Dialogs/InfoDialog/InfoDialog";
import ServiceLoaderDialog from "../../../../library-components/Dialogs/ServiceLoaderDialog";
import InputField from "../../../../library-components/InputFields/InputFields";
import { MOTButtonProps } from "../Types/Types";
import { useStylesDesktopServicingAddRemoveButtons } from "../Styles/ServicingStyles";
import { useHistory } from "react-router-dom";

const AddRemoveButton: FC<MOTButtonProps> = (props) => {
  const { serviceId, mileage, setMileage, ifMileage , defaultActive } = props;
  const classes = useStylesDesktopServicingAddRemoveButtons();

  const { state, dispatch } = useContext(GlobalContext);
  const [basket, setBasket] = useState(state.basketData?.service_details || []);
  const [open, setOpen] = useState(false);

  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [mileageLocal, setMileageLocal] = useState("");

  const [error, setError] = useState("");

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      if (data.computeService?.node !== null)
        dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });

      setLoading(false);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);
  const handleAddClick = (serviceId: string, mileage: string) => {
    setLoading(true);
    computedService({
      variables: {
        is_selected: 1,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        mileage: mileage,
        tyre_data: null,
      },
    });

    if (result.data?.computeService?.node !== null) {
      setRemove(true);
    }
  };

  useEffect(() => {
    if(defaultActive) {
      if (ifMileage.trim() === "1") {
        setOpen(!open);
      }
    }
  }, [])

  const validateInput = () => {
    if (mileageLocal.trim() == "") {
      setError(
        "Indtast venligst bilens kilometerstand for at vælge denne service."
      );
      return false;
    }
    if (parseInt(mileageLocal.trim()) < 1) {
      setError("You must enter a positive value.");
      return false;
    }
    return true;
  };

  const handleRemoveClick = (serviceId: string, mileage: string) => {
    setLoading(true);
    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: mileage,
        tyre_data: null,
      },
    });
    dispatch({
      type: "REMOVE_FROM_BASKET",
      payload: result.data?.computeService?.node,
    });
    setMileageLocal("");
    setMileage("");
    setRemove(false);
    const removed = basket.filter((el: any) => el.sa_id !== serviceId);
    setBasket(removed);
  };
  useEffect(() => {
    if (state.serviceId === serviceId) {
      setRemove(false);
      dispatch({
        type: "REMOVE_SERVICE_FROM_BASKET",
        payload: "",
      });

      const removed = basket.filter((el: any) => el.sa_id !== serviceId);
      setBasket(removed);
    }
  }, [state.serviceId, state.basketData]);
  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // Call the validate function inside the Buttons component
      if (
        ifMileage.trim() === "1" &&
        mileage.trim() !== "" &&
        error.trim() === ""
      ) {
        if (validateInput()) {
          setOpen(!open);
          handleAddClick(serviceId, mileage);
        }
      } else {
        validateInput();
      }
    }
  };

  useEffect(() => {
    if(basket.find((el: any) => el.sa_id === serviceId)) {
      setRemove(true)
    }
  },[])

  const input = () => {
    return (
      <Grid className={classes.guaranteeServiceRoot} item container md={12} onKeyDown={handleEnterKeyPress}>
        <Grid
          item
          container
          md={9}
          className={classes.guaranteeServiceInputFieldWrapper}
        >
          <InputField
            label={"Kørte kilometer"}
            error={error.trim() !== "" ? true : false}
            focused={true}
            value={mileageLocal}
            placeholder={"Indtast bilens kilometerstand"}
            required={true}
            onChange={(event: InputEvent) => {
              const value = (event.target as HTMLInputElement).value;

              const mileageRegex = new RegExp("^[0-9]{0,8}$");

              if (mileageRegex.test(value)) {
                setMileage(value);
                setMileageLocal(value);
                setError("");
              }
              if (
                (event.target as HTMLInputElement).value.length <=
                (event.target as HTMLInputElement).value.length - 1
              )
                setError("");
            }}
            style={{
              height: "2.3vh",
            }}
            helperText={error}
            inputProps={{
              inputMode: "numeric",
              pattern: "^[0-9]{0,8}$",
              maxLength: 8,
            }}
          />
        </Grid>
        <Grid
          item
          container
          md={3}
          className={classes.guaranteeInputButtonWrapper}
        >
          <SimpleButton
            onClick={() => {
              if (
                ifMileage.trim() === "1" &&
                mileage.trim() !== "" &&
                error.trim() === ""
              ) {
                if (validateInput()) {
                  setOpen(!open);
                  handleAddClick(serviceId, mileage);
                }
              } else {
                validateInput();
              }
            }}
            classes={classes.guaranteeInputButton}
          >
            {"Tilføj"}
          </SimpleButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!remove && !basket.find((el: any) => el.sa_id === serviceId) ? (
        <SimpleButton
          onClick={() => {
            if (ifMileage.trim() === "1") {
              setOpen(!open);
            } else {
              handleAddClick(serviceId, mileage);
            }
          }}
          classes={classes.addButton}
        >
          {"Vælg"}
        </SimpleButton>
      ) : (
        <SimpleButton
          onClick={() => {
            handleRemoveClick(serviceId, mileage);
          }}
          classes={classes.removeButton}
        >
          {"Fjern"}
        </SimpleButton>
      )}
      <InfoDialog
        title="Kørte kilometer"
        content={<>{input()}</>}
        open={open}
        onClose={() => {
          setOpen(!open);
          setError("");
        }}
        fullWidth={true}
      />
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </>
  );
};

export default AddRemoveButton;
