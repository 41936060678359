import { Grid } from "@mui/material";
import React, { FC } from "react";
import PrePurChkListTable1 from "./SubComponents/PrePurChkListTable1";
import PrePurChkListTable2 from "./SubComponents/PrePurChkListTable2";
import PrePurChkListTable3 from "./SubComponents/PrePurChkListTable3";
import PrePurChkListTable4 from "./SubComponents/PrePurChkListTable4";
import PrePurChkListTable5 from "./SubComponents/PrePurChkListTable5";
import PrePurChkListTable6 from "./SubComponents/PrePurChkListTable6";
import PrePurChkListTable7 from "./SubComponents/PrePurChkListTable7";
import PrePurChkListTable8 from "./SubComponents/PrePurChkListTable8";
import PrePurChkListTable9 from "./SubComponents/PrePurChkListTable9";
import PrePurChkListTable10 from "./SubComponents/PrePurChkListTable10";


const PrepurchaseChecklist: FC = () => {
  return (
    <Grid>
      <PrePurChkListTable1 />
      <PrePurChkListTable2 />
      <PrePurChkListTable3 />
      <PrePurChkListTable4 />
      <PrePurChkListTable5 />
      <PrePurChkListTable6 />
      <PrePurChkListTable7 />
      <PrePurChkListTable8 />
      <PrePurChkListTable9 />
      <PrePurChkListTable10/>
    </Grid>
  );
};

export default PrepurchaseChecklist;
