import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import TyreCartOptions from "../SubComponents/TyreCartOptions";
import TyresTabPanel from "../TyresTabPanel";
import { TyreWrapperProps } from "../interfaces/interfaces";
import DropdownsSelectionWrapper from "./DropdownsSelectionWrapper";
import { useHistory } from "react-router-dom";

const TyreWrapper: FC<TyreWrapperProps> = (props) => {
  const { value } = props;

  const [show, setShow] = useState(false);
  const [showTyreSelection, setShowTyreSelection] = useState(false);
  const [serviceId, setServiceId] = useState("");

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Grid>
      <TyresTabPanel value={value} index={1}>
        {!show ? (
          <>
            <TyreCartOptions
              setShow={setShow}
              setServiceId={setServiceId}
              setShowTyreSelection={setShowTyreSelection}
            />
          </>
        ) : (
          <Grid>
            <DropdownsSelectionWrapper
              setShow={setShow}
              serviceId={serviceId}
              setShowTyreSelection={setShowTyreSelection}
              showTyreSelection={showTyreSelection}
            />
          </Grid>
        )}
      </TyresTabPanel>
    </Grid>
  );
};

export default TyreWrapper;
