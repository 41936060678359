import { makeStyles } from "@mui/styles";

export const useStylesWrappers = makeStyles({
  clusterWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  servicesWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100vh",
    padding: "2% 0 0 0",
  },
  vehicleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
