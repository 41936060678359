import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import { TabPanelProps } from "../interfaces/TabPanelInterfaces";
import { useStylesServicing } from "./Styles/ServicingStyles";

const ServicingAndMotTabPanel: FC<TabPanelProps> = (props) => {
  const { index, children, value } = props;
  const classes = useStylesServicing();

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      item
      container
      className={classes.root}
      md={12}
    >
      {value === index && <Box className={classes.box}>{children}</Box>}
    </Grid>
  );
};

export default ServicingAndMotTabPanel;
