/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, FormHelperText, Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import carChasis from "../../../../../../assets/illustrations/CarChasis.svg";
import carWheel from "../../../../../../assets/illustrations/CarWheel.svg";
import carWheelChecked from "../../../../../../assets/illustrations/WheelChecked.svg";
import { SimpleButton } from "../../../../../library-components/Buttons/Buttons";
import { TyreSelectionType } from "../../Types/Types";
import {
  useStylesTyresSelection,
  useStylesTyresButtons,
} from "../../Styles/TyresStyles";

const TyreSelectionMobile: FC<TyreSelectionType> = (props) => {
  const { setShow, setShowTyreSelection } = props;

  const { state, dispatch } = useContext(GlobalContext);

  const [frontTyres, setFrontTyres] = useState(0);
  const [backTyres, setBackTyres] = useState(0);

  const [isFrontLeft, setIsFrontLeft] = useState(false);
  const [isFrontRight, setIsFrontRight] = useState(false);
  const [isBackRight, setIsBackRight] = useState(false);
  const [isBackLeft, setIsBackLeft] = useState(false);

  const [isFrontLeftChecked, setIsFrontLeftChecked] = useState(false);
  const [isFrontRightChecked, setIsFrontRightChecked] = useState(false);
  const [isBackRightChecked, setIsBackRightChecked] = useState(false);
  const [isBackLeftChecked, setIsBackLeftChecked] = useState(false);

  const classes = useStylesTyresSelection(props);
  const styles = useStylesTyresButtons();

  const { t } = useTranslation();

  const handleClick = () => {
    dispatch({
      type: "TYRES_SELECTED",
      payload: {
        noFrontTyres: frontTyres,
        noBackTyres: backTyres,
        isFrontLeft: isFrontLeft,
        isFrontRight: isFrontRight,
        isBackLeft: isBackLeft,
        isBackRight: isBackRight,
      },
    });
  };

  const [hasTyreSelected, setHasTyreSelected] = useState(false);

  useEffect(() => {
    dispatch({
      type: "TYRES_SELECTED",
      payload: {
        noFrontTyres: frontTyres,
        noBackTyres: backTyres,
        isFrontLeft: isFrontLeft,
        isFrontRight: isFrontRight,
        isBackLeft: isBackLeft,
        isBackRight: isBackRight,
      },
    });
  }, [
    frontTyres,
    backTyres,
    isFrontLeft,
    isFrontRight,
    isBackLeft,
    isBackRight,
  ]);

  useEffect(() => {
    if (state.tyresSelected !== undefined) {
      setIsFrontLeftChecked(state.tyresSelected.isFrontLeft);
      setIsFrontRightChecked(state.tyresSelected.isFrontRight);
      setIsBackLeftChecked(state.tyresSelected.isBackLeft);
      setIsBackRightChecked(state.tyresSelected.isBackRight);

      setIsFrontLeft(state.tyresSelected.isFrontLeft);
      setIsFrontRight(state.tyresSelected.isFrontRight);
      setIsBackLeft(state.tyresSelected.isBackLeft);
      setIsBackRight(state.tyresSelected.isBackRight);

      setFrontTyres(state.tyresSelected.noFrontTyres);
      setBackTyres(state.tyresSelected.noBackTyres);
    }
  }, []);

  const backToCards = () => {
    setShow(false);
  };

  const continueToTyreSpecs = () => {
    setShowTyreSelection(false);
  };

  return (
    <Grid item container className={classes.rootMobile}>
      <Grid className={classes.header}>
        <Typography fontFamily={"Poppins"} fontWeight={"Bold"} fontSize={14}>
          {t("tyreSelectionTitle")}
        </Typography>
      </Grid>
      <Grid className={classes.explanationMobile}>
        <Typography fontFamily={"Poppins"} fontWeight={"Regular"} fontSize={12}>
          {t("tyreTitleExplanation")}
        </Typography>
      </Grid>
      <Grid className={classes.carPadding}>
        <Grid className={classes.wrapper}>
          <Grid>
            <Grid className={classes.tyresFrame}>
              <Grid className={classes.tyresPaddingMobile}>
                <Grid
                  className={`${classes.wrapper} ${classes.topTyresWrapper}`}
                >
                  <Grid>
                    <Checkbox
                      icon={<img src={carWheel} />}
                      checkedIcon={<img src={carWheelChecked} />}
                      className={`${classes.wrapper} ${classes.topLeftTyre}`}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={isFrontRightChecked}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setFrontTyres(frontTyres + 1);
                          setIsFrontRight(true);
                          setIsFrontRightChecked(true);
                          setHasTyreSelected(false);
                          handleClick();
                        } else {
                          if (frontTyres > 0) {
                            setFrontTyres(frontTyres - 1);
                          } else {
                            setFrontTyres(0);
                          }
                          setIsFrontRight(false);
                          setIsFrontRightChecked(false);
                          handleClick();
                        }
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Checkbox
                      icon={<img src={carWheel} />}
                      checkedIcon={<img src={carWheelChecked} />}
                      className={`${classes.wrapper} ${classes.rightTyre}`}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={isBackRightChecked}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setBackTyres(backTyres + 1);
                          setIsBackRight(true);
                          setIsBackRightChecked(true);
                          setHasTyreSelected(false);
                          handleClick();
                        } else {
                          if (backTyres > 0) {
                            setBackTyres(backTyres - 1);
                          } else {
                            setBackTyres(0);
                          }
                          setIsBackRight(false);
                          setIsBackRightChecked(false);
                          handleClick();
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.wrapper}>
                <Grid className={classes.wrapper}>
                  <img src={carChasis} className={classes.carImageMobile} />
                </Grid>
              </Grid>
              <Grid className={classes.tyresPaddingMobile}>
                <Grid
                  className={`${classes.wrapper} ${classes.topTyresWrapper}`}
                >
                  <Grid>
                    <Checkbox
                      icon={<img src={carWheel} />}
                      checkedIcon={<img src={carWheelChecked} />}
                      className={`${classes.wrapper} ${classes.bottomLeftTyre}`}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={isFrontLeftChecked}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setFrontTyres(frontTyres + 1);
                          setIsFrontLeft(true);
                          setIsFrontLeftChecked(true);
                          setHasTyreSelected(false);
                          handleClick();
                        } else {
                          if (frontTyres > 0) {
                            setFrontTyres(frontTyres - 1);
                          } else {
                            setFrontTyres(0);
                          }
                          setIsFrontLeft(false);
                          setIsFrontLeftChecked(false);
                          handleClick();
                        }
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Checkbox
                      icon={<img src={carWheel} />}
                      checkedIcon={<img src={carWheelChecked} />}
                      className={`${classes.wrapper} ${classes.rightTyre}`}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={isBackLeftChecked}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setBackTyres(backTyres + 1);
                          setIsBackLeft(true);
                          setIsBackLeftChecked(true);
                          setHasTyreSelected(false);
                          handleClick();
                        } else {
                          if (backTyres > 0) {
                            setBackTyres(backTyres - 1);
                          } else {
                            setBackTyres(0);
                          }
                          setIsBackLeft(false);
                          setIsBackLeftChecked(false);
                          handleClick();
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={`${classes.wrapper} ${classes.errorWrapper}`}>
        {hasTyreSelected ? (
          <FormHelperText className={classes.errorText}>
            {"You must at least select one tyre"}
          </FormHelperText>
        ) : null}
      </div>
      <Grid className={`${classes.wrapper} ${classes.buttonsWrapper}`}>
        <Grid className={classes.backToMainPageButtonWrapper}>
          <SimpleButton
            classes={`${styles.cancelButton} ${classes.buttonMargin}`}
            onClick={() => {
              backToCards();
            }}
          >
            {t("backToMainTab")}
          </SimpleButton>
        </Grid>
        <Grid>
          <SimpleButton
            classes={`${styles.continueButton} ${classes.buttonMargin}`}
            onClick={() => {
              if (backTyres > 0 || frontTyres > 0) {
                continueToTyreSpecs();
                setHasTyreSelected(false);
              } else {
                setHasTyreSelected(true);
              }
            }}
          >
            {t("continue")}
          </SimpleButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TyreSelectionMobile;
