import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import ContentZipCode from "./ContentZipCode";
import ContentLicencePlate from "./ContentLicencePlate";
import Buttons from "./Actions";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";
import ContentEmail from "./ContentEmail";
import Unchecked from "../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../assets/illustrations/checkboxChecked.svg";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { GlobalContext } from "../../../context/GlobalContext";
import ConversionRate from "./ConversionRate";
import { useLocation } from "react-router-dom";
import {ContactDetailsScreenType} from "../../../interfaces-and-unionTypes/UnionTypes";

const Content: FC = () => {
  const classes = useStylesDesktop();
  const { state, dispatch } = useContext(GlobalContext);
  const zipCodeRef = useRef<HTMLInputElement>();
  const licencePlateRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const query = useLocation();
  const couponCodeValue = state.queryParams?.coupon_code??''

  const blurZipCodeInput = () => {
    zipCodeRef?.current?.blur();
  };

  const blurLicencePlateInput = () => {
    licencePlateRef?.current?.click();
    licencePlateRef?.current?.blur();
  };

  const blurEmailInput = () => {
    emailRef?.current?.click();
    emailRef?.current?.blur();
  }

  const [localLicencePlate, setLocalLicencePlate] = useState("");
  const [localZipCode, setLocalZipCode] = useState("");
  const [localEmail, setLocalEmail] = useState("");
  const [cvr, setCvr] = useState("");
  const cvrRef = useRef<HTMLInputElement>();
  const heavyCampaigns = ["fleggaardleasing0"]
  const [errorZipCode, setErrorZipCode] = useState("");
  const [errorLicencePlate, setErrorLicencePlate] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [marketingPermission, setMarketingPermission] = useState(true);
  const [callFunction, setCallFunction] = useState(false);
  const [isServicePlan, setIsServicePlan] = useState(state?.servicePlanExist || query?.search?.toLowerCase()?.includes("serviceplan"))
  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // Call the validate function inside the Buttons component
      setCallFunction(true);
    }
  };
  useEffect(() => {
    if (state?.basketData?.campaign_code === "serviceplan") {
      setIsServicePlan(true)
      dispatch({ type: "SERVICE_PLAN_EXIST", payload: true })
    }
  }, [])

  useEffect(() => {
    if (state.queryParams) {
      if (state.queryParams?.email) {
        setLocalEmail(state.queryParams?.email || "");
        if(state.queryParams?.service) {
          setCallFunction(true);
        }
      }
      const detailsObject: ContactDetailsScreenType = {
        node: {
          full_name: state.queryParams?.name ?? '',
          address: state.queryParams?.address ?? '',
          phone: state.queryParams?.phone ?? '',
        }
      }
      dispatch({ type: "CONTACT_DETAILS_FROM_URL", payload: detailsObject })
    }
  }, [state.queryParams]);


  return (
    <Grid item container md={12} className={`${classes.contentComponent}`}>
      <div onKeyDown={handleEnterKeyPress}>
        <div
          className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
        >
          <ContentZipCode
            error={errorZipCode}
            setLocalZipCode={setLocalZipCode}
            zipCodeRef={zipCodeRef}
          />
        </div>
        <div
          className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
        >
          <ContentLicencePlate
            setLocalLicencePlate={setLocalLicencePlate}
            licenceError={errorLicencePlate}
            licencePlateRef={licencePlateRef}
          />
        </div>
        <div
          className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
        >
          <ContentEmail
            setEmail={setLocalEmail}
            email={localEmail}
            emailError={errorEmail}
            setEmailError={setErrorEmail}
            emailRef={emailRef}
          />
        </div>
        {state?.customerType === "business" &&
          <div
            className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
          >
            <ConversionRate
              setCvr={setCvr}
              cvr={cvr}
              cvrRef={cvrRef}
            />
          </div>}
        {!heavyCampaigns.includes(state.basketData?.campaign_code) && !heavyCampaigns.includes(couponCodeValue) && <div
          className={`${classes.contentComponent}`} style={{ width: "100vw", marginBottom: "1vh" }}
        >
          <div
            style={{ width: !isMobile ? "27.7vw" : "79vw" }}>
            <FormControlLabel
              control={
                <div style={{ display: "table-cell" }}>
                  <Checkbox
                    style={{
                      color: "#8D88FF",
                    }}
                    icon={<img src={Unchecked} />}
                    checkedIcon={<img src={Checked} />}
                    onChange={(e: any) => {
                      setIsServicePlan(!isServicePlan);
                      dispatch({ type: "SERVICE_PLAN_EXIST", payload: !isServicePlan })
                    }}
                    checked={isServicePlan}
                  />
                </div>
              }
              label={
                <Typography fontWeight={400} fontSize={12}>
                  Er du tilmeldt Boxnow Autopilot?
                </Typography>
              }
            // className={classesChecbox.root1}
            />
          </div>
        </div>}
        <div
          className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
        >

          <div
            style={{ width: !isMobile ? "27.7vw" : "79vw" }}>
            <FormControlLabel
              sx={{ display: "table" }}
              control={
                <div style={{ display: "table-cell" }}>
                  <Checkbox
                    style={{
                      color: "#8D88FF",
                    }}
                    icon={<img src={Unchecked} />}
                    checkedIcon={<img src={Checked} />}
                    onChange={(e: any) => {
                      setMarketingPermission(!marketingPermission);
                    }}
                    checked={marketingPermission}
                  />
                </div>
              }
              label={
                <Typography fontWeight={400} fontSize={12}>
                  {t("acceptConditions1")}
                  <span>
                    <a
                      href="https://boxnow.dk/personoplysninger/"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{ color: "#00F" }}
                    >
                      persondatapolitik
                    </a>
                    <span> og </span>
                    <a
                      href="https://boxnow.dk/generelle-betingelser/"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{ color: "#00F" }}
                    >
                      betingelserne
                    </a>
                  </span>
                  {t("acceptConditions1Continued")}
                  <span>
                    <a
                      href="mailto:hej@boxnow.dk"
                      style={{ color: "#00F" }}
                    >
                      hej@boxnow.dk
                    </a>
                  </span>
                </Typography>
              }
            // className={classesChecbox.root1}
            /></div>
        </div>
      </div>
      <div
        className={`${classes.contentComponent} ${classes.contentComponentDiv}`}
      >
        <Buttons
          localLicencePlate={localLicencePlate}
          localZipCode={localZipCode}
          localEmail={localEmail}
          marketingPermission={marketingPermission}
          setError={setErrorZipCode}
          setErrorLicencePlate={setErrorLicencePlate}
          setErrorEmail={setErrorEmail}
          blurZipCodeInput={blurZipCodeInput}
          blurLicencePlateInput={blurLicencePlateInput}
          blurEmailInput={blurEmailInput}
          callFunction={callFunction}
          setCallFunction={setCallFunction}
        />
      </div>
    </Grid >
  );
};

export default Content;
