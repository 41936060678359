import { Grid } from "@mui/material";
import React, { FC, useState } from "react";
import Summary from "../Summary/Summary";

import { useStylesDesktopServices } from "./Styles/ServiceDetailsCalendarStyles";
import ContactDetails from "./SubComponents/ContactDetails/ContactDetails";
import { ContactDetailsType, ContactGTMDetailsType } from "../../interfaces-and-unionTypes/UnionTypes";
import Scheduler from "./SubComponents/Schedule/Scheduler";
import TrustBox from "../TrustBox/TrustBox";
import { isMobile } from "react-device-detect";
import BasketMobileContent from "../Summary/BasketMobile/BasketMobileContent";

const ServiceDetailsCalendar: FC = () => {
  const classes = useStylesDesktopServices();

  const [errorMsg, setError] = useState("");

  const [contactDetails, setContactDetails] = useState<ContactDetailsType>({
    fullName: "",
    email: "",
    strAddress1: "",
    strAddress2: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    otherPayer: ""
  });

  const [GTMDetails, setGTMDetails] = useState<ContactGTMDetailsType>({
    Utm_source: "",
    Utm_medium: "",
    Utm_campaign: "",
    Gclid: "",
  });

  return (
    <Grid item container md={12} className={classes.root}>
      <div style={{ marginTop: "15px" }}>
        <TrustBox />
      </div>
      <Grid item container md={12}>
        {!isMobile ? <Summary /> : <BasketMobileContent />}
      </Grid>
      <Grid
        item
        container
        md={12}
        className={`${classes.flexCenter} ${classes.detailsBottomPadding}`}
      >
        <ContactDetails
          errorMsg={errorMsg}
          setContactDetails={setContactDetails}
          setGTMContatcDetails={setGTMDetails}
        />
      </Grid>
      <Grid item container md={12} className={classes.flexCenter}>
        <Scheduler
          errorMsg={errorMsg}
          setError={setError}
          contactDetails={contactDetails}
          gtmDetails={GTMDetails}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceDetailsCalendar;
