import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesMobileSummaryCheckout } from "../../Styles/SummaryCheckoutStyles";

const DetailsContentTechnicalMobile: FC = () => {
  const { state } = useContext(GlobalContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const classes = useStylesMobileSummaryCheckout();

  useEffect(() => {
    setFullName(state.ticketInformationsData?.fullName || "");
    setEmail(state.ticketInformationsData?.email || "");
    setComment(state.ticketInformationsData?.comment || "");
    setPhoneNumber(state.ticketInformationsData?.number || "");
  }, []);

  return (
    <Grid className={classes.cardRoot}>
      <Grid>
        <Typography className={classes.cardTitleText}>
          {"Kontaktoplysninger"}
        </Typography>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>Fulde navn</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>Email</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>
            Telefonnummer
          </Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {"+45 " + phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>
            Hvordan kan vi hjælpe dig?
          </Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {comment}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContentTechnicalMobile;
