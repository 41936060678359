import { Theme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
type InfoDialogPropType = {
  isMaxWidth: boolean;
};

export const useStylesInfoDialog = makeStyles<Theme, InfoDialogPropType>({
  root: (props) => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      WebkitJustifyContent: "center",
      WebkitAlignContent: "center",
      width: "100vw",
      height: "100vh",

      "& .MuiPaper-root": {
        width: props.isMaxWidth ? "100%" : "42vw",
        maxWidth: props.isMaxWidth ? "100%" : "",
      },
    };
  },
  rootMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100vh",

    "& .MuiPaper-root": {
      width: "100vw",
    },

    "& .MuiFormControl-root": {
      width: "60vw",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    WebkitJustifyContent: "space-between",
    WebkitAlignItems: "center",
    borderBottom: "1px solid #E5E5E5",
    paddingBottom: "2px !important",
    paddingTop: "5px !important",
    paddingRight: "1.5% !important",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    WebkitJustifyContent: "flex-start",
    WebkitAlignItems: "center",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    WebkitJustifyContent: "flex-end",
    WebkitAlignItems: "center",
  },
  dialogContent: {
    paddingTop: "15px",
  },
  dialogContentWrapper: {
    paddingTop: "15px",
  },
});
