import logo from "../../../assets/illustrations/boxnow_logo_black.png";
import logoSign from "../../../assets/illustrations/boxnow_logo_small.png";
import { makeStyles } from "@mui/styles";
import { isDesktop, isMobile } from "react-device-detect";
export const logoDimension = isDesktop ? "35%" : isMobile ? "45px" : "";
export const omnicarLogo = isDesktop ? logo : logoSign;
export const useStylesButton = makeStyles({
  icon: {
    fill: "green",
  },
  text: {
    fill: "white",
  },
});

export const useStylesDesktop = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 20,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  logo: {
    width: logoDimension,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    margin: 0,
  },
  link: {
    padding: "1.2%",
    justifyContent: "center",
    color: "#ACACAC",
    fontSize: 14,
    textDecoration: "none",
  },
  linkNavbar: {
    display: "flex",
    justifyContent: "stretch",
    alignItems: "center",
  },
  trackerWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  checkpointName: {
    display: "flex",
    justifyContent: "space-around",
  },
  parentCircleElement: {
    display: "flex",
    justifyContent: "center",
    marginTop: 7,
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    border: `1px solid #ACACAC`,
    borderRadius: 50102,
    width: 20,
    height: 20,
  },
  stepper: {
    "& .MuiSvgIcon-root": { fill: "#DCD9D4" },
    "& .Mui-active": { fill: "#8D88FF", color: "white" },
    "& .Mui-completed": { fill: "#8D88FF", color: "white" },
  },
  stepperBox: {
    width: "100%",
  },
});

export const useStylesMobile = makeStyles({
  root: {
    height: "100%",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    padding: "0px 10px",
    paddingTop: 10,
  },
  logo: {
    width: "30px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    margin: 0,
  },
  link: {
    display: "flex",
    justifyContent: "stretch",
    alignItems: "center",
    padding: "0px 10px",
    marginRight: "7.5% !important",
  },
  customBadge: {
    color: "white",
    backgroundColor: "#EF6060",
  },
  anchorOriginTopLeftCircular: {
    width: "16px",
    minWidth: "16px",
    height: "16px",
    minHeight: "16px",
    fontSize: "7pt",
    top: 4,
    right: 2,
  },
  goBackImage: {
    width: 30,
  },
  trackerWrapper: {
    width: "50% !important",
  },
  helpAndBasketWrapper: {
    display: "flex",
    alignItems: "center",
  },
  basketImage: {
    height: "30px",
  },
  servicesNavigation: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    paddingTop: "2%",
  },
  servicesIconButton: {
    width: "100%",
    padding: "0px !important",
  },
  servicesImageWrapper: {
    padding: 0,
    margin: 0,
  },
  servicesImage: {
    width: 24,
  },
  rootProgressTrack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 0,
  },
});

export const useStyles = makeStyles({
  root: {
    boxShadow: "none !important",
    height: isMobile ? "100%" : "",
  },
  header: {
    background: "#FFFFFF",
    color: "#1A1D1E",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: isMobile ? "0px !important" : "0px",
    borderBottom: isMobile ? "1px solid #404546" : "none",
    height: isMobile ? "100%" : "",
  },
  logo: {
    width: logoDimension,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    margin: 0,
  },
  link: {
    display: "flex",
    justifyContent: "stretch",
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
