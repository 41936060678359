import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
  useStylesDesktopPrePurchaseChecklist,
  useStylesDesktopPrePurchaseCompareTable,
} from "../../../Styles/PrePurchaseStyles";

const PrePurChkListTable3: FC = () => {
  const { t } = useTranslation();
  const classes = useStylesDesktopPrePurchaseChecklist();
  const styles = useStylesDesktopPrePurchaseCompareTable();
  const rows = [
    {
      title: t("table3.table3_1"),
      basic: true,
      starBasic: false,
      premium: true,
      starPremium: false,
    },
    {
      title: t("table3.table3_2"),
      basic: true,
      starBasic: false,
      premium: true,
      starPremium: false,
    },
    {
      title: t("table3.table3_3"),
      basic: true,
      starBasic: false,
      premium: true,
      starPremium: false,
    },
    {
      title: "",
    },
  ];
  return (
    <Grid className={classes.root}>
      <TableContainer component={Grid} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={`${styles.tableHeadCells} ${classes.tableHeadCells}`}
              >
                {t("table3.table3Header")}
              </TableCell>
              <TableCell align="center" className={styles.tableHeadCells}>
                {t("table3.table3Basic")}
              </TableCell>
              <TableCell align="center" className={styles.tableHeadCells}>
                {t("table3.table3Premium")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <TableRow
                key={item.title}
                className={styles.tableRowLastChildBorder}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.title.trim() === "" ? "bold" : "normal",
                    display: item.title.trim() == "" ? "flex" : "table-cell",
                    justifyContent:
                      item.title.trim() == "" ? "flex-start" : "inherit",
                    alignItems: item.title.trim() == "" ? "center" : "inherit",
                    padding: "1px",
                    fontSize: !isMobile ? "14px" : "10px",
                  }}
                >
                  {item.title}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.title.trim() === "" ? "bold" : "normal",
                  }}
                >
                  {item.basic ? (
                    <DoneIcon className={styles.doneIcon} />
                  ) : item.basic == false ? (
                    <CloseIcon className={styles.closeIcon} />
                  ) : (
                    ""
                  )}
                  {item.starBasic ? "*" : ""}
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.title.trim() === "" ? "bold" : "normal",
                  }}
                >
                  {item.premium ? (
                    <DoneIcon className={styles.doneIcon} />
                  ) : item.premium == false ? (
                    <CloseIcon className={styles.closeIcon} />
                  ) : (
                    ""
                  )}
                  {item.starPremium ? "*" : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PrePurChkListTable3;
