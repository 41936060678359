import { Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import PaperPlane from "../../../assets/illustrations/awesome-paper-plane.svg";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const HeaderTechnical: FC = () => {
  const { state } = useContext(GlobalContext);

  const classes = useStylesDesktopSummaryCheckout();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Grid item container md={12}>
      <Grid
        className={classes.headerRoot}
        item
        container
        md={12}
        onClick={() => {
          scrollToTop();
          window.location.reload();
        }}
      >
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item container md={12} className={classes.headerImage}>
        <img src={PaperPlane} />
      </Grid>
      <div className={classes.headerTitleWrapper}>
        <Typography className={classes.headerTitleText}>
          {"Tak for din forespørgsel!"}
        </Typography>
      </div>
      {state.submitTicketRes?.node &&
      state.submitTicketRes?.node?.om_message?.trim() !== "" ? (
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.missingItemText}>
            {"Minimumskøb på 698 DKK for online booking er ikke opnået."}
          </Typography>
        </div>
      ) : null}
      <div className={classes.headerDescription}>
        <Typography className={classes.headerDescriptionText}>
          {
            "Vi kontakter dig inden for 24 timer på hverdage med et godt tilbud."
          }
        </Typography>
      </div>
      <div className={classes.borderWrapper}>
        <div className={classes.border}></div>
      </div>
    </Grid>
  );
};

export default HeaderTechnical;
