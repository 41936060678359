import { Box, Button, Typography } from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Repairs from "../../../../assets/illustrations/Repairs.svg";
import ServicingAndMOT from "../../../../assets/illustrations/Servicing&MOT.svg";
import GlasOgRuder from "../../../../assets/illustrations/Glass.svg";
import Tyres from "../../../../assets/illustrations/Tyres.svg";
import PrePurchaseInspections from "../../../../assets/illustrations/PrePurchaseInspections.svg";
import NotSureWhatsWrongCar from "../../../../assets/illustrations/not-sure-whats-wrong-car.svg";
import chevronRight from "../../../../assets/illustrations/chevron-right.svg";
import { useStyles } from "../Style/Styles";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useLazyQuery } from "react-apollo";
import { computeCampaign, getCampaignById } from "../../../../API/graphql-queries/Queries";
import { ComputeServiceDataResponse } from "../../../../interfaces-and-unionTypes/Interfaces";


const Forms: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const query = useLocation();
  const servicesButtonsProps = [
    {
      id: 1,
      title: t("repairs") + " ",
      desc: t("repairsHelperText"),
      to: "repairs",
      image: Repairs,
    },
    {
      id: 2,
      title: t("tyres") + " ",
      desc: t("tyresHelperText"),
      to: "tyres",
      image: Tyres,
    },
    {
      id: 3,
      title: t("servicingAndMOT") + " ",
      desc: t("servicingAndMOTHelperText"),
      to: "servicing",
      image: ServicingAndMOT,
    },
    {
      id: 4,
      title: t("prePurchaseInspections") + " ",
      desc: t("prePurchaseInspectionsHelperTextMobile"),
      to: "inspections",
      image: PrePurchaseInspections,
    },
    {
      id: 5,
      title: t("glasOgRuder") + " ",
      desc: t("glasOgRuderHelperText"),
      to: "glass",
      image: GlasOgRuder,
    },
    {
      id: 6,
      title: t("notSureWhatsWrongTitleTabs") + " ",
      desc:
        t("notSureWhatsWrongDescriptionTabsMobile").length > 27
          ? t("notSureWhatsWrongDescriptionTabsMobile").slice(0, 26) + "..."
          : t("notSureWhatsWrongDescriptionTabsMobile"),
      to: "assistance",
      image: NotSureWhatsWrongCar,
    },
  ];

  const history = useHistory();
  const navigateToCluster = (val: string) => {
    console.log('navi', val)
    if (query.search) {
      if (val.trim() === "assistance") {
        history.push("/" + val + "/" + query.search);
      } else {
        history.push("/services/" + val + "/" + query.search);
      }
    } else {
      if (val.trim() === "assistance") {
        history.push("/" + val);
      } else {
        history.push("/services/" + val);
      }
    }

  };
  const { state, dispatch } = useContext(GlobalContext);
  const [loadCampaignById, result] = useLazyQuery<Partial<any>>(
    getCampaignById,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [computedCampaign] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeCampaign, {
    onCompleted: (data) =>
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeCampaign?.node }),
    fetchPolicy: "no-cache",
  });

  const location = useLocation();
  useEffect(() => {
    loadCampaignFromUrl();
    if(typeof(state.queryParams?.service) === 'string') {
      if(state.queryParams?.service_ids?.includes('5000') || state.queryParams?.service_ids?.includes('5001')) {
        navigateToCluster('servicing')
      } else {
        navigateToCluster(state.queryParams.service)
      }
    }
  }, [])


  useEffect(() => {
    if (!state?.servicePlanExist && state.basketData?.campaign_code?.toLowerCase() === "serviceplan") {
      computedCampaign({
        variables: {
          campaign_code: "",
          ticket_id: state.ticket?.node?.ticket_id || "",
        },
      });
    }
  }, [])

  const loadCampaignFromUrl = async () => {
    let couponCode;
    if (state?.servicePlanExist) {
      couponCode = "serviceplan"
    } else {
      couponCode = state.queryParams?.coupon_code
    }
    if (couponCode) {
      if (couponCode.trim() !== "") {
        loadCampaignById({
          variables: {
            campaign_code: couponCode,
          },
        });
      } else if (couponCode.trim() === "") {
        Submit();
      }
    }
  }

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getCampaignById?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const Submit = () => {
    computedCampaign({
      variables: {
        campaign_code: result?.data?.getCampaignById?.node?.campaign_code !== "" ? result?.data?.getCampaignById?.node?.campaign_code : "",
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  };

  useEffect(() => {
    if (result?.data?.getCampaignById?.status === "SUCCESS") {
      Submit();
    }
  }, [result?.data]);
  return (
    <div className={`${classes.root} ${classes.rootContent}`}>
      {servicesButtonsProps.map((item) => {
        return (
          <Box
            key={item.id}
            className={`${classes.root} ${classes.boxContent}`}
          >
            <Button
              key={item.id}
              className={`${classes.root} ${classes.buttonContent}`}
              onClick={() => {
                navigateToCluster(item.to);
              }}
            >
              <div
                className={`${classes.root} ${classes.buttonContentWrapper}`}
              >
                <div
                  className={`${classes.root} ${classes.contentImageWrapper}`}
                >
                  <img src={item.image} className={classes.imageContent} />
                </div>
                <div className={`${classes.root} ${classes.cardTextContent}`}>
                  <Typography
                    component="div"
                    fontFamily={"Poppins, Bold"}
                    fontWeight={"bold"}
                    color="#1A1D1E"
                    className={classes.cardTextContentTitle}
                  >
                    {item.title}
                    <Typography
                      variant="subtitle2"
                      component="p"
                      fontWeight={"small"}
                      color="#1A1D1E"
                      fontFamily={"Poppins, Regular"}
                      fontSize={"10pt"}
                    >
                      {item.desc}
                    </Typography>
                  </Typography>
                </div>
                <div className={classes.cardArrowContent}>
                  <img src={chevronRight} />
                </div>
              </div>
            </Button>
          </Box>
        );
      })}
    </div>
  );
};

export default Forms;
