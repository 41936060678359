import { DialogContent, Dialog, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useDialogStylesReturningCustomer } from "./Styles/DialogStyle";
import { DialogProps } from "./Types/DialogTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import TechnicalAssistancePatienceDialog from "./TechincalAssistance/TechnicalAssistancePatienceDialog";

const ServiceLoaderDialog: FC<DialogProps> = (props) => {
  const classes = useDialogStylesReturningCustomer();

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { open, close } = props;

  const closeDialog = (data: any) => {
    close(data);
  };

  return (
    <Dialog
      open={open}
      className={classes.root}
      fullWidth={fullScreen}
      maxWidth={"md"}
      style={{
          zIndex: 2000
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5vh 0",
        }}
      >
        <TechnicalAssistancePatienceDialog
          isTechnical={false}
          title={props.title}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ServiceLoaderDialog;
