import React, { Fragment, useContext, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
import Navbar from "../components/library-components/Navbar/Navbar";
import { GlobalContext } from "../context/GlobalContext";
import { Routes as ArrayRoutes } from "./Routes";
import { RouteI } from "./Routes";
import VehicleWrapper from "../components/wrappers/VehicleWrapper";
import ReturningCustomerMobile from "../components/library-components/Dialogs/ReturningCustomer/ReturningCustomerMobile/ReturningCustomerMobile";
import TechnicalAssistanceMobile from "../components/library-components/Dialogs/TechincalAssistance/TechnicalAssistanceMobile/TechnicalAssistanceMobile";
import BasketMobileContent from "../components/Summary/BasketMobile/BasketMobileContent";
import SummaryCheckoutTechnical from "../components/SummaryCheckout/SummaryCheckoutTechnical";
import { isMobile } from "react-device-detect";
import SummaryCheckoutTechnicalMobile from "../components/SummaryCheckout/SummaryCheckoutMobile/SummaryCheckoutTechnicalMobile";
import ScrollToTop from "./ScrollToTop";
import InternetConnectionError from "../components/library-components/ErrorPages/InternetConnectionError";
import PageNotFoundError from "../components/library-components/ErrorPages/PageNotFoundError";
import "../i18n";

const Routes: React.FC = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();

  // useEffect(() => {


  //   const tempState = JSON.parse(localStorage.getItem("state")!);
  //   console.log('tem', tempState)
  //   dispatch({ type: "RETURNING_CUSTOMER", payload: { ...tempState } });
  // }, [])





  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <Route path="/">
          <Navbar />
        </Route>
        <Switch>
          {state.ticket?.node?.ticket_id ||
            state.submitTicketRes?.node?.ticket_id ? (
            ArrayRoutes.map((route: RouteI) => {
              return !state?.errorMessageDialog?.showDialog ? (
                route.exact == true ? (
                  <Route
                    key={route.route}
                    exact
                    path={route.route}
                    component={route?.component}
                  />
                ) : (
                  <Route
                    key={route.route}
                    path={route.route}
                    component={route?.component}
                  />
                )
              ) : route.exact == true ? (
                <Route
                  key={route.route}
                  exact
                  path={route.route}
                  component={InternetConnectionError}
                />
              ) : (
                <Route
                  key={route.route}
                  path={route.route}
                  component={InternetConnectionError}
                />
              );
            })
          ) : (
            <>


              <Redirect to={"/"} />
              <Redirect to={{ pathname: '/', search: window.location.search }} />


              <Route exact path={"/"}>
                <>
                  {!state?.errorMessageDialog?.showDialog ? (
                    <VehicleWrapper />
                  ) : (
                    <InternetConnectionError />
                  )}
                </>
              </Route>

              <Route exact path={"/returning"}>
                <>
                  {!state?.errorMessageDialog?.showDialog ? (
                    <ReturningCustomerMobile />
                  ) : (
                    <InternetConnectionError />
                  )}
                </>
              </Route>
              <Route
                path={"/assistance"}
                component={
                  !state?.errorMessageDialog?.showDialog
                    ? TechnicalAssistanceMobile
                    : InternetConnectionError
                }
              />
              <Route path="/basket">
                {!state?.errorMessageDialog?.showDialog ? (
                  <BasketMobileContent />
                ) : (
                  <InternetConnectionError />
                )}
              </Route>
            </>
          )}
          <Route path="/summary-technical">
            {!isMobile ? (
              <>
                {!state?.errorMessageDialog?.showDialog ? (
                  <SummaryCheckoutTechnical />
                ) : (
                  <InternetConnectionError />
                )}
              </>
            ) : (
              <>
                {!state?.errorMessageDialog?.showDialog ? (
                  <SummaryCheckoutTechnicalMobile />
                ) : (
                  <InternetConnectionError />
                )}
              </>
            )}
          </Route>
          <Route component={PageNotFoundError} />
        </Switch>
      </Fragment>
    </Router>
  );
};

export default Routes;
