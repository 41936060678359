/* eslint-disable @typescript-eslint/no-explicit-any */
import { Info } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { getServiceCluster } from "../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../context/GlobalContext";
import ServicesInfoDialog from "../../../../library-components/Dialogs/ServicesInfoDialog";
import { ServiceClusterType } from "../../../enumerations/enumerations";
import { formatPrice } from "../../PanelFunctions";
import { PrePurchaseInspectionsCartItems } from "../interfaces/interfaces";
import AddRemovePrePurchaseButton from "./AddRemovePrePurchaseButton";
import { useStylesPrePurchaseCartOptionsTab } from "../Styles/PrePurchaseStyles";
import { useHistory } from "react-router-dom";

const PrePurchaseInspectionsCartOptionsTab: FC<
  PrePurchaseInspectionsCartItems
> = () => {
  const [open, setOpen] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [serviceDsc, setServiceDsc] = useState("");
  const classes = useStylesPrePurchaseCartOptionsTab();
  const heavyCampaigns = ["fleggaardleasing0"]

  const { state } = useContext(GlobalContext);
  const history = useHistory();
  const [readyToRender, setReadyToRender] = useState(false)

  const [loadServiceCluster, result] = useLazyQuery<Partial<any>>(
    getServiceCluster,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getServiceCluster?.node === null) {
        alert(result?.data?.getServiceCluster?.message);
      }
      if (result?.data?.getServiceCluster?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  useEffect(() => {
    loadServiceCluster({
      variables: {
        service_cluster: ServiceClusterType.Prepurchase,
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  }, []);

  useEffect(() => {
    setReadyToRender(result?.data?.getServiceCluster?.node?.length > 0)
  }, [result?.data]);

  const handleDialog = (serviceId: string, serviceDsc: string) => {
    setOpen(!open);
    setServiceId(serviceId);
    setServiceDsc(serviceDsc);
  };

  return (
    <Grid item container md={12} className={classes.root}>
      {readyToRender && result?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
        return (
          <React.Fragment key={index}>
            {cat.service_subcategory?.map((cat: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  {cat?.service_details.map((cat: any) => {
                    return (
                      <Grid
                        key={cat.service_dsc}
                        className={classes.cardWrapper}
                      >
                        <Grid className={classes.cardHeader}>
                          <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"Bold"}
                            fontSize={14}
                          >
                            {cat.service_dsc}
                          </Typography>
                          <IconButton
                            className={classes.infoButton}
                            onClick={() =>
                              handleDialog(cat.service_id, cat.service_dsc)
                            }
                          >
                            <Info className={classes.infoButtonIcon} />
                          </IconButton>
                        </Grid>
                        <Grid className={classes.cardText}>
                          <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"Regular"}
                            fontSize={12}
                          >
                            {cat.service_long_dsc}
                          </Typography>
                        </Grid>
                        <Grid className={classes.cardPrice}>
                          <Typography
                            fontFamily={"Poppins"}
                            fontSize={24}
                            className={classes.cardPriceTextWeight}
                          >
                            {!cat.hide_price && !heavyCampaigns.includes(state.basketData?.campaign_code) && formatPrice(
                              cat.total_price === "0"
                                ? ""
                                : cat.total_price + " DKK"
                            )}
                          </Typography>
                          <AddRemovePrePurchaseButton
                            item={cat}
                            serviceId={cat.service_id}
                            mileage={cat.mileage}
                            tyreData={null}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
      <ServicesInfoDialog
        open={open}
        close={() => setOpen(false)}
        content={{ service_id: serviceId, service_dsc: serviceDsc }}
      />
    </Grid>
  );
};

export default PrePurchaseInspectionsCartOptionsTab;
