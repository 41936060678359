import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";
import { TyreSelectionType } from "../Types/Types";

export const useStylesTyres = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapperMobileMargin: {
    marginTop: "4% !important",
  },
  box: {
    padding: "3vh 0 0 0",
    width: "100%",
  },
  dropdownsRootMobile: {
    padding: "2%",
    width: "90%",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  dropdownsWrapper: {
    paddingLeft: "2%",
    paddingRight: "2%",
  },
});

export const useStylesTyresCartOptions = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0px 0% 0px 3%",
  },
  cardWrapper: {
    background: !isMobile
      ? "#F7F6F4 0% 0% no-repeat padding-box"
      : "white 0% 0% no-repeat padding-box",
    display: "flex",
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    width: !isMobile ? "26vw" : "100%",
    height: "19.3vh",
    flexDirection: "column !important" as "column",
    margin: "2%",
    boxShadow: !isMobile ? "" : "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoButton: {
    margin: "0px !important",
    padding: "0px !important",
  },
  infoButtonIcon: {
    color: "#8D88FF",
  },
  cardText: {
    height: !isMobile ? "9vh" : "8vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: !isMobile ? "2%" : "",
    lineHeight: !isMobile ? "1.2" : "",
  },
  cardPrice: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardPriceTextWeight: {
    fontWeight: "700 !important",
  },
  rootMobile: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "3%",
    flexDirection: "column",
    width: "100%",
    paddingBottom: "10%",
  },
  contentWrapperMobile: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});

export const useStylesTyresDropdowns = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-around",
    padding: "2%",
    width: !isMobile ? "8vw" : "100%",
  },
  menuItem: {
    height: "4vh",
  },
  errorWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2%",
  },
  errorText: {
    color: "#d32f2f !important",
  },
});

export const useStylesTyresSelection = makeStyles<Theme, TyreSelectionType>({
  rootMobile: {
    padding: "2% 2% 10% 2%",
  },
  header: {
    padding: !isMobile ? "0px 0px 2% 2%" : "10px 0px 2% 2%",
  },
  explanationMobile: {
    padding: "2%",
  },
  carPadding: {
    paddingTop: "5%",
    width: !isMobile ? "" : "100%",
  },
  wrapper: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  tyresWrapper: (props) => {
    return {
      width: props.matches ? "16vw" : "53vw",
      maxWidth: "53vw",
    };
  },
  tyresFrame: {
    paddingLeft: "2%",
    paddingTop: "5%",
  },
  tyresPaddingMobile: {
    padding: "0 9%",
  },
  topTyresWrapper: (props) => {
    return {
      justifyContent: "space-between !important",
      width: !isMobile ? (props.matches ? "16vw" : "22vw") : "",
      maxWidth: !isMobile ? "22vw" : "",
    };
  },
  topLeftTyre: {
    justifyContent: "flex-start !important",
  },
  rightTyre: {
    justifyContent: "flex-end !important",
  },
  bottomLeftTyre: {
    justifyContent: "space-around !important",
  },
  buttonsWrapper: {
    padding: "5%",
    width: !isMobile ? "" : "100%",
  },
  backToMainPageButtonWrapper: {
    paddingRight: "5%",
  },
  buttonMargin: {
    marginLeft: "4% !important",
  },
  errorWrapper: {
    width: "100%",
    paddingTop: "3%",
  },
  errorText: {
    color: "#d32f2f !important",
  },
  carImageMobile: {
    width: 250,
  },
});

export const useStylesTyresSpecs = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "2%",
    padding: !isMobile ? "" : "2% 2% 12% 2%",
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  titleWrapper: {
    padding: "0 0 0 2%",
  },
  titleWrapperMobile: {
    paddingLeft: "2%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  text: {
    fontWeight: "bold !important",
    fontFamily: "Poppins !important",
  },
  recomendation: {
    fontWeight: "regular !important",
    fontFamily: "Poppins !important",
    padding: "3% 0px 4% 0px !important",
    fontSize: "12px !important",
  },
  borderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    width: "55.5vw",
    border: "1px solid rgba(112, 112, 112, 0.2)",
    borderColor: "rgba(112, 112, 112, 0.2)",
  },
  dropdownsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dropdownsTitle: {
    padding: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60vw",
  },
  error: {
    width: "40vw",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "5%",
    width: !isMobile ? "" : "100%",
  },
  buttonsPadding: {
    paddingRight: !isMobile ? "5px" : "5%",
  },
  cancelButton: {
    marginLeft: !isMobile ? "-10% !important" : "4%",
  },
  continueButton: {
    marginRight: !isMobile ? "0% !important" : "",
    marginLeft: !isMobile ? "" : "4% !important",
  },
  findTyreSizeTitleWrapper: {
    margin: "5vh 0px 5vh 0px",
    padding: "0 0 0 3.5%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    marginTop: "10%",
    height: !isMobile ? "" : "100%",
  },
  wheelImageWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "0 0 0 2%",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  wheelImageSize: {
    width: !isMobile ? "30vw" : "80vw",
  },
  explanationWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "1.2%",
  },
  explanationText: {
    fontWeight: "regular",
    fontFamily: "Poppins",
    padding: "3% 2% 4% 3.5% !important",
    fontSize: "12px !important",
  },
  explanationItemsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    padding: "0 5% 0 3%",
  },
  explanationItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: !isMobile ? "2%" : "2% 2% 0px 2%",
  },
  explanationItemsText: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: !isMobile ? "" : "4% !important",
  },
});

export const useStylesTyresButtons = makeStyles({
  addButton: {
    background: "#8D88FF",
    color: "white !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  removeButton: {
    background: "#f72c2ca3 !important",
    color: "#ffffff !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  cancelButton: {
    background: "#DCD9D4 !important",
    color: "#1A1D1E !important",
    textTransform: "capitalize !important" as "capitalize",
    width: !isMobile ? "150px !important" : "30vw !important",
    height: "47px !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
  },
  continueButton: {
    background: "#8D88FF !important",
    color: "#F6F6FF !important",
    textTransform: "capitalize !important" as "capitalize",
    width: !isMobile ? "150px !important" : "30vw !important",
    height: "47px !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
  },
});
