import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useContext } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../../../context/GlobalContext";
import { isMobile } from "react-device-detect";
import {
  useStylesDesktopServicingChecklist,
  useStylesDesktopServicingTables,
} from "../../../Styles/ServicingStyles";

const FinalChecks: FC = () => {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const motortype = state.ticket?.node?.motortype;
  const classes = useStylesDesktopServicingChecklist();
  const styles = useStylesDesktopServicingTables();

  const rows = [
    {
      title: t("table5-servicing-checklist.kontrolLygternesFunktion"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.visuelKontrolRidser"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.kontrolVisker"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.visuelKontrolBilens"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.smoring"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.kontrolOvrige"),
      basic: false,
      guarantee: true,
      el: true,
    },
    {
      title: t(
        "table5-servicing-checklist.roadTestVehicleAndReportAnyFindings"
      ),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.reCheckEngineOilLevel"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table5-servicing-checklist.ensureAllUpholstery"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.kontrolGearskifte"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table5-servicing-checklist.stampServiceBook"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table5-servicing-checklist.resetServiceIntervalIndicator"),
      basic: false,
      guarantee: true,
      el: true,
    },
  ];
  return (
    <Grid className={classes.root}>
      <TableContainer component={Grid} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={`${styles.tableHeadCells} ${classes.tableHeadCells}`}
              >
                {t("table5-servicing-checklist.finalChecksTitle")}
              </TableCell>
              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table5-servicing-checklist.basic")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table5-servicing-checklist.guarantee")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() == "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table5-servicing-checklist.el")}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <>
                {(motortype?.toLowerCase() === "el" && item.el === true) ||
                motortype?.toLowerCase() !== "el" ? (
                  <TableRow
                    key={item.title}
                    className={styles.tableRowLastChildBorder}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight:
                          item.title.trim() === "" ? "bold" : "normal",
                        display:
                          item.title.trim() == "" ? "flex" : "table-cell",
                        justifyContent:
                          item.title.trim() == "" ? "flex-start" : "inherit",
                        alignItems:
                          item.title.trim() == "" ? "center" : "inherit",
                        padding: "1px",
                        fontSize: !isMobile ? "14px" : "10px",
                      }}
                    >
                      {item.title}
                    </TableCell>
                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.basic ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.basic == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.guarantee ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.guarantee == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {motortype?.toLowerCase() == "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.el ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.el == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid className={classes.root}>
        <TableContainer component={Grid}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead></TableHead>
          </Table>
        </TableContainer>
      </Grid>
      <Grid className={classes.noteWrapper}>
        <Grid>
          <Typography
            textAlign="left"
            fontSize={!isMobile ? "14px" : "10px"}
            fontFamily={"Poppins, Regular"}
          >
            {t("table5-servicing-checklist.note2")}
          </Typography>
          <Typography
            textAlign="left"
            fontSize={!isMobile ? "14px" : "10px"}
            fontFamily={"Poppins, Regular"}
          >
            {t("table5-servicing-checklist.note3")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinalChecks;
