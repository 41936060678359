/* eslint-disable @typescript-eslint/no-explicit-any */
import { Info } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import {
  getInsuranceCompany,
  getServiceCluster,
} from "../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { InsuranceResponse } from "../../../../../interfaces-and-unionTypes/Interfaces";
import { InsuranceCompanies } from "../../../../../interfaces-and-unionTypes/UnionTypes";
import ServicesInfoDialog from "../../../../library-components/Dialogs/ServicesInfoDialog";
import BasketMobile from "../../../../Summary/BasketMobile/BasketMobile";
import TrustBox from "../../../../TrustBox/TrustBox";
import { ServiceClusterType } from "../../../enumerations/enumerations";
import { formatPrice } from "../../PanelFunctions";
import AddRemoveButton from "../SubComponents/AddRemoveButton";
import { useStylesWindshield } from "../Styles/WindshieldStyles";
import { useHistory } from "react-router-dom";

const WindshieldMobile: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [open, setOpen] = useState(false);

  const heavyCampaigns = ["fleggaardleasing0"]
  const classes = useStylesWindshield();
  const history = useHistory();

  const [loadServiceCluster, result] =
    useLazyQuery<Partial<any>>(getServiceCluster);
  const [readyToRender, setReadyToRender] = useState(false)
  const [activeButtons, setActiveButtons] = useState<number[]>([]);

  const [loadInsurance, resultLoad] = useLazyQuery<InsuranceResponse>(
    getInsuranceCompany,
    {
      onCompleted: (data) => {
        dispatch({
          type: "INSURANCE_COMPANIES",
          payload: data.getInsuranceCompany,
        });
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getServiceCluster?.node === null) {
        alert(result?.data?.getServiceCluster?.message);
      } else {
        if (result?.data?.getServiceCluster?.message === "Session expired") {
          alert("Session expired");
          history.push("/");
        }
      }
    }
  }, [result?.data]);

  // useEffect(() => {
  //   if (resultLoad?.data === null) {
  //     alert(resultLoad?.error?.message);
  //   } else {
  //     if (resultLoad?.data?.getInsuranceCompany?.node === null) {
  //       alert(resultLoad?.data?.getInsuranceCompany?.message);
  //     }
  //     else{
  //       if (
  //         resultLoad?.data?.getInsuranceCompany?.message === "Session expired"
  //       ) {
  //         alert("Session expired");
  //         history.push("/");
  //       }
  //     }
  //   }
  // }, [resultLoad?.data]);

  const [insuranceCompany, setInsuranceCompany] = useState("");

  const [serviceId, setServiceId] = useState("");
  const [serviceDsc, setServiceDsc] = useState("");

  const handleDialog = (serviceId: string, serviceDsc: string) => {
    setServiceId(serviceId);
    setServiceDsc(serviceDsc);
    setOpen(!open);
  };

  const { t } = useTranslation();

  useEffect(() => {
    loadServiceCluster({
      variables: {
        service_cluster: ServiceClusterType.Glass,
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  }, []);

  useEffect(() => {
    loadInsurance();
  }, []);

  useEffect(() => {
    result?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
      cat.service_subcategory?.map((cat: any, index: any) => {
        cat?.service_details.map((cat: any) => {
          if (state.queryParams?.service_ids?.includes(cat.service_id)) {
            const b = activeButtons;
            b.push(cat.service_id)
            setActiveButtons(b)
          }
        })
      })
    })
    setReadyToRender(result?.data?.getServiceCluster?.node?.length > 0)
  }, [result?.data]);

  const handleChange = (event: SelectChangeEvent) => {
    setInsuranceCompany(event.target.value);
    dispatch({ type: "INSURANCE_COMPANY", payload: event.target.value });
  };

  return (
    <Grid container>
      <TrustBox />
      <Grid container item md={12} className={classes.root}>
        <Grid container className={classes.headerWrapper}>
          <Grid container item md={12} className={classes.insuranceWrapper}>
            <Grid
              item
              md={12}
              className={classes.label}
              style={{ marginBottom: "2%" }}
            >
              <Typography className={classes.labelTextMobile}>
                {t("insuranceInputFieldLabel")}
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.formControl}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Forsikringsselskaber
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={insuranceCompany}
                  label="Forsikringsselskaber"
                  onChange={handleChange}
                >
                  <MenuItem
                    value="None"
                    className={classes.menuItem}
                  ></MenuItem>
                  {state.insuranceCompanies !== undefined
                    ? state?.insuranceCompanies?.map(
                      (item: InsuranceCompanies) => (
                        <MenuItem
                          key={item?.insuranceCompanyId}
                          value={item?.insuranceCompanyId}
                        >
                          {item?.insuranceCompanyDsc}
                        </MenuItem>
                      )
                    )
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {readyToRender && result?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
          return (
            <Grid container key={index}>
              {cat.service_subcategory?.map((cat: any, index: any) => {
                return (
                  <Grid item container key={index}>
                    {cat?.service_details.map((cat: any) => {
                      if (cat.service_dsc === "Udskiftning af rude (foran)" || cat.service_dsc === "Udskiftning af rude (bag)" || cat.service_dsc === "Udskiftning af rude (side)") {

                        if (!heavyCampaigns.includes(state.basketData?.campaign_code)) {
                          return (
                            <Grid
                              item
                              container
                              key={cat.service_dsc}
                              className={classes.cardWrapper}
                            >
                              <Grid className={classes.cardHeader}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontWeight={"Bold"}
                                  fontSize={14}
                                >
                                  {cat.service_dsc}
                                </Typography>
                                <IconButton
                                  className={classes.infoButton}
                                  onClick={() =>
                                    handleDialog(cat.service_id, cat.service_dsc)
                                  }
                                >
                                  <Info className={classes.infoButtonIcon} />
                                </IconButton>
                              </Grid>
                              <Grid className={classes.cardText}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontWeight={"Regular"}
                                  fontSize={12}
                                >
                                  {cat.service_long_dsc}
                                </Typography>
                              </Grid>
                              <Grid className={classes.cardPrice}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontSize={24}
                                  className={classes.cardPriceTextWeight}
                                >
                                  {!cat.hide_price && !heavyCampaigns.includes(state.basketData?.campaign_code) && formatPrice(
                                    cat.total_price === "0"
                                      ? ""
                                      : cat.total_price + " DKK"
                                  )}
                                </Typography>
                                <AddRemoveButton
                                    defaultActive={activeButtons.includes(cat.service_id.toString())}
                                  serviceId={cat.service_id}
                                  mileage={cat.mileage}
                                  item={cat}
                                  tyreData={undefined}
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      } else if (cat.service_dsc !== "Udskiftning af rude (foran)" && cat.service_dsc !== "Udskiftning af rude (bag)" && cat.service_dsc !== "Udskiftning af rude (side)") {
                        return (
                          <Grid
                            item
                            container
                            key={cat.service_dsc}
                            className={classes.cardWrapper}
                          >
                            <Grid className={classes.cardHeader}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontWeight={"Bold"}
                                fontSize={14}
                              >
                                {cat.service_dsc}
                              </Typography>
                              <IconButton
                                className={classes.infoButton}
                                onClick={() =>
                                  handleDialog(cat.service_id, cat.service_dsc)
                                }
                              >
                                <Info className={classes.infoButtonIcon} />
                              </IconButton>
                            </Grid>
                            <Grid className={classes.cardText}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontWeight={"Regular"}
                                fontSize={12}
                              >
                                {cat.service_long_dsc}
                              </Typography>
                            </Grid>
                            <Grid className={classes.cardPrice}>
                              <Typography
                                fontFamily={"Poppins"}
                                fontSize={24}
                                className={classes.cardPriceTextWeight}
                              >
                                {!cat.hide_price && !heavyCampaigns.includes(state.basketData?.campaign_code) && formatPrice(
                                  cat.total_price === "0"
                                    ? ""
                                    : cat.total_price + " DKK"
                                )}
                              </Typography>
                              <AddRemoveButton
                                serviceId={cat.service_id}
                                mileage={cat.mileage}
                                item={cat}
                                tyreData={undefined}
                              />
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        <ServicesInfoDialog
          open={open}
          close={() => setOpen(false)}
          content={{ service_id: serviceId, service_dsc: serviceDsc }}
        />
      </Grid>
      <BasketMobile />
    </Grid>
  );
};

export default WindshieldMobile;
