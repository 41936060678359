import { makeStyles } from "@mui/styles";

export const useStylesDesktopRepairs = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0 1.8%",
  },
  itemsWrapper: {
    width: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  wrapperMargin: {
    marginTop: "20px",
  },
  accordion: {
    boxShadow: "none !important",
    width: "100% !important",
    "div:last-child": {
      borderBottom: "1px solid rgba(112, 112, 112, 0.2)",
      borderColor: "rgba(112, 112, 112, 0.2)",
    },
  },
  accordionSummary: {
    padding: "0px !important",
    height: "40px !important",
  },
  detailsText: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize !important" as "capitalize",
  },
  checkbox: {
    color: "#8D88FF",
    "&.Mui-checked": {
      color: "#8D88FF",
    },
  },
  infoIcon: {
    color: "#8D88FF",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "2%",
    width: "100%",
  },
  headerWrapper: {
    width: "50vw",
    height: "150px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  headerLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2% 2% 2% 11%",
  },
  headerLinkText: {
    color: "#1A1D1E !important",
    cursor: "pointer",
    textDecorationColor: "#1A1D1E !important",
  },
  borderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    width: "55.5vw",
    border: "1px solid rgba(112, 112, 112, 0.2)",
    borderColor: "rgba(112, 112, 112, 0.2)",
  },
});

export const useStylesMobileRepairs = makeStyles({
  repairsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  accordion: {
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3) !important",
    width: "100% !important",
    marginBottom: "5% !important",
    border: "none !important",
    borderTop: "none !important",
    "div:last-child": {
      borderBottom: "1px solid rgba(112, 112, 112, 0.2)",
      borderColor: "rgba(112, 112, 112, 0.2)",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  accordionSummary: {
    height: "40px !important",
  },
  detailsText: {
    paddingBottom: "14px",
  },
});
