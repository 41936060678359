import { Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import PaperPlane from "../../../assets/illustrations/awesome-paper-plane.svg";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const Header: FC = () => {
  const { state } = useContext(GlobalContext);
  const heavyCampaigns = ["fleggaardleasing0"]

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const classes = useStylesDesktopSummaryCheckout();

  return (
    <Grid item container md={12}>
      <Grid
        className={classes.headerRoot}
        item
        container
        md={12}
        onClick={() => {
          scrollToTop();
          window.location.reload();
        }}
      >
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item container md={12} className={classes.headerImage}>
        <img src={PaperPlane} />
      </Grid>
      {!heavyCampaigns.includes(state.basketData?.campaign_code) ?
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.headerTitleText}>
            {"Tak for din ordre/forespørgsel!"}
          </Typography>
        </div> :
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.headerTitleText}>
            {"Tak for din booking!"}
          </Typography>
        </div>
      }
      {state.submitTicketRes?.node &&
        state.submitTicketRes?.node?.om_message?.trim() !== "" &&
        state.basketData?.root_missing == 0 ? (
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.missingItemText}>
            {"Minimumskøb på 698 DKK for online booking er ikke opnået."}
          </Typography>
        </div>
      ) : null}
      <div className={classes.headerDescription}>
        {!heavyCampaigns.includes(state.basketData?.campaign_code) ?
          <>
            <Typography className={classes.headerDescriptionText}>
              {
                "Du vil inden for et par minutter modtage en ordrebekræftelse på mail."
              }
            </Typography>
            <Typography className={classes.headerDescriptionText}>
              {
                "Hvis prisen på det ønskede arbejde ikke er blevet automatisk genereret, kan du forvente, at vi vender retur med et tilbud inden for 24 timer på hverdage."
              }
            </Typography></>
          :
          <>
            <Typography className={classes.headerDescriptionText}>
              {
                "Du vil inden for et par minutter modtage en ordrebekræftelse på mail. Såfremt vi skulle have opklarende spørgsmål til din bestilling, vil vi tage kontakt til dig."
              }
            </Typography>
          </>
        }
      </div>
      <div className={classes.borderWrapper}>
        <div className={classes.border}></div>
      </div>
    </Grid>
  );
};

export default Header;
