import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { LocationTicket } from "../../../interfaces-and-unionTypes/Interfaces";
import { SimpleButton } from "../../library-components/Buttons/Buttons";
import { useMutation } from "@apollo/react-hooks";
import {
  updateTicketLocationVehicle,
  updateZipCode,
} from "../../../API/graphql-queries/Queries";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormHelperText,  Typography } from "@mui/material";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";
import { abandonedCart } from "../../../API/fetch-api/get.coupon.code";
import { useLocation } from "react-router-dom";
type ButtonProps = {
  localLicencePlate: string;
  localZipCode: string;
  localEmail: string;
  marketingPermission: boolean;
  setError: (error: string) => void;
  setErrorLicencePlate: (error: string) => void;
  setErrorEmail: (error: string) => void;
  blurZipCodeInput: () => void;
  blurLicencePlateInput: () => void;
  blurEmailInput: () => void;
  callFunction: boolean;
  setCallFunction: (value: boolean) => void;
};
const Buttons: FC<ButtonProps> = (props) => {
  const {
    localLicencePlate,
    setError,
    localZipCode,
    setErrorLicencePlate,
    blurLicencePlateInput,
    blurZipCodeInput,
    localEmail,
    setErrorEmail,
    blurEmailInput,
    marketingPermission,
  } = props;
  const { t } = useTranslation();

  const classes = useStylesDesktop();

  const [policyError, setPolicyError] = useState(false);

  const params = useLocation();

  useEffect(() => {
    if (props.callFunction) {
      validate();
      props.setCallFunction(false);
    }
  }, [props.callFunction]);

  const blurLicencePlate = () => {
    blurLicencePlateInput();
  };

  const blurZipCode = () => {
    blurZipCodeInput();
  };

  const blurEmail = () => {
    blurEmailInput();
  };

  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();

  const [ticket, setTicket] = useState(
    state.returningCustomer?.node?.ticket_id || ""
  );

  const [updateTicket, result] = useMutation<Partial<LocationTicket>>(
    updateTicketLocationVehicle,
    {
      onCompleted: (data) => {
        if (
          data.updateTicketLocationVehicle?.node?.delete_basket?.trim() === "1"
        ) {
          dispatch({ type: "ADD_TO_BASKET", payload: undefined });
          dispatch({ type: "TYRES_SELECTED", payload: undefined });
          dispatch({ type: "TYRE_DROPDOWNS_DATA", payload: undefined });
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  const [updateUserZipCode, resultZipCode] = useMutation<Partial<any>>(
    updateZipCode,
    {
      onCompleted: (data) => {
        if (data?.updateZipCode?.node !== null) {
          dispatch({
            type: "ADD_TO_BASKET",
            payload: data?.updateZipCode?.node,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      // if (result?.data?.updateTicketLocationVehicle?.node === null) {
      //   alert(result?.data?.updateTicketLocationVehicle?.message);
      // } else {
      if (
        result?.data?.updateTicketLocationVehicle?.message === "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
        // }
      }
    }
  }, [result?.data]);

  useEffect(() => {
    if (resultZipCode?.data === null) {
      alert(resultZipCode?.error?.message);
    } else {
      // if (resultZipCode?.data?.updateZipCode?.node === null) {
      //   alert(resultZipCode?.data?.updateZipCode?.message);
      // }
      if (resultZipCode?.data?.updateZipCode?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [resultZipCode?.data]);

  useEffect(() => {
    setTicket(state.returningCustomer?.node?.ticket_id || "");
  }, [state.returningCustomer?.node]);

  useEffect(() => {
    if (state.ticket?.node && state.ticket?.node.ticket_id) {
      setTicket(state.ticket?.node?.ticket_id || "");
    }
  }, []);

  useEffect(() => {
    if (state.ticket?.node && state.ticket?.node?.ticket_id)
      setTicket(state.ticket?.node?.ticket_id);
  }, [state.ticket?.node]);

  const validate = async () => {
    if (!localZipCode) {
      setError(t("zipCodeErrorRequired"));
    }

    if (!localLicencePlate) {
      setErrorLicencePlate(t("licencePlateErrorRequired"));
    }

    // if (!marketingPermission) {
    //   setPolicyError(true);
    // }

    if (
      localEmail !== undefined &&
      localEmail.length > 0 &&
      !localEmail.match(
        //eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorEmail("Invalid Email");
    }

    blurLicencePlate();
    blurZipCode();
    blurEmail();

    // && localEmail
    if (localZipCode && localLicencePlate) {
      blurZipCode();
      blurLicencePlate();
      blurEmail();
      // setPolicyError(false);

      updateUserZipCode({
        variables: {
          ticket_id: state?.ticket?.node?.ticket_id,
          zip_code: localZipCode || "",
        },
      });
      updateTicket({
        variables: {
          ticket_id: ticket,
          zip_code: localZipCode,
          licence_plate: localLicencePlate,
        },
      }).then((res) => {
        dispatch({
          type: "TICKET_ID",
          payload: res.data?.updateTicketLocationVehicle,
        });
        if (
          res.data?.updateTicketLocationVehicle?.node?.ticket_id.trim() !==
            "" &&
          res.data?.updateTicketLocationVehicle?.node?.ticket_id !==
            undefined &&
          ((state.userLocation?.node !== null &&
            state.licence?.node !== null) ||
            state?.returningCustomer?.node !== null)
        ) {
          if (
            !state.returningCustomer.node ||
            state.returningCustomer.node !== undefined
          ) {
            const obj = {
              message: state.returningCustomer.message,
              node: {
                city: state.returningCustomer?.node?.city || "",
                licence_plate: localLicencePlate,
                ticket_id: res.data.updateTicketLocationVehicle.node.ticket_id,
                zip_code: localZipCode || "",
                phone_number: state.returningCustomer?.node?.phone_number || "",
                email: localEmail,
                marketingAccept: marketingPermission,
                fleet_manager_for: state.returningCustomer?.node?.fleet_manager_for,
              },
              status: state.returningCustomer.status,
            };
            dispatch({ type: "RETURNING_CUSTOMER", payload: obj });

            const response = abandonedCart({ first_step: obj.node });
            console.log("abo", response);
            // localStorage.setItem("state", JSON.stringify(obj));
          }

           history.push(`/services`);
        } else {
          if (
            res.data?.updateTicketLocationVehicle?.message ===
              "Location not found" ||
            state.userLocation?.node.zip_code == ""
          ) {
            setError(t("zipCodeErrorInvalid"));
          }
          if (
            res.data?.updateTicketLocationVehicle?.message ===
              "Vehicle not found!" ||
            state.licence?.node.licence_plate.trim() == ""
          ) {
            setErrorLicencePlate(t("licencePlateErrorInvalid"));
          }
          history.push("/");
        }
      });
    }
  };

  return (
    <div className={`${(classes.flexCenter, classes.buttonDivHeight)}`}>
      <SimpleButton
        classes={classes.button}
        onClick={() => {
          validate();
        }}
        type={"submit"}
      >
        <Typography fontFamily={"Poppins, Bold"} fontWeight={"bold"}>
          {" "}
          {t("continueButtonLabel-zipCode")}
        </Typography>
      </SimpleButton>
      {policyError && (
        <FormHelperText
          style={{
            paddingTop: "1%",
            color: "#d32f2f",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography fontSize={12} textAlign="center">
            {t("acceptConditions2")}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
};

export default Buttons;
