import { Grid, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import HeaderMobile from "./SubComponents/HeaderMobile";
import DetailsContentMobile from "./SubComponents/DetailsContentMobile";
import VehicleContentMobile from "./SubComponents/VehicleContentMobile";
import ServicesContentMobile from "./SubComponents/ServicesContentMobile";
import { useStylesMobileSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const SummaryCheckoutMobile: FC = () => {
  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const history = useHistory();
  const classes = useStylesMobileSummaryCheckout();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <Grid item container className={classes.root}>
      <Grid item container className={classes.headerRoot}>
        <HeaderMobile />
      </Grid>
      <Grid item container className={classes.contentRoot}>
        <Grid item container>
          <Grid item container className={classes.contentWrapper}>
            <DetailsContentMobile />
            <div className={classes.borderWrapper}>
              <div className={classes.border}></div>
            </div>
            <VehicleContentMobile />
            <div className={classes.borderWrapper}>
              <div className={classes.border}></div>
            </div>
            <ServicesContentMobile />
          </Grid>
          <Grid className={classes.actionsRoot}>
            <Typography fontSize={12}>
              {"Gå til "}
              <LinkButton
                onClick={() => {
                  scrollToTop();
                  window.location.reload();
                }}
                classes={classes.link}
              >
                {"hjemmeside"}
              </LinkButton>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryCheckoutMobile;
