import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie";
import noInternetConnectionAnimation from "../../../../assets/illustrations/no-internet-animation.json";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStyles } from "./style/ErrorDialogStyle";

const ErrorDialog: FC = () => {
  const classes = useStyles();

  const { state } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // setOpen(state?.errorMessageDialog?.showDialog);
  }, [state?.errorMessageDialog?.showDialog]);
  return (
    <Dialog
      open={open}
      className={classes.root}
      fullWidth={true}
      fullScreen={isMobile ? true : false}
    >
      <DialogContent className={classes.content}>
        <Lottie
          options={{
            animationData: noInternetConnectionAnimation,
          }}
          isClickToPauseDisabled={true}
          height={250}
        />
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontFamily={"Poppins"}
            fontWeight={"medium"}
            fontSize={16}
            textAlign={"center"}
          >
            {state?.errorMessageDialog?.message}
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
