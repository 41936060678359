import { makeStyles } from "@mui/styles";

export const useStylesDesktopServices = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2% 0 0 0",
    maxHeight: "78vh",
    margin: 0,
  },
});
