/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../context/GlobalContext";
import {
  ContactDetailsType,
  ContactGTMDetailsType,
} from "../../../../interfaces-and-unionTypes/UnionTypes";
import { InputEvent } from "../../../Types/Types";
import {
  SeparateZipCode,
  validateEmail,
} from "../../../../Helpers/stringHelpers";
import { isMobile } from "react-device-detect";
import { useLazyQuery, useMutation } from "react-apollo";
import { LocationByZIPCODE } from "../../../../interfaces-and-unionTypes/Interfaces";
import {
  getLocationById,
  updateZipCode,
} from "../../../../API/graphql-queries/Queries";
import { useHistory } from "react-router-dom";
import Unchecked from "../../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../../assets/illustrations/checkboxChecked.svg";
import {makeStyles} from "@mui/styles";

export type ContactDetails = {
  setDetails: (value: any) => void;
  errorMsg: string;
  setGTMDetails: (value: any) => void;
};

const ContactDetailsContent: FC<ContactDetails> = (props) => {
  const { setDetails, errorMsg, setGTMDetails } = props;

  const { state, dispatch } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [fullName, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [isOtherPayer, setIsOtherPayer] = useState(false);
  const [otherPayer, setOtherPayer] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Utm_source, setUtm_source] = useState("");
  const [Utm_medium, setUtm_medium] = useState("");
  const [Utm_campaign, setUtm_campaign] = useState("");
  const [Gclid, setGclid] = useState("");

  const [otherPayerErrorMessage, setOtherPayerErrorMessage] = useState("");
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [streetAddress1ErrorMessage, setStreetAddress1ErrorMessage] =
    useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");
  const isMounted = useRef(false);
  const history = useHistory();
  const useStylesChecbox = makeStyles({
    root1: {
      textAlign: "left",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: isMobile
        //   ? matches
        //     ? "-32.4vh"
        //     : matches2
        //     ? "-16vh"
        //     : "-22vh"
        //   : matches3
        //   ? "-8.4vh"
        //   : "-3.3vh",
        marginTop: isMobile ? "2.7vh" : "2.3vh",
      },
    },
    root2: {
      textAlign: "left",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: isMobile
        //   ? matches
        //     ? "-8vh"
        //     : matches2
        //     ? "-2vh"
        //     : "-4vh"
        //   : "",
        marginTop: isMobile ? "2.7vh" : "2.3vh",
      },
    },
  });

  const classesChecbox = useStylesChecbox();

  useEffect(() => {
    if (errorMsg) {
      if (!fullName) {
        setFullNameErrorMessage("You must enter your name");
      }
      if (!email) {
        setEmailErrorMessage("You must enter your email");
      }
      if (!streetAddress1) {
        setStreetAddress1ErrorMessage("You must enter your street address");
      }
      if (!phoneNumber) {
        setPhoneNumberErrorMessage("You must enter your phone number");
      }
      if (!zipCode) {
        setZipCodeErrorMessage("Postnummer udenfor dækningsområde");
      }
      if(isOtherPayer && !otherPayer) {
        setOtherPayerErrorMessage("You must enter name");
      }
    }
  }, [errorMsg]);

  const [loadLocation, result] = useLazyQuery<Partial<LocationByZIPCODE>>(
    getLocationById,
    {
      onCompleted: (data) => {
        if (data?.getLocationById?.node === null) {
          setZipCode("");
        }
        setCity(data?.getLocationById?.node?.city || "");
        if (
          (result?.data?.getLocationById?.node?.country?.length as number) > 0
        ) {
          dispatch({
            type: "ADD_TO_BASKET",
            payload: {
              ...state?.basketData,
              location_text:
                result?.data?.getLocationById?.node?.zip_code +
                "," +
                result?.data?.getLocationById?.node?.city +
                "," +
                result?.data?.getLocationById?.node?.country,
            },
          });
        }
      },
    }
  );

  const [updateUserZipCode, resultZipCode] = useMutation<Partial<any>>(
    updateZipCode,
    {
      onCompleted: (data) => {
        if (data?.updateZipCode?.node !== null) {
          dispatch({
            type: "ADD_TO_BASKET",
            payload: data?.updateZipCode?.node,
          });
        }
      },
    }
  );

  useEffect(() => {
    {
      state?.basketData?.location_text
        ? loadLocation({
            variables: {
              zip_code: SeparateZipCode(state?.basketData?.location_text) || "",
            },
          })
        : loadLocation({
            variables: { zip_code: state.ticket?.node?.zip_code || "" },
          });
    }
    state?.basketData?.location_text
      ? setZipCode(SeparateZipCode(state?.basketData?.location_text))
      : setZipCode("");
    setFullname(state.contactDetails?.node?.full_name || "");
    setEmail(state.returningCustomer?.node?.email || "");
    fillForms();
  }, []);

  useEffect(() => {
    fillForms();
  }, [state.contactDetails.node]);

  useEffect(() => {
    if (isMounted.current) {
      if (result?.data?.getLocationById?.node === null) {
        setZipCodeErrorMessage("Postnummer udenfor dækningsområde.");
      }
    } else {
      isMounted.current = true;
    }
  }, [result?.data?.getLocationById?.node]);

  useEffect(() => {
    if (result?.data?.getLocationById?.message === "Session expired") {
      alert("Session expired");
      history.push("/");
    }
  }, [result?.data]);

  useEffect(() => {
    if (resultZipCode?.data === null) {
      alert(resultZipCode?.error?.message);
    }
    if (resultZipCode?.data?.updateZipCode?.message === "Session expired") {
      alert("Session expired");
      history.push("/");
    }
  }, [resultZipCode?.data]);

  const fillForms = () => {
    if (
      state.contactDetails.node !== undefined ||
      state.contactDetails.node !== null
    ) {
      if (
        state.contactDetails.node?.full_name !== undefined &&
        state.contactDetails.node?.full_name?.trim() !== ""
      ) {
        setFullname(state.contactDetails?.node?.full_name || "");
      }
      if (
        (state.contactDetails.node?.email !== undefined ||
          state.contactDetails?.node?.email !== null) &&
        state.contactDetails?.node?.email?.trim() !== ""
      ) {
        setEmail(state.contactDetails?.node?.email || "");
      }
      if (
        (state.contactDetails.node?.address !== undefined ||
          state.contactDetails.node?.address !== null) &&
        state.contactDetails?.node?.address?.trim() !== ""
      ) {
        setStreetAddress1(state.contactDetails?.node?.address || "");
      }
      if (
        (state.contactDetails.node?.address2 !== undefined ||
          state.contactDetails?.node?.address2 !== null) &&
        state.contactDetails?.node?.address2?.trim() !== ""
      ) {
        setStreetAddress2(state.contactDetails?.node?.address2 || "");
      }
      if (
        (state.contactDetails.node?.phone !== undefined ||
          state.contactDetails?.node?.phone !== null) &&
        state.contactDetails?.node?.phone?.trim() !== ""
      ) {
        setPhoneNumber(state.contactDetails?.node?.phone || "");
      }
    }
  };

  const propagateDetails = (details: ContactDetailsType) => {
    setDetails(details);
  };

  useEffect(() => {
    const details: ContactDetailsType = {
      fullName: fullName,
      email: email,
      strAddress1: streetAddress1,
      strAddress2: streetAddress2,
      city: city,
      zipCode: zipCode,
      phoneNumber: phoneNumber,
      otherPayer: otherPayer
    };
    propagateDetails(details);
  }, [
    fullName,
    email,
    streetAddress1,
    streetAddress2,
    city,
    phoneNumber,
    zipCode,
    isOtherPayer,
    otherPayer
  ]);

  const propagateGTMDetails = (details: ContactGTMDetailsType) => {
    if (details) setGTMDetails(details);
  };

  useEffect(() => {
    const details: ContactGTMDetailsType = {
      Utm_source,
      Utm_medium,
      Utm_campaign,
      Gclid,
    };
    propagateGTMDetails(details);
  }, [Utm_source, Utm_medium, Utm_campaign, Gclid]);

  useEffect(() => {
    if(state.returningCustomer.node.fleet_manager_for?.payer) {
      setOtherPayer(state.returningCustomer.node.fleet_manager_for?.payer??'')
      setIsOtherPayer(true)
    }
  },[state.returningCustomer.node.fleet_manager_for])

  const clearTimeslotZip = async (val: string) => {
    if (val !== state?.zipCodeChanged) {
      dispatch({
        type: "EMPTY_SELECTED_TIMESLOTS",
        payload: [],
      });
    }
  };
  // const clearTimeslotAddress = async (val: string) => {
  //   if (val !== state?.addressChanged) {
  //     dispatch({
  //       type: "EMPTY_SELECTED_TIMESLOTS",
  //       payload: [],
  //     });
  //   }
  // };
  const [prevZipCode, setPrevZipCode] = useState(state?.ticket?.node.zip_code);

  return (
    <Grid item container>
      <Grid
        item
        container
        style={{ display: "flex", alignItems: "center" }}
      >
        <Grid
          md={6}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: !isMobile ? "1.5%" : "",
            width: !isMobile ? "" : "100%",
            paddingBottom: isMobile ? "4.2vh" : "",
          }}
        >
          <TextField
            label={t("fullNameLabel")}
            className="js-gtm-name"
            error={fullNameErrorMessage.trim() !== "" ? true : false}
            required={true}
            value={fullName}
            onChange={(e: InputEvent) => {
              setFullname(e.target.value);
              setFullNameErrorMessage("");
            }}
            onBlur={() => {
              setFullNameErrorMessage("");
            }}
            helperText={fullNameErrorMessage}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid
          md={6}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: !isMobile ? "" : "100%",
            paddingBottom: isMobile ? "2vh" : "",
          }}
        >
          <TextField
            label={t("emailLabel")}
            className="js-gtm-email"
            error={emailErrorMessage.trim() !== "" ? true : false}
            required={true}
            value={email}
            type={"email"}
            onChange={(e: InputEvent) => {
              setEmail(e.target.value);
              setEmailErrorMessage("");
            }}
            onBlur={(e: InputEvent) => {
              if (validateEmail(e.target.value)) setEmail(e.target.value);
              else {
                setEmailErrorMessage("Invalid email.");
              }
            }}
            helperText={emailErrorMessage}
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingBottom: !isMobile ? "2.5%" : "5%",
          marginLeft: !isMobile ? "0.5%" : "1.3%",
          width: !isMobile ? "" : "100%",
        }}
      >
        <Typography fontWeight={800}>
          {t("addressAndPhoneLabel")}
          <span style={{ fontWeight: "400" }}>{t("forTheCurrentTask")}</span>
        </Typography>
      </Grid>
      <Grid item container style={{ display: "block", paddingBottom: "2%" }}>
        <Grid
          md={6}
          item
          style={{
            maxWidth: !isMobile ? "100%" : "",
            width: "100%",
            paddingBottom: isMobile ? "4.2vh" : "",
          }}
        >
          <TextField
            label={t("streetAddressLabel")}
            className="js-gtm-street"
            error={streetAddress1ErrorMessage.trim() !== "" ? true : false}
            required={true}
            value={streetAddress1}
            onChange={(e: InputEvent) => {
              setStreetAddress1(e.target.value);
              setStreetAddress1ErrorMessage("");
              // clearTimeslotAddress(e.target.value);
            }}
            onBlur={(e: InputEvent) => {
              const value = (e.target as HTMLInputElement).value;
              if (e.target.value.trim() !== "") {
                dispatch({ type: "ADDRESS_CHANGED", payload: value });
                // dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
                setStreetAddress1(e.target.value);
                setStreetAddress1ErrorMessage("");
              } else {
                dispatch({ type: "ADDRESS_CHANGED", payload: value });
                // dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
                setStreetAddress1ErrorMessage("Invalid address");
              }
            }}
            helperText={streetAddress1ErrorMessage}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        {/* <Grid
          md={6}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: !isMobile ? "" : "100%",
            paddingBottom: isMobile ? "4.2vh" : "",
          }}
        >
          <TextField
            label={t("streetAddress2Label")}
            value={streetAddress2}
            onChange={(e: InputEvent) => {
              setStreetAddress2(e?.target?.value);
            }}
            style={{
              width: "100%",
            }}
          />
        </Grid> */}
      </Grid>
      <Grid
        item
        container
        style={{ display: "flex", alignItems: "center", paddingBottom: "2%" }}
      >
        <Grid
          md={6}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: !isMobile ? "1.5%" : "",
            width: !isMobile ? "" : "100%",
            paddingBottom: isMobile ? "4.2vh" : "",
          }}
        >
          <TextField
            value={zipCode}
            label={t("zipCodeLabel")}
            className="js-gtm-zip"
            onChange={(e: InputEvent) => {
              setZipCode(e.target.value);
              setZipCodeErrorMessage("");
              clearTimeslotZip(e.target.value);
            }}
            onBlur={(e: InputEvent) => {
              const value = (e.target as HTMLInputElement).value;
              if (value.trim() !== "") {
                loadLocation({
                  variables: { zip_code: value || "" },
                });
                if (value !== prevZipCode) {
                  updateUserZipCode({
                    variables: {
                      ticket_id: state?.ticket?.node?.ticket_id,
                      zip_code: value || "",
                    },
                  });
                  dispatch({ type: "ZIP_CODE_CHANGED", payload: value });
                  dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
                  setPrevZipCode(value);
                }
                setZipCodeErrorMessage("");
                setZipCode(value);
              } else {
                dispatch({ type: "ZIP_CODE_CHANGED", payload: value });
                dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
                setZipCodeErrorMessage("Postnummer udenfor dækningsområde");
              }
            }}
            style={{
              width: "100%",
            }}
            inputProps={{
              inputMode: "numeric",
              maxLength: 4,
            }}
          />
        </Grid>
        {isMobile ? (
          <Grid
            style={{
              marginTop: "-5vh",
            }}
          >
            {zipCodeErrorMessage.trim() !== "" ? (
              <FormHelperText
                id="component-helper-text"
                style={{
                  width: "100%",
                  color: "#d32f2f",
                }}
              >
                {zipCodeErrorMessage + " "}
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
        ) : null}
        <Grid
          md={6}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: !isMobile ? "" : "100%",
            paddingBottom: isMobile ? "4.2vh" : "",
          }}
        >
          <TextField
            label={t("cityLabel")}
            className="js-gtm-city"
            required
            value={city}
            disabled={state.ticket?.node.city ? true : false}
            onChange={(e: InputEvent) => {
              setCity(e.target.value);
              setZipCodeErrorMessage("");
            }}
            style={{
              width: "100%",
            }}
          />
        </Grid>
        {!isMobile ? (
          <Grid>
            {zipCodeErrorMessage.trim() !== "" ? (
              <FormHelperText
                id="component-helper-text"
                style={{
                  // display: "flex",
                  width: "100%",
                  color: "#d32f2f",
                }}
              >
                {zipCodeErrorMessage + " "}
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
        ) : null}
      </Grid>
      <Grid item container style={{ display: "flex", alignItems: "center" }}>
        <Grid
          item
          container
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TextField
            style={{ width: "100%" }}
            label={t("phoneNumberLabel")}
            className="js-gtm-phone"
            placeholder={t("phoneNumberLabel")}
            required={true}
            error={phoneNumberErrorMessage.trim() !== "" ? true : false}
            variant="outlined"
            value={phoneNumber}
            onChange={(e: InputEvent) => {
              const value = (e.target as HTMLInputElement).value;
              const phoneRegex = new RegExp("^[0-9]{0,8}$");
              if (phoneRegex.test(value)) {
                setPhoneNumber(value);
                setPhoneNumberErrorMessage("");
              }
            }}
            onBlur={(e: InputEvent) => {
              const value = (e.target as HTMLInputElement).value;
              const phoneRegex = new RegExp("^[0-9]{0,8}$");
              if (value.length < 8) {
                setPhoneNumberErrorMessage("Invalid phone number.");
              }
              if (phoneRegex.test(value)) {
                setPhoneNumber(value);
              } else {
                setPhoneNumberErrorMessage("Invalid phone number.");
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+45</InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "^[0-9]{0,8}$",
              maxLength: 8,
            }}
            helperText={phoneNumberErrorMessage}
          />
        </Grid>
      </Grid>
      <Grid
          item
          container
          style={{ display: "flex", alignItems: "center",paddingBottom: isMobile ? "" : "4%", }}
      >
        <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingRight: !isMobile ? "1.5%" : "",
              width: !isMobile ? "" : "100%",
              paddingBottom: isMobile ? "2vh" : "",
            }}
        >
          <FormControlLabel
              sx={{ display: "flex" }}
              control={
                <div style={{ display: "flex", alignItems: "center", marginTop: '-8px' }}>
                  <Checkbox
                      style={{
                        color: "#8D88FF",
                      }}
                      icon={<img src={Unchecked} />}
                      checkedIcon={<img src={Checked} />}
                      onChange={(e: any) => {
                        setIsOtherPayer(!isOtherPayer)
                        setOtherPayer("")
                      }}
                      checked={isOtherPayer}
                  />
                </div>
              }
              label={
                <Typography fontWeight={600} fontStyle={'italic'} fontSize={12}>Anden betaler</Typography>
              }
              className={classesChecbox.root1}
          />
        </Grid>
        { isOtherPayer && <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: !isMobile ? "" : "100%",
              paddingBottom: isMobile ? "4.2vh" : "",
            }}
        >
          <TextField
              label={"Anden betaler"}
              error={otherPayerErrorMessage.trim() !== ""}
              required={true}
              value={otherPayer}
              type={"text"}
              onChange={(e: InputEvent) => {
                setOtherPayer(e.target.value);
                setOtherPayerErrorMessage("");
              }}
              onBlur={(e: InputEvent) => {
                setOtherPayer(e.target.value)
              }}
              helperText={otherPayerErrorMessage}
              style={{
                width: "100%",
              }}
          />
        </Grid> }
      </Grid>
      <Grid
        item
        container
        style={{ display: "flex", alignItems: "center", opacity: "0", position: "absolute",left:"-1000px",top:"-1000px" }}
      >
        <div className="utm_source_class">
          <input
            id="utm_source_id"
            name="utm_source_field"
            value={Utm_source}
            onChange={(e) => setUtm_source(e.target.value)}
          ></input>
        </div>
        <div className="utm_medium_class">
          <input
            id="utm_medium_id"
            name="utm_medium_field"
            value={Utm_medium}
            onChange={(e) => setUtm_medium(e.target.value)}
          ></input>
        </div>
        <div className="utm_campaign_class">
          <input
            id="utm_campaign_id"
            name="utm_campaign_field"
            value={Utm_campaign}
            onChange={(e) => setUtm_campaign(e.target.value)}
          ></input>
        </div>
        <div className="gclid_class">
          <input
              id="gclid_id"
              name="gclid_field"
              value={Gclid}
              onChange={(e) => setGclid(e.target.value)}
          ></input>
          <input
              type="hidden"
              id="zc_gad"
              name="zc_gad"
              value=""
          ></input>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactDetailsContent;
