import { Grid } from "@mui/material";
import React, { FC } from "react";
import BasketMobile from "../../../../Summary/BasketMobile/BasketMobile";
import TrustBox from "../../../../TrustBox/TrustBox";
import ServicingAndMotTables from "../SubComponents/ServicingAndMotTables";
import ServicingAndMOTCartOptionsMobile from "./SubComponents/ServicingAndMOTCartOptionsMobile";
import { useStylesServicing } from "../Styles/ServicingStyles";

const ServicingAndMotMobile: FC = () => {
  const classes = useStylesServicing();

  return (
    <Grid item container className={classes.root}>
      <TrustBox />
      <Grid>
        <ServicingAndMOTCartOptionsMobile />
        <ServicingAndMotTables />
      </Grid>
      <BasketMobile />
    </Grid>
  );
};

export default ServicingAndMotMobile;
