/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ActionCard from "../../../library-components/ActionCard/ActionCard";
import { CardStyleType } from "../../../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import ContactDetailsContent from "./ContactDetailsContent";
import ContactDetailsHeader from "./ContactDetailsHeader";

export type ContactDetails = {
  setContactDetails: (value: any) => void;
  errorMsg: string;
  setGTMContatcDetails: (value: any) => void;
};

const ContactDetails: FC<ContactDetails> = (props) => {
  const { setContactDetails, errorMsg, setGTMContatcDetails } = props;

  const [details, setDetails] = useState({});
  const [gtmDetails, setGTMDetails] = useState({});

  const style: CardStyleType = {
    header: {
      padding: !isMobile ? "2% 2% 0px 3%" : "4% 5.5% 0px 6.5%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    content: {},
    actions: undefined,
    container: {
      padding: "0.5rem",
      width: !isMobile ? "45vw" : "95%",
    },
    boxHeight: {
      width: !isMobile ? "45vw" : "100%",
    },
  };
  useEffect(() => {
    setContactDetails(details);
  }, [details]);

  useEffect(() => {
    setGTMContatcDetails(gtmDetails);
  }, [gtmDetails]);

  return (
    <Grid
      item
      container
      md={!window.location.href.includes("checkout") ? 4 : 12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        width: "90%",
        marginTop: !isMobile ? "" : 20,
      }}
    >
      <ActionCard
        Header={<ContactDetailsHeader />}
        Content={
          <ContactDetailsContent
            errorMsg={errorMsg}
            setDetails={setDetails}
            setGTMDetails={setGTMDetails}
          />
        }
        style={style}
      />
    </Grid>
  );
};

export default ContactDetails;
