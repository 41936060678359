import React, {
  // useContext,
  useEffect,
  useState,
  FC,
} from "react";
import { useStylesMobile } from "./NavbarStyle";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
// import { GlobalContext } from "../../../context/GlobalContext";
import { checkpoints } from "./checkpoints";
const ProgressTrackMobile: FC = () => {
  const classes = useStylesMobile();

  // const { state } = useContext(GlobalContext);
  // const [inProgress, setInProgress] = useState(false);

  const [activeStep, setActiveStep] = useState(-1);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  // useEffect(() => {
  //   setInProgress(true);
  // }, [state.vehicleCheckpoint.IN_PROGRESS]);

  // useEffect(() => {
  //   setInProgress(false);
  // }, [state.vehicleCheckpoint.COMPLETED]);

  const location = useLocation();

  const totalSteps = () => {
    return checkpoints.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? checkpoints.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    handleComplete();
  };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  useEffect(() => {
    let current = 0;
    if (location.pathname === "/" || location.pathname === "/services") {
      if (activeStep !== 0) {
        setActiveStep(0);
        current = 0;
      }
    } else if (
      location.pathname.includes("repairs") ||
      location.pathname.includes("glass") ||
      location.pathname.includes("servicing") ||
      location.pathname.includes("tyres") ||
      location.pathname.includes("inspections")
    ) {
      if (activeStep !== 1) {
        setActiveStep(1);
        current = 1;
      }
    } else if (location.pathname.includes("checkout")) {
      if (activeStep !== 2) {
        setActiveStep(2);
        current = 2;
      }
    } else if (location.pathname.includes("summary")) {
      if (activeStep !== 3) {
        setActiveStep(3);
        current = 3;
      }
    }
    for (let i = 0; i <= current; i++) {
      completed[i] = true;
    }
  }, [location]);

  return (
    <Grid item container md={9} className={classes.rootProgressTrack}>
      {"Step " + (activeStep + 1) + " / 4"}
    </Grid>
  );
};

export default ProgressTrackMobile;
