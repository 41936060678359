import { Grid } from "@mui/material";
import React, { FC } from "react";
import BasketMobile from "../../../../../Summary/BasketMobile/BasketMobile";
import TrustBox from "../../../../../TrustBox/TrustBox";
import RepairsMobile from "../RepairsMobile";
import { useStylesMobileRepairs } from "../../Styles/RepairsStyles";

const RepairsWrapperMobile: FC = () => {
  const classes = useStylesMobileRepairs();
  return (
    <Grid item container className={classes.repairsWrapper}>
      <TrustBox />
      <Grid>
        <RepairsMobile />
      </Grid>
      <BasketMobile />
    </Grid>
  );
};

export default RepairsWrapperMobile;
