export const months = [...Array.from(Array(12).keys())].map((key) =>
  new Date(0, key).toLocaleString("en", { month: "long" })
);
export const normalizeDate = (date: string): Date => {
  const datesSplitted = date.split("-");
  const day = datesSplitted[0];
  const month = datesSplitted[1];
  const year = datesSplitted[2];

  const newDate = month + "/" + day + "/" + year;
  return new Date(newDate);
};

export const dayMonthDate = (date: string): string => {
  const datesSplitted = date.split("/");
  const day = datesSplitted[0];
  const month = datesSplitted[1];
  const year = datesSplitted[2];

  const newDate = day + "/" + month + "/" + year;
  return newDate;
};

export const localizeDateFull = (date: Date): string => {
  const day=date.getDate();
  let dayString=day.toString()
  if(day<10){
    dayString=dayString.substring(0,0)+"0"+dayString.substring(0,dayString.length)
  }
  const month=(date.getMonth()+1);
  let monthString=month.toString();
  if(month<10){
   monthString=monthString.substring(0,0)+"0"+monthString.substring(0,monthString.length)
  }
  const yearString =date.getFullYear().toString();
  const newDate = dayString + "/" + monthString + "/" + yearString;
  return newDate;
  // return date.toLocaleString("default", {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  // });
  
};
export const localizeDateShort = (date: Date): string => {
  return date.toLocaleString("default", {
    day: "numeric",
    month: "numeric",
  });
};

export const toWeekDayLong = (date: Date): string => {
  return date.toLocaleString("default", {
    weekday: "long",
  });
};
export const toWeekDayShort = (date: Date): string => {
  return date.toLocaleString("default", {
    weekday: "short",
  });
};

export const splitWeekDay=(date:string):string[]=>{
  const splitted=date.split(" ");
  return splitted
}

export const setToMonday=(date:Date):Date=>{
  if(date.getDay()===0){
    return new Date(date.getTime()-6*24*60*60*1000)
  }
  else if(date.getDay()===2){
    return new Date(date.getTime()-24*60*60*1000)
  }
  else if(date.getDay()===3){
    return new Date(date.getTime()-2*24*60*60*1000)
  }
  else if(date.getDay()===4){
    return new Date(date.getTime()-3*24*60*60*1000)
  }
  else if(date.getDay()===5){
    return new Date(date.getTime()-4*24*60*60*1000)
  }
  else if(date.getDay()===6){
    return new Date(date.getTime()-5*24*60*60*1000)
  }
  else{
    return date;
  }
}

