import { Grid } from "@mui/material";
import React, { FC } from "react";
import { ServicingAndMOTWrapperProps } from "../interfaces/interfaces";
import ServicingAndMotTabPanel from "../ServicingAndMotTabPanel";
import ServicingAndMOTCartOptions from "../SubComponents/ServicingAndMOTCartOptions";
import ServicingAndMotTables from "../SubComponents/ServicingAndMotTables";

const ServicingAndMotTabPanelWrapper: FC<ServicingAndMOTWrapperProps> = (
  props
) => {
  const { value } = props;

  return (
    <Grid>
      <ServicingAndMotTabPanel index={2} value={value}>
        <ServicingAndMOTCartOptions />
        <ServicingAndMotTables />
      </ServicingAndMotTabPanel>
    </Grid>
  );
};

export default ServicingAndMotTabPanelWrapper;
