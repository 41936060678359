import { makeStyles } from "@mui/styles";

export const useStylesDesktopServices = makeStyles({
  rootCluster: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "3% 0 0 0%",
    margin: 0,
  },
  root: {
    width: "60vw",
  },
  clusterWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  tabs: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    padding: "0 4% 0 4%",
  },
  borderWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  border: {
    width: "55vw",
    border: "1px solid",
    borderColor: "rgba(112, 112, 112, 0.2)",
  },
});
