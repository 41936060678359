export const validateEmail = (email: string): boolean => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const splitUserInfo = (info: string): string => {
  const infoSplitted = info.split(",");
  const zip = infoSplitted[0];
  const city = infoSplitted[1];
  const country = infoSplitted[2];
  const newInfo = zip + " - " + city + " - " + country;
  return newInfo;
};
export const SeparateZipCode = (info: string): string => {
  const infoSplitted = info.split(",");
  const zip = infoSplitted[0];
  return zip;
};
