import { useLazyQuery } from "@apollo/react-hooks";
import React, { FC, useContext, useEffect, useState } from "react";
import { getAutigData } from "../../../API/graphql-queries/Queries";
import { InputEvent, LicencePlateForm } from "../../Types/Types";
import { GlobalContext } from "../../../context/GlobalContext";
import { AutigByLicencePlate } from "../../../interfaces-and-unionTypes/Interfaces";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";
import InputField from "../../library-components/InputFields/InputFields";
import { useTranslation } from "react-i18next";
import { Box, FormControl } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

const ContentLicencePlate: FC<LicencePlateForm> = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const { setLocalLicencePlate, licenceError, licencePlateRef } = props;
  const [licence_plate_local, setLicensePlateLocal] = useState("");

  const [error, setError] = useState("");

  const classes = useStylesDesktop();

  const history = useHistory();

  useEffect(() => {
    setLicensePlateLocal(state.returningCustomer?.node?.licence_plate || "");
    setLocalLicencePlate(state.returningCustomer?.node?.licence_plate || "");
  }, [state.returningCustomer?.node, state.clearVehicleForm]);

  useEffect(() => {
    setError(licenceError);
  }, [licenceError.trim() !== ""]);

  useEffect(() => {
    setLicensePlateLocal(state.licence?.node?.licence_plate || "");
    setLocalLicencePlate(state.licence?.node?.licence_plate || "");
    if (
      state.returningCustomer?.node !== undefined &&
      state.returningCustomer?.node?.licence_plate?.trim() !== ""
    ) {
      setLicensePlateLocal(state.returningCustomer?.node?.licence_plate || "");
      setLocalLicencePlate(state.returningCustomer?.node?.licence_plate || "");
    }
  }, []);

  useEffect(() => {
    if (state.queryParams?.reg) {
      setLicensePlateLocal(
        state.queryParams?.reg || ""
      );
      setLocalLicencePlate(
        state.queryParams?.reg || ""
      );
    }
  }, [state.queryParams?.reg]);

  const [getAutigDataByLicencePlate, result] = useLazyQuery<
    Partial<AutigByLicencePlate>
  >(getAutigData, {
    onCompleted: (data) => {
      dispatch({ type: "LICENCE_PLATE_AUTIG", payload: data?.getAutigData });
    },
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    }
    if (result?.data?.getAutigData?.message === "Session expired") {
      alert("Session expired");
      history.push("/");
    }
  }, [result?.data]);

  const validateLicencePlate = () => {
    if (result?.data?.getAutigData?.node === null) {
      setError(t("licencePlateErrorInvalid"));
    } else {
      setError("");
    }
  };

  useEffect(() => {
    validateLicencePlate();
  }, [result?.data?.getAutigData?.node]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ width: !isMobile ? "28vw" : "80vw" }}
    >
      <FormControl className={classes.formControl}>
        <InputField
          inputRef={licencePlateRef}
          id="standard-basic"
          error={error.trim() !== "" && true}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={(e: InputEvent) => {
            setLicensePlateLocal(e.target.value);
            setLocalLicencePlate(e.target.value);

            if (e.target.value.trim() === "" || e.target.value.length === 1)
              setError("");
          }}
          onBlur={(e: InputEvent) => {
            if (e.target.value.trim() === "" && e.target.value.length === 0) {
              setError(t("licencePlateErrorRequired"));
            } else {
              getAutigDataByLicencePlate({
                variables: { licence_plate: licence_plate_local },
              });
            }
          }}
          classesProp={classes.textField}
          required={true}
          variant="outlined"
          label={t("licencePlateLabel")}
          value={licence_plate_local}
          placeholder={t("licencePlatePlaceholder")}
          helperText={error}
        />
      </FormControl>
    </Box>
  );
};

export default ContentLicencePlate;
