import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const DetailsContent: FC = () => {
  const { state } = useContext(GlobalContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [strAddress1, setStrAddress1] = useState("");
  const [strAddress2, setStrAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const classes = useStylesDesktopSummaryCheckout();

  useEffect(() => {
    setFullName(state.contactDetailsInfo?.fullName || "");
    setEmail(state.contactDetailsInfo?.email || "");
    setStrAddress1(state.contactDetailsInfo?.strAddress1 || "");
    setStrAddress2(state.contactDetailsInfo?.strAddress2 || "");
    setCity(state.contactDetailsInfo?.city || "");
    setZipCode(state.contactDetailsInfo?.zipCode || "");
    setPhoneNumber(state.contactDetailsInfo?.phoneNumber || "");
  }, []);

  return (
    <Grid className={classes.cardRoot}>
      <Grid className={classes.cardWrapper}>
        <Typography className={classes.cardTitleText}>
          {"Kontaktoplysninger"}
        </Typography>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Fulde navn</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Email</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Serviceadresse</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {strAddress1}
            </Typography>
          </Grid>
          {strAddress2 ? (
            <Grid style={{ width: "42%" }}>
              <Typography
                fontSize={16}
                fontFamily={"Poppins, Bold"}
                fontWeight={"bold"}
              >
                {strAddress2}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Postnummer</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {zipCode}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>By</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {city}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Telefonnummer</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {"+45 " + phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContent;
