// import React, { useContext } from 'react';
import React from "react";
import { AppBar, Grid, Toolbar } from "@mui/material";
import NavbarMobile from "./NavbarMobile";
import { useStyles } from "./NavbarStyle";
import { isDesktop, isMobile } from "react-device-detect";
import NavbarDesktop from "./NavbarDesktop";

const Navbar: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.header} variant="dense">
        <Grid
          item
          container
          md={12}
          height={!isMobile ? "11.2vh" : "100%"}
          className={classes.wrapper}
        >
          {isDesktop ? <NavbarDesktop /> : isMobile && <NavbarMobile />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
