import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SyntheticEvent} from "react";
import {GlobalContext} from "../../context/GlobalContext";
import {useApolloClient} from "react-apollo";
import {computeService, getServiceCluster} from "../../API/graphql-queries/Queries";
import {ServiceClusterType} from "./enumerations/enumerations";
import RepairsWrapperMobile from "./TabPanels/RepairsTabPanel/RepairsTabPanelMobile/wrappersMobile/RepairsWrapperMobile";
import ServicingAndMotMobile from "./TabPanels/ServicingAndMotTabPanel/ServicingAndMotTabPanelMobile/ServicingAndMotMobile";
import WindshieldMobile from "./TabPanels/WindshieldGlassTabPanel/WindshieldMobile/WindshieldMobile";
import TyresWrapperMobile from "./TabPanels/TyresTabPanel/TyresMobile/TyresWrapperMobile";
import PrepurchaseInspectionMobile from "./TabPanels/PrePurchaseInspections/PrepurchaseInspectionsMobile/PrepurchaseInspectionMobile";
import ServiceLoaderDialog from "../library-components/Dialogs/ServiceLoaderDialog";

const ServicesClusterTabsMobileWrapper: React.FC = () => {
    const [value, setValue] = useState(0);
    const history = useHistory();
    const [currLoc, setCurrLoc] = useState(window.location.pathname || "");
    const {state, dispatch} = useContext(GlobalContext);
    const apolloClient = useApolloClient()
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const unlisten = history.listen((location) => {
            setCurrLoc(location.pathname);
        });

        // Cleanup by unsubscribing the listener when the component unmounts
        return () => unlisten();
    }, [history]);

    const {t} = useTranslation();


    const handleChange = (event: SyntheticEvent, newValue: number) => {
        switch (newValue) {
            case 0:
                history.push("/services/repairs");
                break;
            case 1:
                history.push("/services/tyres");
                break;
            case 2:
                history.push("/services/servicing");
                break;
            case 3:
                history.push("/services/inspections");
                break;
            case 4:
                history.push("/services/glass");
                break;
        }
    };

    useEffect(() => {
        const currentLocation = window.location.pathname;
        if (currentLocation.includes("repairs")) {
            setValue(0);
        } else if (currentLocation.includes("tyres")) {
            setValue(1);
        } else if (currentLocation.includes("servicing")) {
            setValue(2);
        } else if (currentLocation.includes("inspections")) {
            setValue(3);
        } else if (currentLocation.includes("glass")) {
            setValue(4);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currLoc]);


    useEffect(() => {
        (async () => {
            if (state.queryParams?.service_ids) {
                setLoading(true)

                const servicesToLoad: Array<string> = []

                const fetchServices = async (service_cluster: string) => {
                    return await apolloClient.query({
                        query: getServiceCluster,
                        variables: {
                            service_cluster,
                            ticket_id: state.ticket?.node?.ticket_id || "",
                        }
                    })
                }
                if(state.queryParams?.service_ids) {
                    for (const service_id of state.queryParams?.service_ids) {
                        const first = service_id.charAt(0)
                        let basket: any = null;
                        switch (first) {
                            case '1':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Repairs)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            if (sub?.service_details?.length > 1) {
                                                for (const subEl of sub?.service_details) {
                                                    if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                        basket = await handleAddClick(subEl.service_id)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            } else {
                                                if (state.queryParams?.service_ids?.includes(sub?.service_details[0].service_id)) {
                                                    basket = await handleAddClick(sub?.service_details[0].service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first)
                                break;
                            case '2':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Tyres)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    if (subEl.tyre_spec !== "1") {
                                                        basket = await handleAddClick(subEl.service_id, subEl.mileage)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '5':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Servicing)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    if (subEl.mileage.trim() !== "1") {
                                                        basket = await handleAddClick(subEl.service_id)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '3':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Prepurchase)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    basket = await handleAddClick(subEl.service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '4':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Glass)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    basket = await handleAddClick(subEl.service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                        }
                    }
                    //clear query params
                    dispatch({
                        type: "QUERY_PARAMS",
                        payload: {coupon_code: state.queryParams?.coupon_code??''},
                    });
                }
                setLoading(false)
            }
        })();
    }, []);

    const handleAddClick = async (serviceId: string, milage = "") => {
        return await apolloClient.query({
            query: computeService,
            variables: {
                is_selected: 1,
                service_id: serviceId,
                ticket_id: state.ticket?.node.ticket_id || "",
                milage: milage,
                tyre_data: null,
            }
        });
    };

    const renderService = () => {
        switch (value) {

            case 0: return (<RepairsWrapperMobile></RepairsWrapperMobile>);
            case 1: return (<TyresWrapperMobile></TyresWrapperMobile>);
            case 2: return (<ServicingAndMotMobile></ServicingAndMotMobile>);
            case 3: return (<PrepurchaseInspectionMobile></PrepurchaseInspectionMobile>);
            case 4: return (<WindshieldMobile></WindshieldMobile>);
        }
        return <></>
    }


    return (
        <>
            {renderService()}
            <ServiceLoaderDialog open={loading} close={() => {return;}}/>
        </>
    )


};

export default ServicesClusterTabsMobileWrapper;
