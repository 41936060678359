import React, { FC } from 'react';
import './loading-car.css'

const ProgressCarLoading: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="96.155" height="34.751" viewBox="0 0 96.155 34.751" className='loading'>
            <path id="Path_347" data-name="Path 347" className='loading-fill'
                d="M83.347,204.273l6.443-.307a5.377,5.377,0,0,1,5.216,5.216v8.284a5.377,5.377,0,0,1-5.216,5.216H84.267a9.031,9.031,0,0,0-8.9-10.125,8.808,8.808,0,0,0-8.591,10.125H23.822c.92-5.216-3.375-10.125-8.9-10.125a8.808,8.808,0,0,0-8.591,10.125H5.106c-5.83,0-7.671-9.512-.92-12.58,5.83-3.068,17.182-4.3,21.478-4.6,5.523-6.443,16.568-10.739,29.455-10.739,11.659,0,22.091,3.682,28.228,9.512Zm-31.3-3.068h1.227v-4.3c-9.512.307-18.716,3.375-23.318,8.9,7.057,0,13.807,0,20.864-.307v-2.762a1.4,1.4,0,0,1,1.227-1.534Zm23.318,12.887a7.467,7.467,0,0,1,7.364,7.364,7.371,7.371,0,0,1-14.728,0A7.467,7.467,0,0,1,75.369,214.091Zm-60.445,0a7.269,7.269,0,0,1,7.364,7.364,7.02,7.02,0,0,1-7.364,7.057,6.943,6.943,0,0,1-7.057-7.057,7.206,7.206,0,0,1,7.057-7.364Zm40.193-17.182V205.5c7.978-.307,15.955-.614,23.625-.92-4.909-4.909-14.114-7.671-23.625-7.671Z"
                transform="translate(0.65 -194.261)" fill="none" stroke="#231f20" strokeWidth="1" fillRule="evenodd" />
        </svg>
    )
}

export default ProgressCarLoading;
