import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import ApolloClient from "apollo-boost";
import ApolloConfig from "./API/ApolloConfig/ApolloConfig";
import { ApolloProvider } from "react-apollo";
import { GlobalStateProvider } from "./context/GlobalState";
import i18n from "i18next";
const client: any = new ApolloClient(ApolloConfig);
import ReactGA from "react-ga";
ReactGA.initialize("UA-98802810-4");
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY || ""
}

TagManager.initialize(tagManagerArgs)
// ReactGA.pageview(window.location.pathname + window.location.search);
/**
 *
 * @returns The Application with Apollo provider (client)
 */

const options = {
  autoConfig: false,

  debug: false,
};

declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

window.onbeforeunload = () => {
  scrollToTop();
};

window.onload = () => {
  scrollToTop();
};

const AppWithProvider: React.FC = () => {
  const asyncCall = async () => {
    const lang = localStorage.getItem("i18nextLng") || "en";
    if (i18n?.services?.languageUtils) {
      i18n.changeLanguage(lang);
    }
  };
  asyncCall();
  return (
    <GlobalStateProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </GlobalStateProvider>
  );
};

ReactDOM.render(<AppWithProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
