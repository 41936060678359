/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
declare global {
  interface Window {
    Trustpilot: any;
  }
}
const TrustBoxMini = (): ReactElement => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5f72d110bc819a00018e9a45"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/omnicarservice.dk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBoxMini;
