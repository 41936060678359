import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";
import { HeaderProps } from "../Types/interfaces";

export const useStylesDesktop = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column !important" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2% 0 0 0",
  },
  textField: {
    width: !isMobile ? "100% !important" : "80vw !important",
  },
  contentComponent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  contentComponentDiv: {
    width: "100vw",
    marginBottom: "2vh",
  },
  innerContainer: {
    marginTop: !isMobile ? "25px" : "20px",
  },
  formControl: {
    width: isMobile ? "" : "100% !important",
  },
  formControlHelperTextRoot: {
    margin: "0px!important",
  },
  formControlHelperTextZipCodeInvalidError: {
    width: "100%",
    color: "#d32f2f !important",
    marginLeft: isMobile ? "0" : "14px",
  },
  formControlHelperTextInvalidErrorLink: {
    textTransform: "lowercase",
    color: "#4FC1E1 !important",
    textDecorationColor: "#4FC1E1 !important",
  },
  formControlHelperTextZipCodeRequiredError: {
    width: "100%",
    color: "#d32f2f!important",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  buttonDivHeight: {
    height: "8vh",
  },
  button: {
    width: isMobile ? "80vw" : "215px",
    maxWidth: isMobile ? "" : "215px",
    height: "47px",
    color: "white !important",
    backgroundColor: "#5F58FE !important",
  },
  width100percent: {
    marginTop: "30px",
    width: "100% !important",
  },
  zeroMarginPadding: {
    margin: "0px !important",
    padding: "0px !important",
  },
  actionsWrapperPadding: {
    padding: "2% !important",
    width: "100%",
  },
  linkStyle: {
    color: "#1A1D1E !important",
    cursor: "pointer !important",
    textDecorationColor: "#1A1D1E !important",
    fontFamily: "Poppins, Bold !important",
    fontWeight: "1000 !important",
  },
  border: {
    width: "27.5vw !important",
    border: "1px solid !important",
    borderColor: "rgba(112, 112, 112, 0.2) !important",
    marginTop: "-5% !important",
  },
  zeroPadding: {
    padding: "0px !important",
  },
  paddingBottomHeaderGrid: {
    padding: "0 0 2% 0 !important",
  },
  gridContent: {
    padding: "0 0 3% 0 !important",
    width: "23vw !important",
  },
  paddingBottomActionsGrid: {
    padding: "0 0 5% 0 !important",
  },
});

export const useHeaderStyles = makeStyles<Theme, HeaderProps>({
  rootHeaderGrid: (props) => {
    return {
      display: "flex",
      justifyContent: "end",
      padding: isMobile
        ? "3.267806267806268vh 5vw 1vh 5vw"
        : props.matches
        ? "2rem 4.5rem 1rem"
        : "6.267806267806268vh 4.2vw 4vh 14.4%",
    };
  },
  typographyTitle: (props) => {
    return {
      fontSize: "17px !important",
      width: "100%",
    };
  },
  linkWrapperContainer: (props) => {
    return {
      paddingLeft: isMobile ? "" : props.matches ? "" : "1.75rem",
      paddingBottom: "1rem",
      maxHeight: !isMobile ? "2vw" : "5vw",
    };
  },
  linkButton: (props) => {
    return {
      color: "#1A1D1E!important",
      paddingRight: props.matches ? "0" : "1.75rem",
      cursor: "pointer!important",
      textDecorationColor: "#1A1D1E!important",
    };
  },
  linkTextFont: {
    fontSize: "14px !important",
  },
  linkErrorWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "14.3%",
  },
  linkErrorTypography: (props) => {
    return {
      fontSize: "12px!important",
      paddingLeft: "8px!important",
      paddingRight: !props.matches ? "27px!important" : "",
      color: props.error ? "#DA0707" : "",
    };
  },
  headerErrorMobileReturningCustomer: (props) => {
    return {
      fontSize: "12px",
      paddingLeft: "8px",
      color: "#DA0707",
    };
  },
});
