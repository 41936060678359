/* eslint-disable @typescript-eslint/no-explicit-any */
import { Info } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import {
  computeService,
  getServiceCluster,
} from "../../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { ComputeServiceDataResponse } from "../../../../../../interfaces-and-unionTypes/Interfaces";
import { SimpleButton } from "../../../../../library-components/Buttons/Buttons";
import ServicesInfoDialog from "../../../../../library-components/Dialogs/ServicesInfoDialog";
import { ServiceClusterType } from "../../../../enumerations/enumerations";
import { formatPrice } from "../../../PanelFunctions";
import { TyreCartOptionsI } from "../../interfaces/interfaces";
import AddRemoveButton from "../../SubComponents/AddRemoveButton";
import {
  useStylesTyresCartOptions,
  useStylesTyresButtons,
} from "../../Styles/TyresStyles";
import { useHistory } from "react-router-dom";
import ServiceLoaderDialog from "../../../../../library-components/Dialogs/ServiceLoaderDialog";

const TyreCartOptions: FC<TyreCartOptionsI> = (props) => {
  const { setShow, setShowTyreSelection, setServiceId } = props;

  const { state, dispatch } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [serviceId, setServiceIdLocal] = useState("");

  const [remove, setRemove] = useState(false);

  const [serviceDsc, setServiceDsc] = useState("");

  const [basket, setBasket] = useState(state.basketData?.service_details || []);

  const [loading, setLoading] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false)
  const [activeButtons, setActiveButtons] = useState<number[]>([]);

  const heavyCampaigns = ["fleggaardleasing0"]
  const classes = useStylesTyresCartOptions();
  const styles = useStylesTyresButtons();
  const history = useHistory();

  const [loadServiceCluster, resultLoad] =
    useLazyQuery<Partial<any>>(getServiceCluster);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
      setLoading(false);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  useEffect(() => {
    if (resultLoad?.data === null) {
      alert(resultLoad?.error?.message);
    } else {
      if (resultLoad?.data?.getServiceCluster?.node === null) {
        alert(resultLoad?.data?.getServiceCluster?.message);
      }
      if (resultLoad?.data?.getServiceCluster?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [resultLoad?.data]);

  useEffect(() => {
    loadServiceCluster({
      variables: {
        service_cluster: ServiceClusterType.Tyres,
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  }, []);

  useEffect(() => {
    if (state.queryParams) {
      resultLoad?.data?.getServiceCluster?.node?.map((cat: any, index: any) => {
        cat.service_subcategory?.map((cat: any, index: any) => {
          cat?.service_details.map((cat: any) => {
            if (state.queryParams?.service_ids?.includes(cat.service_id)) {
              if (cat.tyre_spec === "1") {
                showTyreSpecs();
                handleClick(cat.service_id);
              } else {
                const b = activeButtons;
                b.push(cat.service_id)
                setActiveButtons(b)
              }
            }
          })
        })
      })
    }
    setReadyToRender(resultLoad?.data?.getServiceCluster?.node?.length>0)
  }, [resultLoad?.data]);

  const showTyreSpecs = () => {
    setShow(true);
    setShowTyreSelection(true);
  };

  const handleDialog = (serviceId: string, serviceDsc: string) => {
    setServiceIdLocal(serviceId);
    setServiceDsc(serviceDsc);
    setOpen(true);
  };

  const handleClick = (serviceId: string) => {
    setServiceId(serviceId);
  };

  const handleRemoveClick = (serviceId: string, mileage: string) => {
    setLoading(true);
    setServiceIdLocal(serviceId);
    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: mileage,
        tyre_data: null,
      },
    });
    dispatch({
      type: "REMOVE_FROM_BASKET",
      payload: result.data?.computeService?.node,
    });
    setRemove(false);
    const removed = basket.filter((el: any) => el.sa_id !== serviceId);
    setBasket(removed);
  };

  useEffect(() => {
    if (basket.find((el: any) => el.sa_id === state.serviceId)) {
      setRemove(false);
      dispatch({
        type: "REMOVE_SERVICE_FROM_BASKET",
        payload: "",
      });
      const removed = basket.filter((el: any) => el.sa_id !== state.serviceId);
      setBasket(removed);
    }
  }, [state.serviceId, state.basketData]);

  return (
    <Grid container md={12} className={classes.rootMobile}>
      {readyToRender && resultLoad?.data?.getServiceCluster?.node?.map(
        (cat: any, index: any) => {
          return (
            <Grid item container md={12} key={index}>
              {cat.service_subcategory?.map((cat: any, index: any) => {
                return (
                  <Grid item container md={12} key={index}>
                    {cat?.service_details.map((cat: any) => {
                      return (
                        <Grid
                          key={cat.service_dsc}
                          className={classes.cardWrapper}
                        >
                          <Grid className={classes.cardHeader}>
                            <Typography
                              fontFamily={"Poppins"}
                              fontWeight={"Bold"}
                              fontSize={14}
                            >
                              {cat.service_dsc}
                            </Typography>
                            <IconButton
                              className={classes.infoButton}
                              onClick={() => {
                                handleDialog(cat.service_id, cat.service_dsc);
                              }}
                            >
                              <Info className={classes.infoButtonIcon} />
                            </IconButton>
                          </Grid>
                          <Grid className={classes.cardText}>
                            <Typography
                              fontFamily={"Poppins"}
                              fontWeight={"Regular"}
                              fontSize={12}
                              variant="subtitle1"
                            >
                              <Grid className={classes.contentWrapperMobile}>
                                <Typography
                                  fontFamily={"Poppins"}
                                  fontWeight={"Regular"}
                                  fontSize={12}
                                  variant="subtitle1"
                                >
                                  {cat.service_long_dsc}
                                </Typography>
                              </Grid>
                            </Typography>
                          </Grid>
                          <Grid className={classes.cardPrice}>
                            <Typography
                              fontFamily={"Poppins"}
                              fontSize={24}
                              className={classes.cardPriceTextWeight}
                            >
                              {!cat.hide_price && !heavyCampaigns.includes(state.basketData?.campaign_code) && formatPrice(
                                cat.total_price === "0"
                                  ? ""
                                  : cat.total_price + " DKK"
                              )}
                            </Typography>
                            {cat.tyre_spec === "1" ? (
                              !remove &&
                              !basket.find(
                                (el: any) => el.sa_id === cat.service_id
                              ) ? (
                                <SimpleButton
                                  classes={styles.addButton}
                                  onClick={() => {
                                    showTyreSpecs();
                                    handleClick(cat.service_id);
                                  }}
                                >
                                  {t("tyreOptionsAddButton")}
                                </SimpleButton>
                              ) : (
                                <SimpleButton
                                  onClick={() =>
                                    handleRemoveClick(
                                      cat.service_id,
                                      cat.mileage
                                    )
                                  }
                                  classes={styles.removeButton}
                                >
                                  {t("tyreOptionsRemoveButton")}
                                </SimpleButton>
                              )
                            ) : (
                              <AddRemoveButton
                                defaultActive={activeButtons.includes(cat.service_id.toString())}
                                serviceId={cat.service_id}
                                mileage={cat.mileage}
                                tyreData={null}
                                item={cat}
                              />
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        }
      )}
      <ServicesInfoDialog
        open={open}
        close={() => {
          setOpen(false);
        }}
        content={{ service_id: serviceId, service_dsc: serviceDsc }}
      />
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </Grid>
  );
};

export default TyreCartOptions;
