import {
  useTheme,
  Typography,
  InputAdornment,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDialogStylesTechnicalAssistanceForm } from "../../Styles/DialogStyle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useMutation } from "@apollo/react-hooks";
import { LoadingButton } from "@mui/lab";
import { submitTicket } from "../../../../../API/graphql-queries/Queries";
import { TicketI } from "../../../../../interfaces-and-unionTypes/Interfaces";
import InputField from "../../../InputFields/InputFields";
import { useTranslation } from "react-i18next";
import carImage from "../../../../../assets/illustrations/not-sure-whats-wrong-car.svg";
import { useHistory } from "react-router-dom";
import TechnicalAssistancePatienceDialog from "../TechnicalAssistancePatienceDialog";
import TechnicalAssistanceDialogSuccess from "../TechnicalAssistanceDialogSuccess";
import TrustBox from "../../../../TrustBox/TrustBox";
import { makeStyles } from "@mui/styles";
import Unchecked from "../../../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../../../assets/illustrations/checkboxChecked.svg";
import { isMobile } from "react-device-detect";

const TechnicalAssistanceMobile: FC = () => {
  const classes = useDialogStylesTechnicalAssistanceForm();
  const { t } = useTranslation();

  const history = useHistory();

  const { state, dispatch } = useContext(GlobalContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [comment, setComment] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorComment, setErrorComment] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [marketingPermission, setMarketingPermission] = useState(true);
  const [notSelectedAcceptTerms, setNotSelectedAcceptTerms] = useState(false);

  const [submitTicketAction, result] =
    useMutation<Partial<TicketI>>(submitTicket);
  //
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery("(max-width: 350px)");
  const matches2 = useMediaQuery("(min-width: 400px)");
  const matchIphone4 = useMediaQuery("(max-height: 480px)");
  const useStylesChecbox = makeStyles({
    root1: {
      textAlign: isMobile ? "left" : "center",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: matches ? "-38vh" : matches2 ? "-17.5vh" : "-22vh",
        marginTop: "2.7vh",
      },
    },
    root2: {
      textAlign: isMobile ? "left" : "center",
      "& .MuiCheckbox-root": {
        paddingTop: 0,
        // marginTop: matches ? "-8vh" : matches2 ? "-2vh" : "-4vh",
        marginTop: "2.7vh",
      },
    },
  });
  const classesChecbox = useStylesChecbox();

  const [valid, setValid] = useState(false);

  const [hasPositiveMessage, setHasPositiveMessage] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (state.returningCustomer?.node?.phone_number) {
      setNumber(
        state.returningCustomer?.node?.phone_number?.substring(1) || ""
      );
    }
  }, []);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.submitTicket?.node === null) {
        alert(result?.data?.submitTicket?.message);
      }
      if (result?.data?.submitTicket?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const submitForm = () => {
    let valid = true;

    if (!fullName.length) {
      valid = false;
      setErrorName("Required");
    }
    if (!email.length) {
      valid = false;
      setErrorEmail("Required");
    } else if (
      !email.match(
        //eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      valid = false;
      setErrorEmail("Invalid Email");
    }
    if (number.length < 8) {
      valid = false;
      setErrorPhone("Invalid Phone");
    }
    if (!comment.length) {
      valid = false;
      setErrorComment("Required");
    }
    if (valid) {
      setValid(valid);
      setHasPositiveMessage(true);
      submitTicketAction({
        variables: {
          flag: state.ticket?.node.ticket_id ? "S" : "V",
          ticket_id: state.ticket?.node.ticket_id || "",
          address: "",
          address2: "",
          commentVal: comment || "",
          email: email || "",
          fullName: fullName || "",
          phone: number || "",
          prefferedDate: "",
          prefferedTime: "",
          marketingPermission: marketingPermission ? "1" : "0",
          service_dsc: "",
          service_id: "",
          zip_code: state.userLocation?.node?.zip_code || "",
          licence_plate: state.licence?.node?.licence_plate || "",
          utmCampaign: state?.googleAdsParams?.utm_campaign,
          utmSource: state?.googleAdsParams?.utm_source,
          utmMedium: state?.googleAdsParams?.utm_medium,
          gclid: state?.googleAdsParams?.gclid,
          otherPayer: ''
        },
      })
        .then((res) => {
          setHasPositiveMessage(false);
          dispatch({
            type: "SUBMIT_TICKET_RESPONSE",
            payload: res.data?.submitTicket,
          });
          setSuccess(valid);
          setErrorName("");
          setErrorPhone("");
          setErrorEmail("");
          setErrorComment("");
          setValid(false);
          setSuccess(false);
          dispatch({
            type: "SUBMIT_TICKET_INFORMATIONS",
            payload: {
              fullName,
              email,
              number,
              comment,
            },
          });
          history.push("/summary-technical");
        })
        .catch((err) => {
          if (err.networkError) {
            dispatch({
              type: "ERROR_MESSAGE_DIALOG",
              payload: {
                message:
                  "It seems you don't have internet connection or your network is too weak.",
                showDialog: true,
              },
            });
            window.ononline = () => {
              dispatch({
                type: "ERROR_MESSAGE_DIALOG",
                payload: { message: "You're back online.", showDialog: true },
              });
              setTimeout(() => {
                dispatch({
                  type: "ERROR_MESSAGE_DIALOG",
                  payload: { message: "", showDialog: false },
                });
              }, 1500);
            };
            handleClose();
          }
        });
    }
  };

  const handleClose = () => {
    // close(false);
    setValid(false);
    setSuccess(false);
    setHasPositiveMessage(false);
  };

  return (
    <Grid
      item
      container
      md={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <TrustBox />
      </div>
      <Grid
        item
        container
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
          overflowX: "hidden",
        }}
        sx={{ boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)" }}
      >
        <Grid
          item
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
          }}
        >
          <Grid style={{ overflow: "scroll", overflowX: "hidden" }}>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <img src={carImage} style={{ width: "70px", height: "50px" }} />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  height: "10vh",
                }}
              >
                <Typography
                  component={"span"}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 600,
                    fontSize: "18px",
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  {t("dialogTitle-technical")}
                </Typography>
              </div>
              {!hasPositiveMessage && !success ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: "6%",
                  }}
                >
                  <Typography
                    component={"div"}
                    style={{
                      width: "100%",
                      fontWeight: "normal",
                      fontSize: "14px",
                      marginBottom: "1vh",
                      textAlign: "center",
                      paddingTop:
                        matches && !matchIphone4
                          ? "13%"
                          : matchIphone4
                          ? "15%"
                          : "",
                    }}
                  >
                    {t("dialogDescription-technical")}
                  </Typography>
                </div>
              ) : (
                hasPositiveMessage && (
                  <TechnicalAssistancePatienceDialog isTechnical={true} />
                )
              )}
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {!success && !hasPositiveMessage ? (
                <>
                  <div className={classes.inputDiv}>
                    <InputField
                      error={errorName.trim() !== "" && true}
                      style={{ width: "23rem" }}
                      required={true}
                      label={t("nameLabel")}
                      variant="outlined"
                      placeholder={t("namePlaceholder")}
                      onChange={(e: InputEvent) => {
                        const value = (e.target as HTMLInputElement).value;
                        setFullName(value);
                        setErrorName("");
                      }}
                      helperText={errorName}
                    />
                  </div>
                  <div className={classes.inputDiv}>
                    <InputField
                      error={errorEmail.trim() !== "" && true}
                      style={{ width: "23rem" }}
                      required={true}
                      label={t("emailLabel")}
                      variant="outlined"
                      placeholder={t("emailPlaceholder")}
                      onChange={(e: InputEvent) => {
                        const value = (e.target as HTMLInputElement).value;
                        setEmail(value);
                        setErrorEmail("");
                      }}
                      helperText={errorEmail}
                    />
                  </div>
                  <div className={classes.inputDiv}>
                    <InputField
                      style={{ width: "23rem" }}
                      error={errorPhone.trim() !== "" && true}
                      required={true}
                      label={t("numberLabel")}
                      variant="outlined"
                      onChange={(e: InputEvent) => {
                        const value = (e.target as HTMLInputElement).value;
                        const phoneRegex = new RegExp("^[0-9]{0,8}$");
                        if (phoneRegex.test(value)) {
                          setNumber(value);
                          setErrorPhone("");
                        }
                      }}
                      value={number}
                      placeholder={t("numberPlaceholder")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+45</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "^[0-9]{8}$",
                        maxLength: 8,
                      }}
                      helperText={errorPhone}
                    />
                  </div>
                  <div className={classes.inputDiv}>
                    <InputField
                      error={errorComment.trim() !== "" && true}
                      style={{ width: "23rem" }}
                      required={false}
                      label={t("howWeCanHelpLabel")}
                      variant="outlined"
                      placeholder={t("howWeCanHelpPlaceholder")}
                      multiline={true}
                      rows={5}
                      onChange={(e: InputEvent) => {
                        const value = (e.target as HTMLInputElement).value;
                        setComment(value);
                        setErrorComment("");
                      }}
                      helperText={errorComment}
                    />
                  </div>
                  <Grid
                    item
                    container
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      textAlign: isMobile ? "start" : "center",

                      paddingLeft: "2%",
                      paddingRight: "2%",
                      marginBottom: "10px",
                    }}
                  >
                    <Grid>
                      {/* <Typography fontSize={10}>{t("sendRequestWarning")}</Typography> */}
                      <FormControlLabel
                        sx={{ display: "table" }}
                        control={
                          <div style={{ display: "table-cell" }}>
                            <Checkbox
                              style={{
                                color: "#8D88FF",
                              }}
                              icon={<img src={Unchecked} />}
                              checkedIcon={<img src={Checked} />}
                              onChange={(e: any) => {
                                setMarketingPermission(!marketingPermission);
                              }}
                              checked={marketingPermission}
                            />
                          </div>
                        }
                        label={
                          <Typography fontWeight={400} fontSize={12}>
                            {t("acceptConditions1")}
                            <span>
                              <a
                                href="https://boxnow.dk/personoplysninger/"
                                target={"_blank"}
                                rel="noopener noreferrer"
                                style={{ color: "#00F" }}
                              >
                                persondatapolitik
                              </a>
                              <span> og </span>
                              <a
                                href="https://boxnow.dk/generelle-betingelser/"
                                target={"_blank"}
                                rel="noopener noreferrer"
                                style={{ color: "#00F" }}
                              >
                                betingelserne
                              </a>
                            </span>
                            {t("acceptConditions1Continued")}
                            <span>
                              <a
                                href="mailto:hej@boxnow.dk"
                                style={{ color: "#00F" }}
                              >
                                hej@boxnow.dk
                              </a>
                            </span>
                          </Typography>
                        }
                        className={classesChecbox.root1}
                      />
                      <FormControlLabel
                        sx={{ display: "table" }}
                        control={
                          <div style={{ display: "table-cell" }}>
                            <Checkbox
                              style={{
                                color: "#8D88FF",
                              }}
                              icon={<img src={Unchecked} />}
                              checkedIcon={<img src={Checked} />}
                              onChange={(e: any) => {
                                setAcceptTerms(!acceptTerms);
                                setNotSelectedAcceptTerms(false);
                              }}
                              checked={acceptTerms}
                            />
                          </div>
                        }
                        label={
                          <Typography fontWeight={400} fontSize={12}>
                            {t("acceptConditions2")}{" "}
                            <a
                              href="https://boxnow.dk/generelle-betingelser/"
                              target={"_blank"}
                              rel="noopener noreferrer"
                              style={{ color: "#00F" }}
                            >
                              vilkår
                            </a>{" "}
                            {t("acceptConditions3")}
                          </Typography>
                        }
                        className={classesChecbox.root2}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : hasPositiveMessage ? null : (
                success && <TechnicalAssistanceDialogSuccess />
              )}
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
                fontFamily: "Poppins",
              }}
            >
              <LoadingButton
                style={{
                  width: 155,
                  height: 47,
                  background: "#8D88FF",
                  color: "#F6F6FF",
                  font: "22px",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  if (!acceptTerms) {
                    setNotSelectedAcceptTerms(true);
                  } else {
                    submitForm();
                  }
                }}
                role={"progressbar"}
                loading={valid}
                loadingIndicator={
                  <CircularProgress style={{ color: "white" }} size={32} />
                }
              >
                {!valid && t("continueButtonLabel")}
              </LoadingButton>
              {notSelectedAcceptTerms && (
                <FormHelperText
                  style={{
                    paddingTop: "1%",
                    color: "#d32f2f",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography fontSize={12} textAlign="center">
                    {t("errorMessageNotAccepted")}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TechnicalAssistanceMobile;
