import { Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import success from "../../../../assets/illustrations/success-lottie.json";

const TechnicalAssistanceDialogSuccess: FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: "49vh",
        width: "45vw",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {/* <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", alignItems: "center", width: "45vw", height: "30vh" }}>
                 <Lottie options={{
                     animationData: success,
                 }}
                 height={250}/>
            </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            animationData: success,
          }}
          height={250}
          isClickToPauseDisabled={true}
        />
      </div>
      <div>
        <Typography
          component={"div"}
          style={{ fontWeight: "bold", textAlign: "center", fontSize: "18px" }}
        >
          {t("successMessage")}
        </Typography>
      </div>
    </div>
  );
};

export default TechnicalAssistanceDialogSuccess;
