import React, {FC, useContext, useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Repairs from "../../assets/illustrations/Repairs.svg";
import ServicingAndMOT from "../../assets/illustrations/Servicing&MOT.svg";
import GlasOgRuder from "../../assets/illustrations/Glass.svg";
import Tyres from "../../assets/illustrations/Tyres.svg";
import PrePurchaseInspections from "../../assets/illustrations/PrePurchaseInspections.svg";
import {useHistory} from "react-router-dom";
import {a11yProps} from "./TabPanels/PanelFunctions";
import {Grid, Typography} from "@mui/material";
import TyreWrapper from "./TabPanels/TyresTabPanel/wrappers/TyreWrapper";
import WindshieldWrapper from "./TabPanels/WindshieldGlassTabPanel/wrappers/WindshieldWrapper";
import RepairBlue from "../../assets/illustrations/RepairBlue.svg";
import TyreBlue from "../../assets/illustrations/TyreBlue.svg";
import GlassBlue from "../../assets/illustrations/GlassBlue.svg";
import PrePurchaseInspectionBlue from "../../assets/illustrations/PrePurchaseInspectionBlue.svg";
import ServicingAndMOTBlue from "../../assets/illustrations/ServicingAndMOTBlue.svg";
import {prePurchaseInspectionsItems} from "./TabPanels/TyresTabPanel/SubComponents/placeholderMockups/mockup";
import PrePurchaseInspectionWrapper from "./TabPanels/PrePurchaseInspections/wrappers/PrePurchaseInspectionWrapper";
import RepairsWrapper from "./TabPanels/RepairsTabPanel/wrappers/RepairsWrapper";
import ServicingAndMotTabPanelWrapper
    from "./TabPanels/ServicingAndMotTabPanel/wrappers/ServicingAndMotTabPanelWrapper";
import {useTranslation} from "react-i18next";
import {useStylesDesktopServices} from "./Style/ServicesStyle";
import {SyntheticEvent} from "react";
import NotSureWhatsWrong from "./NotSureWhatsWrong/NotSureWhatsWrong";
import {GlobalContext} from "../../context/GlobalContext";
import {useApolloClient} from "react-apollo";
import {computeService, getServiceCluster} from "../../API/graphql-queries/Queries";
import {ServiceClusterType} from "./enumerations/enumerations";
import ServiceLoaderDialog from "../library-components/Dialogs/ServiceLoaderDialog";

const ServicesClusterTabsWrapper: FC = () => {
    const [value, setValue] = useState(0);
    const history = useHistory();
    const [currLoc, setCurrLoc] = useState(window.location.pathname || "");
    const classes = useStylesDesktopServices();
    const {state, dispatch} = useContext(GlobalContext);
    const apolloClient = useApolloClient()
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const unlisten = history.listen((location) => {
            setCurrLoc(location.pathname);
        });

        // Cleanup by unsubscribing the listener when the component unmounts
        return () => unlisten();
    }, [history]);

    const {t} = useTranslation();


    const handleChange = (event: SyntheticEvent, newValue: number) => {
        switch (newValue) {
            case 0:
                history.push("/services/repairs");
                break;
            case 1:
                history.push("/services/tyres");
                break;
            case 2:
                history.push("/services/servicing");
                break;
            case 3:
                history.push("/services/inspections");
                break;
            case 4:
                history.push("/services/glass");
                break;
        }
    };

    useEffect(() => {
        const currentLocation = window.location.pathname;
        if (currentLocation.includes("repairs")) {
            setValue(0);
        } else if (currentLocation.includes("tyres")) {
            setValue(1);
        } else if (currentLocation.includes("servicing")) {
            setValue(2);
        } else if (currentLocation.includes("inspections")) {
            setValue(3);
        } else if (currentLocation.includes("glass")) {
            setValue(4);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currLoc]);


    useEffect(() => {
        (async () => {
            if (state.queryParams?.service_ids) {
                setLoading(true)

                const servicesToLoad: Array<string> = []

                const fetchServices = async (service_cluster: string) => {
                    return await apolloClient.query({
                        query: getServiceCluster,
                        variables: {
                            service_cluster,
                            ticket_id: state.ticket?.node?.ticket_id || "",
                        }
                    })
                }
                if(state.queryParams?.service_ids) {
                    for (const service_id of state.queryParams?.service_ids) {
                        const first = service_id.charAt(0)
                        let basket: any = null;
                        switch (first) {
                            case '1':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Repairs)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            if (sub?.service_details?.length > 1) {
                                                for (const subEl of sub?.service_details) {
                                                    if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                        basket = await handleAddClick(subEl.service_id)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            } else {
                                                if (state.queryParams?.service_ids?.includes(sub?.service_details[0].service_id)) {
                                                    basket = await handleAddClick(sub?.service_details[0].service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first)
                                break;
                            case '2':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Tyres)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    if (subEl.tyre_spec !== "1") {
                                                        basket = await handleAddClick(subEl.service_id, subEl.mileage)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '5':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Servicing)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    if (subEl.mileage.trim() !== "1") {
                                                        basket = await handleAddClick(subEl.service_id)
                                                        await dispatch({
                                                            type: "ADD_TO_BASKET",
                                                            payload: basket.data?.computeService?.node
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '3':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Prepurchase)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    basket = await handleAddClick(subEl.service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                            case '4':
                                if (!servicesToLoad.includes(first)) {
                                    const res = await fetchServices(ServiceClusterType.Glass)
                                    for (const el of res.data?.getServiceCluster?.node) {
                                        for (const sub of el?.service_subcategory) {
                                            for (const subEl of sub?.service_details) {
                                                if (state.queryParams?.service_ids?.includes(subEl.service_id)) {
                                                    basket = await handleAddClick(subEl.service_id)
                                                    await dispatch({
                                                        type: "ADD_TO_BASKET",
                                                        payload: basket.data?.computeService?.node
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                                servicesToLoad.push(first);
                                break;
                        }
                    }
                    //clear query params
                    dispatch({
                        type: "QUERY_PARAMS",
                        payload: {coupon_code: state.queryParams?.coupon_code??''},
                    });
                }
                setLoading(false)
            }
        })();
    }, []);

    const handleAddClick = async (serviceId: string, milage = "") => {
        return await apolloClient.query({
            query: computeService,
            variables: {
                is_selected: 1,
                service_id: serviceId,
                ticket_id: state.ticket?.node.ticket_id || "",
                milage: milage,
                tyre_data: null,
            }
        });
    };


    return (
        <Grid className={classes.root}>
            <div className={classes.clusterWrapper}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="icon label tabs example"
                    className={classes.tabs}
                    centered={true}
                    variant="fullWidth"
                >
                    <Tab
                        icon={<img src={value === 0 ? RepairBlue : Repairs}/>}
                        style={{
                            fontWeight: value === 0 ? "bold" : "",
                            textTransform: "capitalize",
                            width: "10vw",
                        }}
                        label={
                            <Typography
                                style={{
                                    fontWeight: value === 0 ? "bold" : "",
                                }}
                            >
                                {t("repairs")}
                            </Typography>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        icon={<img src={value === 1 ? TyreBlue : Tyres}/>}
                        style={{
                            fontWeight: value === 1 ? "bold" : "",
                            textTransform: "capitalize",
                            width: "10vw",
                        }}
                        label={t("tyres")}
                        {...a11yProps(1)}
                    />
                    <Tab
                        icon={
                            <img src={value === 2 ? ServicingAndMOTBlue : ServicingAndMOT}/>
                        }
                        style={{
                            fontWeight: value === 2 ? "bold" : "",
                            textTransform: "capitalize",
                            width: "10vw",
                        }}
                        label={t("servicingAndMOT")}
                        {...a11yProps(2)}
                    />
                    <Tab
                        icon={
                            <img
                                src={
                                    value === 3
                                        ? PrePurchaseInspectionBlue
                                        : PrePurchaseInspections
                                }
                            />
                        }
                        style={{
                            fontWeight: value === 3 ? "bold" : "",
                            textTransform: "capitalize",
                            width: "10vw",
                        }}
                        label={
                            <Typography
                                style={{
                                    fontWeight: value === 3 ? "bold" : "",
                                }}
                            >
                                {t("prePurchaseInspections")}
                            </Typography>
                        }
                        {...a11yProps(3)}
                    />
                    <Tab
                        icon={<img src={value === 4 ? GlassBlue : GlasOgRuder}/>}
                        style={{
                            fontWeight: value === 4 ? "bold" : "",
                            textTransform: "capitalize",
                            width: "10vw",
                        }}
                        label={t("glasOgRuder")}
                        {...a11yProps(4)}
                    />
                </Tabs>
            </div>
            <div className={classes.borderWrapper}>
                <div className={classes.border}></div>
            </div>
            <RepairsWrapper value={value}/>
            <TyreWrapper value={value}/>
            <WindshieldWrapper value={value}/>
            <PrePurchaseInspectionWrapper
                value={value}
                items={prePurchaseInspectionsItems}
            />
            <ServicingAndMotTabPanelWrapper value={value}/>
            <NotSureWhatsWrong/>
            <ServiceLoaderDialog
                open={loading}
                close={() => {
                    return;
                }}
            />
        </Grid>
    );
};

export default ServicesClusterTabsWrapper;
