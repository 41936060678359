import { Button, Grid, Typography } from "@mui/material";
import React, {FC, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Repairs from "../../../assets/illustrations/Repairs.svg";
import ServicingAndMOT from "../../../assets/illustrations/Servicing&MOT.svg";
import GlasOgRuder from "../../../assets/illustrations/Glass.svg";
import Tyres from "../../../assets/illustrations/Tyres.svg";
import PrePurchaseInspections from "../../../assets/illustrations/PrePurchaseInspections.svg";
import { useStylesDesktopContent } from "../Style/ContentStyle";
import RepairBlue from "../../../assets/illustrations/RepairBlue.svg";
import TyreBlue from "../../../assets/illustrations/TyreBlue.svg";
import GlassBlue from "../../../assets/illustrations/GlassBlue.svg";
import PrePurchaseInspectionBlue from "../../../assets/illustrations/PrePurchaseInspectionBlue.svg";
import ServicingAndMOTBlue from "../../../assets/illustrations/ServicingAndMOTBlue.svg";
import { GlobalContext } from "../../../context/GlobalContext";

const Forms: FC<{ isExternal: boolean; setExternal: (a: boolean) => void }> = ({
  isExternal, setExternal
}) => {
  const { t } = useTranslation();
  const classes = useStylesDesktopContent();

  const [hover, setHover] = useState(0);
  const [activeExternal, setActiveExternal] = useState("");
  const { state } = useContext(GlobalContext);
  const query = useLocation();


  const hoverStyle = {
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    background: "white",
  };
  const hoverTextStyle = {
    color: "#8D88FF",
  };
  const servicesButtonsProps = [
    {
      id: 1,
      title: t("repairs") + " ",
      desc: t("repairsHelperText"),
      to: "repairs",
      image: hover == 1 ? RepairBlue : Repairs,
      hoverStyle: hover === 1 ? hoverStyle : {},
      hoverTextStyle: hover === 1 ? hoverTextStyle : {},
    },
    {
      id: 2,
      title: t("tyres") + " ",
      desc: t("tyresHelperText"),
      to: "tyres",
      image: hover == 2 ? TyreBlue : Tyres,
      hoverStyle: hover === 2 ? hoverStyle : {},
      hoverTextStyle: hover === 2 ? hoverTextStyle : {},
    },
    {
      id: 3,
      title: t("servicingAndMOT") + " ",
      desc: t("servicingAndMOTHelperText"),
      to: "servicing",
      image: hover == 3 ? ServicingAndMOTBlue : ServicingAndMOT,
      hoverStyle: hover === 3 ? hoverStyle : {},
      hoverTextStyle: hover === 3 ? hoverTextStyle : {},
    },
    {
      id: 4,
      title: t("prePurchaseInspections") + " ",
      desc: t("prePurchaseInspectionsHelperText"),
      to: "inspections",
      image: hover == 4 ? PrePurchaseInspectionBlue : PrePurchaseInspections,
      hoverStyle: hover === 4 ? hoverStyle : {},
      hoverTextStyle: hover === 4 ? hoverTextStyle : {},
    },
    {
      id: 5,
      title: t("glasOgRuder") + " ",
      desc: t("glasOgRuderHelperText"),
      to: "glass",
      image: hover == 5 ? GlassBlue : GlasOgRuder,
      hoverStyle: hover === 5 ? hoverStyle : {},
      hoverTextStyle: hover === 5 ? hoverTextStyle : {},
    },
    // {
    //   id: 6,
    //   title: t("DriveClever") + " ",
    //   desc: t("DriveClever"),
    //   to: "DriveClever",
    //   image: hover == 5 ? GlassBlue : GlasOgRuder,
    //   hoverStyle: hover === 5 ? hoverStyle : {},
    //   hoverTextStyle: hover === 5 ? hoverTextStyle : {},
    // },
  ];

  const toggleHover = (id: number, action: string) => {
    if (action.trim() === "enter") {
      setHover(id);
    } else {
      setHover(0);
    }
  };

  const history = useHistory();
  const navigateToCluster = (val: string) => {
    if (val === 'DriveClever') { // add conditions with val name with OR operator
      setExternal(true);
      setActiveExternal(val)
    }
    else {
      if (query.search) {
        history.push("/services/" + val + "/" + query.search);
      } else {
        history.push("/services/" + val);
      }
    }
  };
  useEffect(() => {
    if(typeof(state.queryParams?.service) === 'string') {
      if(state.queryParams?.service_ids?.includes('5000') || state.queryParams?.service_ids?.includes('5001')) {
        navigateToCluster('servicing')
      } else {
        navigateToCluster(state.queryParams.service)
      }
    }
  }, []);

  return (
    <Grid
      item
      container
      md={12}
      className={`${classes.wrapperFlex} ${classes.root}`}
    >
      {!isExternal && (
        <Grid
          item
          container
          md={12}
          className={`${classes.wrapperFlex} ${classes.wrapper}`}
        >
          {servicesButtonsProps.map((item) => {
            return (
              <Button
                key={item.id}
                className={`${classes.wrapperFlex} ${classes.button}`}
                style={item.hoverStyle}
                onClick={() => {
                  navigateToCluster(item.to);
                }}
                onMouseEnter={() => toggleHover(item.id, "enter")}
                onMouseLeave={() => toggleHover(item.id, "")}
              >
                <div className={`${classes.wrapperFlex} ${classes.imageWrapper}`}>
                  <img src={item.image} className={classes.image} />
                </div>
                <div className={`${classes.wrapperFlex} ${classes.cardText}`}>
                  <Typography
                    component="div"
                    fontFamily={"Poppins"}
                    fontWeight={"bold"}
                    color="#1A1D1E"
                    style={item.hoverTextStyle}
                  >
                    {item.title}
                    <Typography
                      variant="subtitle2"
                      component="p"
                      fontWeight={"small"}
                      color="#1A1D1E"
                      fontFamily={"Poppins"}
                      style={item.hoverTextStyle}
                    >
                      {item.desc}
                    </Typography>
                  </Typography>
                </div>
              </Button>
            );
          })}
        </Grid>
      )}
      {/* : (
        <Grid
          item
          container
          md={12}
          className={`${classes.wrapperFlex} ${classes.wrapper}`}
        >
          <Typography
            variant="h5"
            component="div"
            fontFamily={"Poppins"}
            fontWeight={"medium"}
            color="#1A1D1E"
            textAlign={"center"}
          >

            <Typography
              variant="h5"
              component="span"
              fontWeight={600}
              color="#1A1D1E"
              fontFamily={"Poppins"}
              textAlign={"center"}
             
            >
              {activeExternal}
            </Typography>


          </Typography>
          <iframe src="https://serviceaftale.driveclever.dk/iframe/?customerId=12"
            style={{ width: '100%', height: '1200px', overflow: 'hidden', marginTop: '20px' }}></iframe>
        </Grid>
      )} */}
    </Grid>
  );
};

export default Forms;
