import {ServiceDetailsType} from "../interfaces-and-unionTypes/UnionTypes";

export const getFormattedTotalPrice = ((service_details: ServiceDetailsType[]): string => {
    let totalPrice = 0;
    service_details.forEach((service: any) => {
        totalPrice += Number(service.sa_price);
    });
    return totalPrice.toLocaleString('da-dk')
})

export const itemsInBasketWithMissingParts: (state: any) => any[] = ((state: any) => {
    const doesBasketHaveContent = state.basketData && state.basketData.service_details
    const itemsWithMissingParts: any[] = (doesBasketHaveContent && state.basketData.service_details.filter((item: any) => item.sa_missing === 1)) || [];

    return itemsWithMissingParts;
})

export const canTotalPriceBeCalculated = ((state: any) => {
    const canOneOrMoreItemsPriceCanNotBeCalculated = (state.basketData && itemsInBasketWithMissingParts(state).length > 0);

    const shouldItemsPriceBeDisplayed = !(canOneOrMoreItemsPriceCanNotBeCalculated || isAFullDiscountApplied(state));
    return shouldItemsPriceBeDisplayed;
})

export const isAFullDiscountApplied = ((state: any) => {
    const heavyCampaigns = ["fleggaardleasing0"]
    const isAFullDiscountApplied = heavyCampaigns.includes(state.basketData?.campaign_code);

    return isAFullDiscountApplied;
})
