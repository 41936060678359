import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import {
  LinkButton,
  SimpleButton,
} from "../../../../library-components/Buttons/Buttons";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import InfoDialog from "../../../../library-components/Dialogs/InfoDialog/InfoDialog";
import PrepurchaseChecklist from "./ChecklistComponent/PrepurchaseChecklist";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useStylesDesktopPrePurchaseCompareTable } from "../Styles/PrePurchaseStyles";

const PrePurchaseInspectionsCompareTable: FC = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const classes = useStylesDesktopPrePurchaseCompareTable();

  const rows = [
    {
      tag: "L",
      title: t("link1"),
      link_basic: t("link1_basic"),
      link_premium: t("link1_premium"),
    },
    {
      tag: "F",
      title: t("field2"),
      basic: true,
      premium: true,
    },
    {
      tag: "F",
      title: t("field3"),
      basic: true,
      premium: true,
    },
    {
      tag: "H",
      title: t("header2"),
    },
    {
      tag: "F",
      title: t("field4"),
      basic: false,
      premium: true,
    },
    {
      tag: "F",
      title: t("field5"),
      basic: true,
      premium: true,
    },
    {
      tag: "F",
      title: t("field6"),
      basic: false,
      premium: true,
    },
    {
      tag: "F",
      title: t("field7"),
      basic: false,
      premium: true,
    },
    {
      tag: "F",
      title: t("field8"),
      basic: false,
      premium: true,
    },
    {
      tag: "F",
      title: t("field9"),
      basic: true,
      premium: true,
    },
    {
      tag: "F",
      title: t("field10"),
      basic: true,
      premium: true,
    },
    {
      tag: "F",
      title: t("field11"),
      basic: true,
      premium: true,
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Grid className={classes.root}>
      <TableContainer component={Grid}>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHeadCells}>
                {t("header1")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCells}>
                {t("header1_basic")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCells}>
                {t("header1_premium")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <TableRow
                key={item.title}
                className={classes.tableRowLastChildBorder}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.tag.includes("H") ? "bold" : "normal",
                    display: item.tag.includes("H") ? "flex" : "table-cell",
                    justifyContent: item.tag.includes("H")
                      ? "flex-start"
                      : "inherit",
                    alignItems: item.tag.includes("H") ? "flex-end" : "inherit",
                    fontSize: item.tag.includes("H")
                      ? !isMobile
                        ? "15px"
                        : "12px"
                      : !isMobile
                      ? "14px"
                      : "10px",
                    height: item.tag == "H" ? "60px" : "",
                  }}
                >
                  {item.title}
                  {item.tag === "L" && (
                    <>
                      <br />
                      <LinkButton
                        onClick={() => setOpen(true)}
                        classes={classes.checklistLinkSize}
                      >
                        {t("link1_link")}
                      </LinkButton>
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.tag.includes("H") ? "bold" : "normal",
                  }}
                >
                  {item.basic ? (
                    <DoneIcon className={classes.doneIcon} />
                  ) : item.basic == false ? (
                    <CloseIcon className={classes.closeIcon} />
                  ) : item.link_basic ? (
                    item.link_basic
                  ) : null}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.tag.includes("H") ? "bold" : "normal",
                  }}
                >
                  {item.premium ? (
                    <DoneIcon className={classes.doneIcon} />
                  ) : item.premium == false ? (
                    <CloseIcon className={classes.closeIcon} />
                  ) : item.link_premium ? (
                    item.link_premium
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InfoDialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        title="Autotjek checkliste"
        content={<PrepurchaseChecklist />}
        actions={
          <SimpleButton
            onClick={() => setOpen(false)}
            classes={classes.checklistCloseButton}
          >
            <Typography className={classes.checklistCloseButtonText}>
              {t("closeButtonDialog")}
            </Typography>
          </SimpleButton>
        }
        actionsStyle={classes.checklistCloseButtonWrapper}
      />
    </Grid>
  );
};

export default PrePurchaseInspectionsCompareTable;
