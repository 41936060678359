import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesMobileSummaryCheckout } from "../../Styles/SummaryCheckoutStyles";

const DetailsContentMobile: FC = () => {
  const { state } = useContext(GlobalContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [strAddress1, setStrAddress1] = useState("");
  const [strAddress2, setStrAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const classes = useStylesMobileSummaryCheckout();

  useEffect(() => {
    setFullName(state.contactDetailsInfo?.fullName || "");
    setEmail(state.contactDetailsInfo?.email || "");
    setStrAddress1(state.contactDetailsInfo?.strAddress1 || "");
    setStrAddress2(state.contactDetailsInfo?.strAddress2 || "");
    setCity(state.contactDetailsInfo?.city || "");
    setZipCode(state.contactDetailsInfo?.zipCode || "");
    setPhoneNumber(state.contactDetailsInfo?.phoneNumber || "");
  }, []);

  return (
    <Grid className={classes.cardRoot}>
      <Grid>
        <Typography className={classes.cardTitleText}>
          {"Kontaktoplysninger"}
        </Typography>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>Fulde navn</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>Email</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>
            Serviceadresse
          </Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {strAddress1}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>Postnummer</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {zipCode}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>By</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {city}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography className={classes.cardItemName}>
            Telefonnummer
          </Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {"+45 " + phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContentMobile;
