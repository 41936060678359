import { makeStyles } from "@mui/styles";

export const useStylesDesktopSummaryCheckout = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerImage: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "2% 0px 2% 0px",
  },
  headerTitleWrapper: {
    width: "100%",
    padding: "2% 0px 2% 0px",
  },
  headerTitleText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px !important",
    color: "#353535 !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  missingItemText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px !important",
    color: "{#f72c2ca3} !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  headerDescription: {
    width: "95%",
    paddingLeft: "2%",
  },
  headerDescriptionText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  contentRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0% !important",
  },
  borderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "3% 0px 3% 0px",
  },
  border: {
    width: "91%",
    border: "1px solid #0000001A",
  },
  cardRoot: {
    paddingLeft: "2.5vw",
    width: "95%",
  },
  cardWrapper: {
    paddingBottom: "1.7%",
  },
  cardTitleText: {
    fontSize: "18px !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  cardItemRoot: {
    width: "100%",
    marginBottom: "1%",
  },
  cardItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardItemValueWrapper: {
    width: "42%",
  },
  cardItemValueText: {
    fontWeight: "bold !important",
  },
  actionsRoot: {
    padding: "2% 0px 5% 2.7vw",
  },
  link: {
    color: "#5F58FE !important",
    textDecorationColor: "#5F58FE !important",
  },
  servicesContentCardItemWidth: {
    width: "53%",
  },
  additionalFeesText: {
    color: "#1A1D1E !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
  },
  discountLine: {
    fontFamily: "Poppins-SemiBold !important",
  },
});

export const useStylesMobileSummaryCheckout = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerRoot: {
    width: "90% !important",
    marginTop: "8% !important",
    padding: "5% !important",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  headerWrapper: {
    padding: "6%",
  },
  headerImage: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "2% 0px 2% 0px",
  },
  headerImageWidth: {
    width: "20%",
  },
  headerTitleWrapper: {
    width: "100%",
    padding: "2% 0px 2% 0px",
  },
  headerTitleText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px !important",
    color: "#353535 !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  missingItemText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px !important",
    color: "{#d32f2f} !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  headerDescription: {
    width: "100%",
  },
  headerDescriptionText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "10px !important",
  },
  contentRoot: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    marginTop: "15px !important",
    width: "90% !important",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  contentWrapper: {
    padding: "3%",
  },
  borderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "3% 0px 3% 0px",
  },
  border: {
    width: "91%",
    border: "1px solid #0000001A",
  },
  cardRoot: {
    paddingLeft: "4.2vw",
    width: "95%",
  },
  cardTitleText: {
    fontSize: "12px !important",
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
  },
  cardItemRoot: {
    width: "100%",
    marginBottom: "1%",
  },
  cardItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardItemName: {
    fontSize: "10px !important",
  },
  cardItemValueWrapper: {
    width: "42%",
  },
  cardItemValueText: {
    fontSize: "10px !important",
    fontWeight: "bold !important",
  },
  actionsRoot: {
    padding: "2% 0px 5% 6.3vw",
    width: "100%",
  },
  link: {
    color: "#5F58FE !important",
    textDecorationColor: "#5F58FE !important",
  },
  servicesContentCardItemWidth: {
    width: "53%",
  },
  additionalFeesText: {
    color: "#1A1D1E !important",
    fontWeight: "normal !important",
    fontSize: "10px !important",
  },
  discountLine: {
    fontFamily: "Poppins-SemiBold !important",
  },
});
