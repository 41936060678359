import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { isMobile } from "react-device-detect";
import InternetConnectionErrorImage from "../../../assets/illustrations/InternetConnectionError.svg";

const InternetConnectionError: FC = () => {
  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "7%",
        width: "100%",
      }}
    >
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          width: !isMobile ? "60%" : "95%",
        }}
      >
        <Grid
          item
          container
          md={12}
          sx={{
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: !isMobile ? "60%" : "95%",
          }}
        >
          <Grid
            item
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5%",
            }}
          >
            <img src={InternetConnectionErrorImage} />
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "2%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily={"Poppins, ExtraBold"}
                fontSize={18}
                fontWeight={800}
              >
                {"Ooops!"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3% 0 3% 0",
              width: "100%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                fontFamily={"Poppins, Regular"}
                fontSize={14}
                fontWeight={500}
                textAlign={"center"}
              >
                Langsom eller ingen internetforbindelse
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0% 0 8% 0",
              width: "100%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                fontFamily={"Poppins, Regular"}
                fontSize={14}
                fontWeight={500}
                textAlign={"center"}
              >
                Tjek venligst dine internetindstillinger!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InternetConnectionError;
