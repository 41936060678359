import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../context/GlobalContext";
import { MuiAlert } from "../Schedule/SubComponents/MuiAlert";

const ContactDetailsHeader: FC = () => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const isMounted = useRef(false);
  const scrollToTop = (top: number) => {
    window.scrollTo({ top: top, behavior: "smooth" });
  };

  useEffect(() => {
    if (isMounted.current) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setTimeout(() => {
        scrollToTop(0);
      }, 500);
    } else {
      isMounted.current = true;
    }
  }, [state?.basketData?.total_price]);
  return (
    <Grid>
      {showAlert ? (
        <Grid>
          <MuiAlert />
        </Grid>
      ) : null}
      <Typography fontWeight={800}>
        {t("detailsTitle")}
        <span style={{ fontWeight: "400" }}>{t("forTheCurrentTask")}</span>
      </Typography>
    </Grid>
  );
};

export default ContactDetailsHeader;
