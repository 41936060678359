/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery } from "@apollo/react-hooks";
import React, { FC, useContext, useEffect, useState } from "react";
import { InputEvent, ZipCodeForm } from "../../Types/Types";
import { GlobalContext } from "../../../context/GlobalContext";
import { LocationByZIPCODE } from "../../../interfaces-and-unionTypes/Interfaces";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";
import InputField from "../../library-components/InputFields/InputFields";
import { getLocationById } from "../../../API/graphql-queries/Queries";
import { useTranslation } from "react-i18next";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { Box, FormControl, FormHelperText } from "@mui/material";
import { TechnicalAssistanceDialog } from "../../library-components/Dialogs/TechincalAssistance/TechnicalAssistanceDialog";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { SeparateZipCode } from "../../../Helpers/stringHelpers";

const ContentZipCode: FC<ZipCodeForm> = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { setLocalZipCode, error, zipCodeRef } = props;
  // const [error, setError] = useState(props.error);

  const [zip_code_local, setZipCodeLocal] = useState("");

  const [formError, setFormError] = useState(error);

  const classes = useStylesDesktop();

  const history = useHistory();

  useEffect(() => {
    setZipCodeLocal(state.returningCustomer?.node?.zip_code || "");
    setLocalZipCode(state.returningCustomer?.node?.zip_code || "");
  }, [state.returningCustomer?.node, state.clearVehicleForm]);

  useEffect(() => {
    setFormError(error);
    setZipCodeLocal(state.userLocation?.node?.zip_code || "");
    setLocalZipCode(state.userLocation?.node?.zip_code || "");
    if (
      state.returningCustomer?.node !== undefined &&
      state.returningCustomer?.node?.zip_code.trim() !== ""
    ) {
      setZipCodeLocal(state.returningCustomer?.node?.zip_code || "");
      setLocalZipCode(state.returningCustomer?.node?.zip_code || "");
    }
    if (
      state?.basketData?.location_text !== "" &&
      state?.basketData?.location_text !== undefined
    ) {
      setZipCodeLocal(SeparateZipCode(state?.basketData?.location_text) || "");
      setLocalZipCode(SeparateZipCode(state?.basketData?.location_text) || "");
    }
  }, []);

  useEffect(() => {
    if (state.queryParams?.zip) {
      setZipCodeLocal(state.queryParams?.zip || "");
      setLocalZipCode(state.queryParams?.zip || "");
    }
  }, [state.queryParams?.zip]);

  useEffect(() => {
    setFormError(error);
  }, [error]);

  const [loadLocation, result] = useLazyQuery<Partial<LocationByZIPCODE>>(
    getLocationById,
    {
      onCompleted: (data) => {
        dispatch({ type: "ZIP_CODE_CITY", payload: data?.getLocationById });
      },
    }
  );

  const validateZipCode = () => {
    if (result?.data?.getLocationById?.node === null) {
      setFormError(t("zipCodeErrorInvalid"));
    } else {
      setFormError("");
      dispatch({
        type: "ZIP_CODE_CITY",
        payload: result?.data?.getLocationById,
      });
    }
  };

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    }
    if (result?.data?.getLocationById?.message === "Session expired") {
      alert("Session expired");
      history.push("/");
    }
  }, [result?.data]);

  useEffect(() => {
    validateZipCode();
  }, [result?.data?.getLocationById]);

  useEffect(() => {
    setFormError("");
  }, [state.returningCustomer.node]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ width: !isMobile ? "28vw" : "80vw" }}
    >
      <FormControl fullWidth className={classes.formControl}>
        <InputField
          inputRef={zipCodeRef}
          id="standard-basic"
          error={formError ? true : false}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={(e: InputEvent) => {
            setZipCodeLocal(e.target.value);
            setLocalZipCode(e.target.value);

            if (e.target.value.trim() === "" || e.target.value.length === 1)
              setFormError("");
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "" && e.target.value.length === 0) {
              setFormError(t("zipCodeErrorRequired"));
            } else {
              loadLocation({ variables: { zip_code: zip_code_local } });
            }
          }}
          classesProp={classes.textField}
          required={true}
          variant="outlined"
          label={t("zipCodeLabel")}
          value={zip_code_local}
          placeholder={t("zipCodePlaceholder")}
        />
        {formError.includes(t("zipCodeErrorInvalid")) ? (
          <FormHelperText
            id="component-helper-text"
            className={classes.formControlHelperTextZipCodeInvalidError}
            classes={{ root: classes.formControlHelperTextRoot }}
          >
            {formError + " "}
            <LinkButton
              onClick={() => setOpen(!open)}
              classes={classes.formControlHelperTextInvalidErrorLink}
            >
              {t("notSureWhatsWrongAction")}
            </LinkButton>
          </FormHelperText>
        ) : formError.includes(t("zipCodeErrorRequired")) ? (
          <FormHelperText
            id="component-helper-text"
            className={classes.formControlHelperTextZipCodeRequiredError}
            classes={{ root: classes.formControlHelperTextRoot }}
          >
            {formError + " "}
          </FormHelperText>
        ) : null}
      </FormControl>
      <TechnicalAssistanceDialog
        open={open}
        close={() => setOpen(false)}
        flag={"V"}
      />
    </Box>
  );
};

export default ContentZipCode;
