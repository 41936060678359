import React, { FC } from "react";
import TechnicalAssistance from "./TechnicalAssistance/TechnicalAssistance";

const Buttons: FC = () => {
  return (
    <TechnicalAssistance />
  );
};

export default Buttons;
