import { Grid } from "@mui/material";
import React from "react";
import ServicesCluster from "../ServicesCluster/ServicesCluster";
import Summary from "../Summary/Summary";
import { useStylesWrappers } from "./Styles/WrappersStyles";

const ClusterWrapper: React.FC = () => {
  const classes = useStylesWrappers();
  return (
    <Grid container item md={12} className={classes.clusterWrapper}>
      <ServicesCluster />
      <Summary />
    </Grid>
  );
};

export default ClusterWrapper;
