import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useStyles } from "./Style/Styles";
import TrustBox from "../../TrustBox/TrustBox";
import Content from "./SubComponents/Content";
import Header from "./SubComponents/Header";

const ServicesWrapperMobile: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Grid item container className={classes.root}>
      <TrustBox />
      <Grid>
        <Header />
        <Content />
      </Grid>
    </Grid>
  );
};

export default ServicesWrapperMobile;
