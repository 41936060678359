/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ZIP_CODE_CITY,
  TICKET_ID,
  VEHICLE_CHECKPOINT,
  RETURNING_CUSTOMER,
  LICENCE_PLATE_AUTIG,
  RETURNING_CUSTOMER_ERROR,
  CLEAR_ZIP_CODE_AND_LICENCE_FORM,
  SUBMIT_TICKET_RESPONSE,
  SUBMIT_TICKET_INFORMATIONS,
  ERROR_MESSAGE_DIALOG,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  REMOVE_SERVICE_FROM_BASKET,
  TYRES_SELECTED,
  TYRE_DROPDOWNS_DATA,
  CONTACT_DETAILS_RESPONSE,
  CONTACT_DETAILS_FROM_URL,
  CONTACT_DETAILS,
  CALENDAR_DATE,
  INSURANCE_COMPANY,
  INSURANCE_COMPANIES,
  QUERY_PARAMS,
  QUERY_PHONE_NUMBER,
  QUERY_PHONE_NUMBER_USED,
  GOOGLE_ADS_PARAMS,
  SELECT_TIMESLOT,
  UNSELECT_TIMESLOT,
  CAMPAIGNS,
  REMOVE_FIRST_ELEMENT,
  FILL_TIMESLOTS,
  SELECTED_DATE,
  EMPTY_SELECTED_TIMESLOTS,
  ZIP_CODE_CHANGED,
  ADDRESS_CHANGED,
  ANDET_COMMENT,
  CUSTOMER_TYPE,
  CVR,
  SERVICE_PLAN_EXIST
} from "./ActionConstants";
import { AllActions } from "./Actions";
import { StateType } from "./Types";

export const reducer = (state: StateType, action: AllActions): any => {
  switch (action.type) {
    case CUSTOMER_TYPE:
      return { ...state, customerType: action.payload };
    case CVR:
      return { ...state, cvr: action.payload };
    case ZIP_CODE_CITY:
      return { ...state, userLocation: action.payload };
    case QUERY_PARAMS: {
      return {...state, queryParams: action.payload};
    }
    case QUERY_PHONE_NUMBER:
      return { ...state, queryPhoneNumber: action.payload };
    case QUERY_PHONE_NUMBER_USED:
      return { ...state, queryPhoneNumberUsed: action.payload };
    case GOOGLE_ADS_PARAMS:
      return { ...state, googleAdsParams: action.payload };
    case ANDET_COMMENT:
      return { ...state, andetComment: action.payload }
    case REMOVE_SERVICE_FROM_BASKET:
      return { ...state, serviceId: action.payload };
    case TICKET_ID:
      return { ...state, ticket: action.payload };
    case VEHICLE_CHECKPOINT:
      return { ...state, vehicleCheckpoint: action.payload };
    case RETURNING_CUSTOMER:
      return { ...state, returningCustomer: action.payload };
    case "COUPON_URL":
      return { ...state, queryParams: {...state.queryParams,coupon_code: action.payload }};
    case LICENCE_PLATE_AUTIG:
      return { ...state, licence: action.payload };
    case RETURNING_CUSTOMER_ERROR:
      return { ...state, returningCustomerError: action.payload };
    case CLEAR_ZIP_CODE_AND_LICENCE_FORM:
      return {
        ...state,
        userLocation: undefined,
        inputFormZipCode: undefined,
        clearVehicleForm: true,
      };
    case SUBMIT_TICKET_RESPONSE:
      return {
        ...state,
        submitTicketRes: action.payload,
      };
    case SUBMIT_TICKET_INFORMATIONS:
      return {
        ...state,
        ticketInformationsData: action.payload,
      };
    case ERROR_MESSAGE_DIALOG:
      return {
        ...state,
        errorMessageDialog: action.payload,
      };
    case ADD_TO_BASKET:
      return {
        ...state,
        basketData: action.payload,
      };
    case REMOVE_FROM_BASKET:
      if (state.basketData !== null || state.basketData != undefined) {
        if (
          state.basketData?.service_details !== null ||
          state.basketData?.service_details !== undefined
        ) {
          const item = action.payload?.service_details?.map(
            (item: any) => item?.sa_id
          )[0];

          return {
            ...state,
            basketData: {
              ...state.basketData?.service_details?.filter(
                (item: any) =>
                  JSON.stringify(item?.sa_id) != JSON.stringify(item)
              ),
            },
            removedFromBasket: state.basketData.serviceId,
          };
        }
      }
      return {
        ...state,
        basketData: action.payload,
      };
    case TYRES_SELECTED:
      return {
        ...state,
        tyresSelected: action.payload,
      };

    case TYRE_DROPDOWNS_DATA:
      return {
        ...state,
        tyreDropdownsData: action.payload,
      };

    case CONTACT_DETAILS_RESPONSE:
      return {
        ...state,
        contactDetails: action.payload,
      };

    case CONTACT_DETAILS_FROM_URL:
      return {
        ...state,
        contactDetails: action.payload,
      };

    case CONTACT_DETAILS: {
      return {
        ...state,
        contactDetailsInfo: action.payload,
      };
    }
    case CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload,
      };

    case INSURANCE_COMPANY:
      return {
        ...state,
        insuranceCompany: action.payload,
      };

    case INSURANCE_COMPANIES:
      return {
        ...state,
        insuranceCompanies: action.payload,
      };

    case FILL_TIMESLOTS:
      return {
        ...state,
        slots: action.payload,
      };
    case SELECT_TIMESLOT:
      return {
        ...state,
        selectedTimeslots: [...state?.selectedTimeslots, action?.payload],
      };
    case UNSELECT_TIMESLOT:
      return {
        ...state,
        selectedTimeslots: [
          ...state.selectedTimeslots.filter(
            (timeslot) =>
              JSON.stringify(timeslot) !== JSON.stringify(action.payload)
          ),
        ],
      };
    case REMOVE_FIRST_ELEMENT:
      return {
        ...state,
        selectedTimeslots: [...state.selectedTimeslots?.splice(0, 1)],
      };
    case EMPTY_SELECTED_TIMESLOTS:
      return {
        ...state,
        selectedTimeslots: [],
      };
    case SELECTED_DATE:
      return {
        ...state,
        datePickerChoice: action.payload,
      };
    case ZIP_CODE_CHANGED:
      return {
        ...state,
        zipCodeChanged: action.payload,
      };
    case ADDRESS_CHANGED:
      return {
        ...state,
        addressChanged: action.payload,
      };
    case SERVICE_PLAN_EXIST:
      return {
        ...state,
        servicePlanExist: action.payload,
      };

    default:
      return state;
  }
};
