import React, { ReactElement, ReactNode, useReducer } from "react";
import { TimeslotType } from "../interfaces-and-unionTypes/UnionTypes";
import { GlobalContext } from "./GlobalContext";
import { reducer } from "./Reducer";

const initialState = {
  cvr: "",
  customerType: "private",
  zip_code: "",
  userLocation: {
    message: "",
    node: {
      city: "",
      country: "",
      price_ind: "",
      zip_code: "",
    },
    status: "",
  },
  ticket: {
    message: "",
    node: {
      city: "",
      country: "",
      make: "",
      model: "",
      ticket_id: "",
      year: "",
      zip_code: "",
    },
    status: "",
  },
  vehicleCheckpoint: {
    IN_PROGRESS: false,
    COMPLETED: false,
    name: "",
  },
  licence: {
    message: "",
    node: {
      year: "",
      make: "",
      model: "",
      autig_id: "",
      vehicle_id: "",
      engine_type: "",
      engine_code: "",
      licence_plate: "",
      gasoline_diesel: "",
      vin_number: "",
    },
    status: "",
  },
  returningCustomer: {
    message: "",
    node: {
      city: "",
      licence_plate: "",
      ticket_id: "",
      zip_code: "",
    },
    status: "",
  },
  tyresSelected: {
    noFrontTyres: 0,
    noBackTyres: 0,
    isBackLeft: false,
    isBackRight: false,
    isFrontLeft: false,
    isFrontRight: false,
  },
  returningCustomerError: {
    error: "",
  },
  clearVehicleForm: false,
  submitTicketRes: {},
  errorMessageDialog: {
    message: "",
    showDialog: false,
  },
  basketData: {},
  removedFromBasket: "",
  tyreDropdownsData: {
    width: 0,
    profile: 0,
    rim: 0,
    season: "",
    tyreType: "",
  },
  contactDetails: {
    message: "",
    status: "",
    node: {
      address: "",
      address2: "",
      email: "",
      full_name: "",
      phone: "",
    },
  },
  contactDetailsInfo: {
    fullName: "",
    email: "",
    strAddress1: "",
    strAddress2: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
  },
  calendarDate: {
    day: 0,
    month: 0,
    year: 0,
  },
  andetComment: "",
  insuranceCompany: "",
  insuranceCompanies: [],
  googleAdsParams: {
    gclid: "",
    utm_campaign: "",
    utm_medium: "",
    utm_source: "",
  },
  timeslot: {},
  campaigns: [],
  slots: [{}],
  selectedTimeslot: {},
  selectedTimeslots: [],
  datePickerChoice: null,
  zipCodeChanged: "",
  addressChanged: "",
  servicePlanExist: false
};

export const GlobalStateProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
