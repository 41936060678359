import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import ReturningCustomerDialog from "../../library-components/Dialogs/ReturningCustomer/ReturningCustomer";
import { useTranslation } from "react-i18next";
import { isDesktop, isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { HeaderProps } from "../Types/interfaces";
import { useHeaderStyles } from "../Styles/ZipCodeVehicleStyle";
const Header: FC<HeaderProps> = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useHeaderStyles(props);
  const [alignment, setAlignment] = useState(state.customerType);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (newAlignment) {
      setAlignment(newAlignment);
      dispatch({ type: "CUSTOMER_TYPE", payload: newAlignment })
    }
  };
  const [returningCustomerError, setReturningCustomerError] = useState(
    state.returningCustomerError.error
  );

  useEffect(() => {
    setReturningCustomerError(state.returningCustomerError.error || "");
  }, []);

  useEffect(() => {
    if (state.queryPhoneNumber && !state.queryPhoneNumberUsed) {
      if (isMobile) {
        dispatch({
          type: "QUERY_PHONE_NUMBER_USED",
          payload: { queryPhoneNumberUsed: true },
        });
        history.push("/returning");
      } else {
        setOpenDialog(true);
      }
    }
  }, [state.queryPhoneNumber]);

  const [openDialog, setOpenDialog] = useState(false);
  return (
    <div>
      {/* <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        style={{ justifyContent: "center", display: "flex", marginTop: "1rem" }}
      >
        <ToggleButton value="private">Private</ToggleButton>
        <ToggleButton value="business">Business</ToggleButton>
      </ToggleButtonGroup> */}
      <Grid item container md={12} className={classes.rootHeaderGrid}>
        <div className={classes.linkWrapperContainer}>

          <LinkButton
            classes={classes.linkButton}
            onClick={() => {
              if (isDesktop) {
                setOpenDialog(!openDialog);
              } else {
                history.push("/returning");
              }
            }}
          >
            <Typography className={classes.linkTextFont} fontWeight={"medium"}>
              {t("areYouReturningCustomer")}
            </Typography>
          </LinkButton>
        </div>
        <Typography
          variant="h5"
          component="div"
          fontFamily={"Poppins, ExtraBold"}
          fontWeight={1000}
          color="#1A1D1E"
          className={classes.typographyTitle}
        >
          {t("getPrice")}
        </Typography>
        {!isMobile && (
          <Grid item container md={12} className={classes.linkErrorWrapper}>
            <Typography
              variant={"subtitle2"}
              className={classes.linkErrorTypography}
            >
              {state.returningCustomerError.error}
            </Typography>
          </Grid>
        )}
        {isMobile && (
          <Typography
            variant={"subtitle2"}
            className={classes.headerErrorMobileReturningCustomer}
          >
            {returningCustomerError}
          </Typography>
        )}
        <ReturningCustomerDialog
          open={openDialog}
          close={() => {
            setOpenDialog(!openDialog);
          }}
        />
      </Grid>
    </div>
  );
};

export default Header;
