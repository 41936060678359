import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DropdownsSelectionWrapperType } from "../Types/Types";
import TyreSelectionMobile from "./SubComponents/TyreSelectionMobile";
import TyreSpecsMobile from "./SubComponents/TyreSpecsMobile";
import { useStylesTyres } from "../Styles/TyresStyles";

const DropdownsSelectionWrapperMobile: FC<DropdownsSelectionWrapperType> = (
  props
) => {
  const { setShow, setShowTyreSelection, showTyreSelection, serviceId } = props;

  const [showDropDowns, setShowDropDowns] = useState(false);

  useEffect(() => {
    if (showTyreSelection == false) {
      setShowDropDowns(true);
    }
  }, [showTyreSelection]);

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");
  const classes = useStylesTyres();

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Grid className={classes.dropdownsRootMobile}>
      {showTyreSelection && (
        <Grid className={classes.root}>
          <TyreSelectionMobile
            setShow={setShow}
            setShowTyreSelection={setShowTyreSelection}
          />
        </Grid>
      )}
      {showDropDowns && (
        <Grid>
          <TyreSpecsMobile
            setShow={setShow}
            setShowDropDowns={setShowDropDowns}
            serviceId={serviceId}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DropdownsSelectionWrapperMobile;
