import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { getServiceById } from "../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../context/GlobalContext";
import { InfoDialogProps } from "../Types/DialogTypes";
import { useStylesInfoDialog } from "./Styles/InfoDialogStyles";
type InfoDialogPropType = {
  isMaxWidth: boolean;
};

const InfoDialog: FC<InfoDialogProps> = (props) => {
  const {
    title,
    content,
    open,
    onClose,
    actions,
    serviceId,
    fullWidth,
    actionsStyle,
    maxWidth,
  } = props;
  const classProps: InfoDialogPropType = {
    isMaxWidth: maxWidth as boolean,
  };
  const { state } = useContext(GlobalContext);
  const classes = useStylesInfoDialog(classProps);
  const history = useHistory();

  const [loadServiceById, result] = useLazyQuery<Partial<any>>(getServiceById);

  useEffect(() => {
    if (open === true) {
      loadServiceById({
        variables: {
          service_id: serviceId,
          ticket_id: state.ticket?.node?.ticket_id || "",
        },
      });
    }
  }, [serviceId]);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getServiceById?.node === null) {
        alert(result?.data?.getServiceById?.message);
      }
      if (result?.data?.getServiceById?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      className={!isMobile ? classes.root : classes.rootMobile}
    >
      <DialogTitle className={classes.header}>
        <div className={classes.headerWrapper}>
          <Typography
            fontFamily={"Poppins"}
            fontSize={16}
            fontWeight={"bold"}
            color="#353535"
          >
            {title}
          </Typography>
        </div>
        <Grid className={classes.closeButton}>
          <IconButton onClick={() => onClose(!open)}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid item maxWidth={"100%"} className={classes.dialogContentWrapper}>
          {serviceId?.trim() === "" || serviceId === undefined ? (
            content
          ) : (
            <Typography
              fontFamily={"Poppins"}
              fontWeight={"medium"}
              fontSize={16}
              textAlign={"center"}
              width={"100%"}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: result?.data?.getServiceById?.node?.more_info,
                }}
              ></div>
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={actionsStyle}>
        {actions ? <div>{actions}</div> : null}
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
