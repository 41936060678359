import { Grid } from "@mui/material";
import React, { FC } from "react";
import FinalChecks from "./SubComponents/FinalChecks";
import PreEngineChecks from "./SubComponents/PreEngineChecks";
import UnderTheBonnet from "./SubComponents/UnderTheBonnet";
import Undervogn from "./SubComponents/Undervogn";
import VehicleLowered from "./SubComponents/VehicleLowered";
import VehicleRaised from "./SubComponents/VehicleRaised";

const ServiceInclusionChecklist: FC = () => {
  return (
    <Grid item container>
      <PreEngineChecks />
      <UnderTheBonnet />
      <Undervogn />
      <VehicleRaised />
      <VehicleLowered />
      <FinalChecks />
    </Grid>
  );
};

export default ServiceInclusionChecklist;
