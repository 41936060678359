import { Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import ActionCard from "../library-components/ActionCard/ActionCard";
import { CardStyleType } from "../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import { LinkButton } from "../library-components/Buttons/Buttons";
import DetailsContentTechnical from "./SubComponents/DetailsContentTechnical";
import HeaderTechnical from "./SubComponents/HeaderTechnical";
import { useStylesDesktopSummaryCheckout } from "./Styles/SummaryCheckoutStyles";

const styles: CardStyleType = {
  header: {
    paddingBottom: 0,
  },
  boxHeight: {
    height: "100%",
    marginTop: "3%",
  },
  boxWidth: {
    width: 723,
  },
  container: {},
};

const SummaryCheckoutTechnical: FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const classes = useStylesDesktopSummaryCheckout();

  useEffect(() => {
    scrollToTop();
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <Grid item container className={classes.root}>
      <ActionCard
        Header={<HeaderTechnical />}
        Content={
          <Grid>
            <Grid item container md={12} className={classes.contentRoot}></Grid>
            <DetailsContentTechnical />
          </Grid>
        }
        Actions={
          <Grid className={classes.actionsRoot}>
            <Typography>
              {"Gå til "}
              <LinkButton
                onClick={() => {
                  scrollToTop();
                  window.location.reload();
                }}
                classes={classes.link}
              >
                {"hjemmeside"}
              </LinkButton>
            </Typography>
          </Grid>
        }
        style={styles}
      />
    </Grid>
  );
};

export default SummaryCheckoutTechnical;
