import { Grid } from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import ZipCodeAndVehicle from "../ZipCodeAndVehicle/ZipCodeAndVehicle";
import { useStylesWrappers } from "./Styles/WrappersStyles";
import queryString from "query-string";
import { getCookie } from 'typescript-cookie'
import {QUERY_PARAMS} from "../../context/ActionConstants";

const VehicleWrapper: React.FC = () => {
  const classes = useStylesWrappers();
  const { state, dispatch } = useContext(GlobalContext);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [readyToRender, setReadyToRender ] = useState(false)
  useEffect( () => {
    if (params.clear) {
      dispatch({
        type: "QUERY_PARAMS",
        payload: {coupon_code: state.queryParams?.coupon_code??''},
      });
      dispatch({
        type: "QUERY_PHONE_NUMBER",
        payload: {phoneNumber: null},
      });
      dispatch({
        type: "QUERY_PHONE_NUMBER_USED",
        payload: { queryPhoneNumberUsed: true },
      });
    } else {
      if (params && !state.queryParams) {
        if (params.Coupon_code) {
          params['coupon_code'] = params.Coupon_code
          delete params['Coupon_code']
        }
        dispatch({
          type: "QUERY_PARAMS",
          payload: {...params, service_ids: params.service_ids ? (params.service_ids as string).split(',') : []},
        });
      }
      /* do not use prefil from phone GET param
      /*if ("phone" in params && !state.queryPhoneNumber) {
        dispatch({
          type: "QUERY_PHONE_NUMBER",
          payload: {phoneNumber: params.phone},
        });
      }*/
    }
    dispatch({
      type: "GOOGLE_ADS_PARAMS",
      payload: {
        gclid: params?.gclid ?? getCookie('gclid') ??'',
        utm_source: params?.utm_source ?? getCookie('utm_source'),
        utm_medium: params?.utm_medium ?? getCookie('utm_medium'),
        utm_campaign: params?.utm_campaign ?? getCookie('utm_campaign'),
      }
    })
    console.log('Call setRender')
    setReadyToRender(true)
  }, [])

  return readyToRender ?(
    <Grid container item md={12} className={classes.vehicleWrapper}>
      <ZipCodeAndVehicle />
    </Grid>
  ) : <div></div>;
};

export default VehicleWrapper;
