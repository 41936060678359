export const getCouponCode = async (couponCode: string) => {
    const URL = `https://markjust.com/zoho/v3/coupon_validation.php?coupon=${couponCode}`;
  try {
    const response = await fetch(URL, {
      mode: 'cors',
      headers: {
        //'Access-Control-Allow-Origin': '*',
      }
    });
    const data = await response.json();
    const couponCodeResult = data?.data?.data[0] ?? null

    return couponCodeResult;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const abandonedCart = async (cartData: any) => {
  if (cartData?.second_step?.service_details) {
    console.log('Cart Data')
    console.log(cartData.second_step.service_details)
  }
  const url = "https://markjust.com/zoho/v3/abandoned_cart.php";
  const options: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "no-cors",
    body: JSON.stringify(cartData)
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    if (result.success) {
      console.log("Data was sent successfully");
    } else {
      console.error("Error:", result.message);
    }
  } catch (error) {
    console.error("Error:", error);
  }
}


export const couponCodeCounter = async (id: string) => {
  const URL = `https://markjust.com/zoho/v3/coupon_counter.php?id=${id}`;
  try {
    const response = await fetch(URL, {
      mode: 'cors',
      headers: {
        //'Access-Control-Allow-Origin': '*',
      }
    });
    const data = await response.json();

    return data
  } catch (error) {
    console.error(error);
    throw error;
  }
}