import { ClickAwayListener, Grid, Tooltip } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  computeCampaign,
  contactDetailsScreen,
} from "../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  ComputeServiceDataResponse,
  ContactDetailsScreenResponse,
} from "../../../interfaces-and-unionTypes/Interfaces";
import {
  LinkButton,
  SimpleButton,
} from "../../library-components/Buttons/Buttons";
import { useStylesMobileBasketButtons } from "../Styles/SummaryStyles";
import { ButtonsProps } from "../Types/interfaces";
import CampaignCodeDialog from "../../library-components/Dialogs/CampaignCodeDialog";

const ContinueBackButtonsBasketMobile: FC<ButtonsProps> = (props) => {
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalContext);
  const query = useLocation();

  const { t } = useTranslation();
  const heavyCampaigns = ["fleggaardleasing0"]

  const [openCampaignCodeModalMobile, setOpenCampaignCodeModalMobile] =
    useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const classes = useStylesMobileBasketButtons(props);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [fillContactDetails, resultFill] = useLazyQuery<
    Partial<ContactDetailsScreenResponse>
  >(contactDetailsScreen, {
    onCompleted: (data) => {
      dispatch({
        type: "CONTACT_DETAILS_RESPONSE",
        payload: data.contactDetailsScreen,
      });
      history.push(`/services/checkout${query.search && query.search}`);
    },
  });

  useEffect(() => {
    if (resultFill?.data === null) {
      alert(resultFill?.error?.message);
    } else {
      if (resultFill?.data?.contactDetailsScreen?.node === null) {
        alert(resultFill?.data?.contactDetailsScreen?.message);
      }
      if (
        resultFill?.data?.contactDetailsScreen?.message === "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [resultFill?.data]);

  const [computedCampaign, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeCampaign, {
    onCompleted: (data) =>
      dispatch({
        type: "ADD_TO_BASKET",
        payload: data.computeCampaign?.node,
      }),
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeCampaign?.node === null) {
        alert(result?.data?.computeCampaign?.message);
      }
      if (result?.data?.computeCampaign?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  return (
    <Grid item container md={12} className={classes.continueBackRoot}>
      {!window.location.href.includes("checkout") ? (
        <Grid item container md={12} className={classes.continueBackWrapper}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Grid item container className={classes.continueBackWrapper}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                arrow={true}
                title={"Tilføj noget til kurven for at forstætte til checkout"}
              >
                <Grid
                  item
                  container
                  md={12}
                  className={classes.continueBackPosition}
                >
                  <SimpleButton
                    onClick={() => {
                      history.goBack();
                    }}
                    classes={classes.backButton}
                  >
                    {t("backToMainTab")}
                  </SimpleButton>
                  <SimpleButton
                    onClick={() => {
                      if (
                        !state.basketData ||
                        !state.basketData?.service_details ||
                        state.basketData?.service_details?.length < 1
                      ) {
                        handleTooltipOpen();
                      } else {
                        fillContactDetails({
                          variables: {
                            ticket_id: state.ticket?.node.ticket_id,
                          },
                        });
                      }
                    }}
                    classes={classes.continueButton}
                  >
                    {t("continueButton")}
                  </SimpleButton>
                </Grid>
              </Tooltip>
              {!heavyCampaigns.includes(state.basketData?.campaign_code) &&
                <LinkButton
                  style={{
                    fontWeight: "normal",
                    color: "#5F58FE",
                    textDecorationColor: "#5F58FE",
                    fontSize: 15,
                    marginTop: "20px",
                  }}
                  onClick={() => setOpenCampaignCodeModalMobile(true)}
                >
                  {t("campaignCodeLink")}
                </LinkButton>}
            </Grid>
          </ClickAwayListener>
        </Grid>
      ) : null}
      <CampaignCodeDialog
        open={openCampaignCodeModalMobile}
        close={() => setOpenCampaignCodeModalMobile(false)}
        save={computedCampaign}
      />
    </Grid>
  );
};

export default ContinueBackButtonsBasketMobile;
