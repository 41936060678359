import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC, useContext } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../../../context/GlobalContext";
import { isMobile } from "react-device-detect";
import {
  useStylesDesktopServicingChecklist,
  useStylesDesktopServicingTables,
} from "../../../Styles/ServicingStyles";

const UnderTheBonnet: FC = () => {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const motortype = state.ticket?.node?.motortype;
  const classes = useStylesDesktopServicingChecklist();
  const styles = useStylesDesktopServicingTables();

  const rows = [
    {
      title: t("table2-servicing-checklist.kontrolGearolieInterval"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.kontrolGeneratorsLadeeffekt"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.kontrolFrostsikringspunkt"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.kontrolMultiremme"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.kontrolDieselPartikel"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkCoolingSystemIncludingFanOperation"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table2-servicing-checklist.checkAndRecordAntifreezeProtection"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table2-servicing-checklist.checkAndRecordBrakeFluidCondition"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      title: t("table2-servicing-checklist.checkPowerSteeringOperationAndFluidCondition"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkAllAuxiliaryDriveBelts"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkEngineBreatherSystem"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkVacuumPipes"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkThrottleBody"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.checkBatteryLevelAndLubricateTerminals"),
      basic: false,
      guarantee: true,
      el: true,
    },
    {
      title: t("table2-servicing-checklist.checkConditionOfSparkPlugs"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.replaceSparkPlugs"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.replaceAirFilter"),
      basic: false,
      guarantee: true,
      el: false,
    },
    {
      title: t("table2-servicing-checklist.replacePollenFilter"),
      basic: false,
      guarantee: true,
      el: true,
    },
  ];
  return (
    <Grid className={classes.root}>
      <TableContainer component={Grid} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={`${styles.tableHeadCells} ${classes.tableHeadCells}`}
              >
                {t("table2-servicing-checklist.underTheBonnetTitle")}
              </TableCell>
              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table2-servicing-checklist.basic")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table2-servicing-checklist.guarantee")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() == "el" ? (
                <TableCell align="center" className={styles.tableHeadCells}>
                  {t("table2-servicing-checklist.el")}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <>
                {(motortype?.toLowerCase() === "el" && item.el === true) ||
                motortype?.toLowerCase() !== "el" ? (
                  <TableRow
                    key={item.title}
                    className={styles.tableRowLastChildBorder}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight:
                          item.title.trim() === "" ? "bold" : "normal",
                        display:
                          item.title.trim() == "" ? "flex" : "table-cell",
                        justifyContent:
                          item.title.trim() == "" ? "flex-start" : "inherit",
                        alignItems:
                          item.title.trim() == "" ? "center" : "inherit",
                        padding: "1px",
                        fontSize: !isMobile ? "14px" : "10px",
                      }}
                    >
                      {item.title}
                    </TableCell>
                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.basic ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.basic == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.guarantee ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.guarantee == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {motortype?.toLowerCase() == "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight:
                            item.title.trim() === "" ? "bold" : "normal",
                        }}
                      >
                        {item.el ? (
                          <DoneIcon className={styles.doneIcon} />
                        ) : item.el == false ? (
                          <CloseIcon className={styles.closeIcon} />
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UnderTheBonnet;
