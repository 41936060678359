import { Grid, InputAdornment, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import InputField from "../../../../InputFields/InputFields";
// import { useDialogStylesReturningCustomer } from "../../../Styles/DialogStyle";

type ReturningCustomerMobileInputProps = {
  setNumber: (number: string) => void;
  number: string;
};

const Content: FC<ReturningCustomerMobileInputProps> = (props) => {
  // const classes = useDialogStylesReturningCustomer();
  const { t } = useTranslation();

  const { number, setNumber } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const [error, setError] = useState("");
  const [num, setNum] = useState("");

  useEffect(() => {
    dispatch({ type: "RETURNING_CUSTOMER_ERROR", payload: { error } });
  }, [error]);

  useEffect(() => {
    setError(state.returningCustomerError.error);
  }, [state.returningCustomerError.error]);

  const propagate = (value: string) => {
    setNumber(value);
  };

  // useEffect(() => {
  //   setNumber(num)
  // }, [num])

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      item
      container
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ textAlign: "center", width: "100%" }}>
          {t("dialogDescriptionB2bCustomer")}
        </Typography>
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5vh 0",
        }}
      >
        <InputField
          error={error.trim() !== "" ? true : false}
          focused={window.location.pathname.includes("returning")}
          required={false}
          label={t("customerNumberLabel")}
          variant="outlined"
          value={num}
          onChange={(e: InputEvent) => {
            const value = (e.target as HTMLInputElement).value;
            const phoneRegex = new RegExp("^[0-9]{0,8}$");
            if (phoneRegex.test(value)) {
              setNum(value);
              propagate(value);
              setError("");
            }
            if (
              (e.target as HTMLInputElement).value.length <=
              (e.target as HTMLInputElement).value.length - 1
            )
              setError("");
          }}
          placeholder={t("phoneNumberPlaceholder")}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: "^[0-9]{0,8}$",
            maxLength: 8,
          }}
          helperText={error}
        />
      </Grid>
    </Grid>
  );
};

export default Content;
