import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Services from "../Services/Services";
import TrustBox from "../TrustBox/TrustBox";
import { useStylesWrappers } from "./Styles/WrappersStyles";
import { useLazyQuery } from "react-apollo";
import { ComputeServiceDataResponse } from "../../interfaces-and-unionTypes/Interfaces";
import { computeCampaign, getCampaignById } from "../../API/graphql-queries/Queries";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory, useLocation } from "react-router-dom";


const ServicesWrapper: React.FC = () => {
  const classes = useStylesWrappers();
  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();
  const [loadCampaignById, result] = useLazyQuery<Partial<any>>(
    getCampaignById,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [computedCampaign] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeCampaign, {
    onCompleted: (data) =>
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeCampaign?.node }),
    fetchPolicy: "no-cache",
  });

  const location = useLocation();
  useEffect(() => {
    loadCampaignFromUrl();
  }, [])


  useEffect(() => {
    if (!state?.servicePlanExist && state.basketData?.campaign_code?.toLowerCase() === "serviceplan") {
      computedCampaign({
        variables: {
          campaign_code: "",
          ticket_id: state.ticket?.node?.ticket_id || "",
        },
      });
    }
  }, [])
  const loadCampaignFromUrl = async () => {
    let couponCode;
    if (state?.servicePlanExist) {
      couponCode = "serviceplan"
    } else {
      couponCode = state.queryParams?.coupon_code
    }
    if (couponCode) {
      if (couponCode.trim() !== "") {
        loadCampaignById({
          variables: {
            campaign_code: couponCode,
          },
        });
      } else if (couponCode.trim() === "") {
        Submit();
      }
    }
  }

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getCampaignById?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const Submit = () => {
    computedCampaign({
      variables: {
        campaign_code: result?.data?.getCampaignById?.node?.campaign_code !== "" ? result?.data?.getCampaignById?.node?.campaign_code : "",
        ticket_id: state.ticket?.node?.ticket_id || "",
      },
    });
  };

  useEffect(() => {
    if (result?.data?.getCampaignById?.status === "SUCCESS") {
      Submit();
    }
  }, [result?.data]);
  return (
    <Grid container item md={12} className={classes.servicesWrapper}>
      <TrustBox />
      <Services />
    </Grid>
  );
};

export default ServicesWrapper;
