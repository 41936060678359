import { ClickAwayListener, Grid, Tooltip } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { abandonedCart } from "../../../API/fetch-api/get.coupon.code";
import { contactDetailsScreen } from "../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../context/GlobalContext";
import { ContactDetailsScreenResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { SimpleButton } from "../../library-components/Buttons/Buttons";
import { useStylesBasketContent } from "../Styles/SummaryStyles";
type BasketStylePropType = {
  isCheckout: boolean;
};
const ContinueButton: FC = () => {
  const props: BasketStylePropType = {
    isCheckout: window.location.pathname.includes("checkout"),
  };
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalContext);

  const classes = useStylesBasketContent(props);
  const query = useLocation();
 
  const { t } = useTranslation();

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [fillContactDetails, result] = useLazyQuery<
    Partial<ContactDetailsScreenResponse>
  >(contactDetailsScreen, {
    onCompleted: (data) => {
      /*if (data?.contactDetailsScreen?.node) {
        data.contactDetailsScreen!.node = Object.entries(data?.contactDetailsScreen?.node)
            .filter(([, value]) => value !== null && value !== undefined && value !== '')
            .reduce((newObject, [key, value]) => ({ ...newObject, [key]: value }), {});
      }
      const d =
      data.contactDetailsScreen!.node = Object.fromEntries(Object.entries(data.contactDetailsScreen?.node).filter((v)=>v))

      console.log(data?.contactDetailsScreen?.node)*/

      if (!(data.contactDetailsScreen?.node?.address) && state.contactDetails.node?.address) {
        data.contactDetailsScreen!.node!.address = state.contactDetails.node?.address
      }
      if (!(data.contactDetailsScreen?.node?.phone) && state.contactDetails.node?.phone) {
        data.contactDetailsScreen!.node!.phone = state.contactDetails.node?.phone
      }
      if (!(data.contactDetailsScreen?.node?.full_name) && state.contactDetails.node?.full_name) {
        data.contactDetailsScreen!.node!.full_name = state.contactDetails.node?.full_name
      }
      dispatch({
        type: "CONTACT_DETAILS_RESPONSE",
        payload: data.contactDetailsScreen,
      });
      abandonedCart({
        'second_step': { ...state.returningCustomer.node, ...state.basketData,...state?.googleAdsParams }
      })
      history.push(`/services/checkout${query.search && query.search}`);
    },
  });

  useEffect(() => {
    if (state.basketData?.service_details !== undefined && state.basketData?.service_details?.length > 0) {
      abandonedCart({
        'second_step': { ...state.returningCustomer.node, ...state.basketData,...state?.googleAdsParams }
      })
    }
  }, [state.basketData?.service_details])

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.contactDetailsScreen?.node === null) {
        alert(result?.data?.contactDetailsScreen?.message);
      }
      if (result?.data?.contactDetailsScreen?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  return (
    <Grid className={classes.continueButtonRoot}>
      {!window.location.href.includes("checkout") ? (
        <Grid item container className={classes.continueButtonWrapper}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                arrow={true}
                title={"Tilføj noget til kurven for at forstætte til checkout"}
              >
                <div>
                  <SimpleButton
                    buttonId={"continueBtn"}
                    onClick={() => {
                      if (
                        !state.basketData ||
                        !state.basketData?.service_details ||
                        state.basketData?.service_details?.length < 1
                      ) {
                        handleTooltipOpen();
                      } else {
                        fillContactDetails({
                          variables: {
                            ticket_id: state.ticket?.node.ticket_id,
                          },
                        });
                      }
                    }}
                    classes={classes.continueButton}
                  >
                    {t("continueButton")}
                  </SimpleButton>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ContinueButton;
