import { Grid, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import PaperPlane from "../../../../assets/illustrations/awesome-paper-plane.svg";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesMobileSummaryCheckout } from "../../Styles/SummaryCheckoutStyles";

const HeaderMobile: FC = () => {
  const { state } = useContext(GlobalContext);
  const classes = useStylesMobileSummaryCheckout();
  const heavyCampaigns = ["fleggaardleasing0"]

  return (
    <Grid item container md={12} className={classes.headerWrapper}>
      <Grid item container md={12} className={classes.headerImage}>
        <img src={PaperPlane} className={classes.headerImageWidth} />
      </Grid>
      <div className={classes.headerTitleWrapper}>

        {!heavyCampaigns.includes(state.basketData?.campaign_code) ?
          <Typography className={classes.headerTitleText}>
            {"Tak for din ordre/forespørgsel!"}
          </Typography> :
          <Typography className={classes.headerTitleText}>
            {"Tak for din booking!"}
          </Typography>}
      </div>
      {state.submitTicketRes?.node &&
        state.submitTicketRes?.node?.om_message?.trim() !== "" &&
        state.basketData?.root_missing == 0 ? (
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.missingItemText}>
            {"Minimumskøb på 698 DKK for online booking er ikke opnået."}
          </Typography>
        </div>
      ) : null}
      <div className={classes.headerDescription}>
        {!heavyCampaigns.includes(state.basketData?.campaign_code) ? <Typography className={classes.headerDescriptionText}>
          {
            "Du vil inden for et par minutter modtage en ordrebekræftelse på mail."
          }
          {
            " Hvis prisen på det ønskede arbejde ikke er blevet automatisk genereret, kan du forvente, at vi vender retur med et tilbud inden for 24 timer på hverdage."
          }
        </Typography> :
          <Typography className={classes.headerDescriptionText}>
            {
              "Du vil inden for et par minutter modtage en ordrebekræftelse på mail. Såfremt vi skulle have opklarende spørgsmål til din bestilling, vil vi tage kontakt til dig."
            }
          </Typography>}
      </div>
    </Grid>
  );
};

export default HeaderMobile;
