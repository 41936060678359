import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  useTheme,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";

import { useDialogStylesReturningCustomer } from "./Styles/DialogStyle";
import { DialogProps } from "./Types/DialogTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useLazyQuery } from "react-apollo";
import { getServiceById } from "../../../API/graphql-queries/Queries";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "-webkit-box",
  },
});

const ServicesInfoDialog: FC<DialogProps> = (props) => {
  const classes = useDialogStylesReturningCustomer();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalContext);

  const theme = useTheme();

  const style = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const matches = useMediaQuery("(max-height: 800px)");

  const { open, close, content } = props;

  const history = useHistory();

  const service_id = content.service_id;
  const closeDialog = (data: any) => {
    close(data);
  };
  const handleClose = () => {
    close(false);
  };

  const [loadServiceById, result] = useLazyQuery<Partial<any>>(getServiceById);

  useEffect(() => {
    if (open) {
      loadServiceById({
        variables: {
          service_id: service_id,
          ticket_id: state.ticket?.node?.ticket_id || "",
        },
      });
    }
  }, [service_id]);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.getServiceById?.node === null) {
        alert(result?.data?.getServiceById?.message);
      }
      if (result?.data?.getServiceById?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={closeDialog}
      fullWidth={fullScreen}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          WebkitJustifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          WebkitAlignContent: "center",
          WebkitAlignItems: "center",
          padding: "2% 0px 2% 4%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
            }}
            fontSize={16}
            fontWeight={800}
            fontFamily={"Poppins, Bold"}
            color="#353535"
          >
            {content?.service_dsc}
          </Typography>
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        style={{
          display: "-webkit-box",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "visible",
        }}
      >
        <div style={{ padding: "2% 4% 4% 4%" }}>
          <div
            style={{
              height: "100%",
              maxHeight: "100%",
            }}
            dangerouslySetInnerHTML={{
              __html: result?.data?.getServiceById?.node?.more_info,
            }}
          ></div>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "6vh",
          fontFamily: "Poppins",
        }}
      >
        <LoadingButton
          style={{
            width: 155,
            height: 47,
            background: "#8D88FF",
            color: "#F6F6FF",
            font: "22px",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          onClick={handleClose}
          role={"progressbar"}
          loading={false}
          loadingIndicator={
            <CircularProgress style={{ color: "white" }} size={32} />
          }
        >
          {t("closeButton")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ServicesInfoDialog;
