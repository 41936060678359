import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDK from "./assets/translations/en/translationDK.json";

i18n.use(initReactI18next).init({
  ns: [
    "translation",
  ],
  returnObjects: true,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // debug: true,
  resources: {
    en: {
      translation: translationDK,
    },
  },
});
