import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesMobileSummaryCheckout } from "../../Styles/SummaryCheckoutStyles";

const ServicesContentMobile: FC = () => {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const classes = useStylesMobileSummaryCheckout();
  const heavyCampaigns = ["fleggaardleasing0"]

  const [basketItems, setBasket] = useState(
    state.basketData?.service_details || {}
  );

  useEffect(() => {
    setBasket(state.basketData?.service_details);
  }, []);

  if (!basketItems || !Object.keys(basketItems).length) return <></>;

  return (
    <Grid className={classes.cardRoot}>
      <Grid>
        <Typography className={classes.cardTitleText}>
          {"Bestilte services"}
        </Typography>
      </Grid>
      {basketItems.map((item: any) => {
        return (
          <Grid
            key={item.service_id}
            className={`${classes.cardItemRoot} ${classes.cardItemWrapper}`}
          >
            <Grid
              className={`${classes.cardItemWrapper} ${classes.servicesContentCardItemWidth} `}
            >
              <Typography className={classes.cardItemName}>
                {item.sa_dsc}
              </Typography>
            </Grid>
            {!heavyCampaigns.includes(state.basketData?.campaign_code) && <Grid className={classes.cardItemValueWrapper}>
              <Typography className={classes.cardItemValueText}>
                {item.sa_price === "0" ? t("priceCannotBeCalculated") : Number(item.sa_price).toLocaleString('da-dk') + " DKK"}
              </Typography>
            </Grid>}
          </Grid>
        );
      })}
      {!heavyCampaigns.includes(state.basketData?.campaign_code) && <>
        <Grid className={classes.cardItemRoot}>
          <Grid className={classes.cardItemWrapper}>
            <Typography className={classes.cardItemValueText}>
              I alt inkl. 25% moms
            </Typography>
            <Grid className={classes.cardItemValueWrapper}>
              <Typography className={classes.cardItemValueText}>
                {state.basketData?.total_price + " DKK"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.cardItemRoot}>
          {state.basketData?.fee_amount && (
            <Grid className={classes.cardItemWrapper}>
              <Typography className={classes.additionalFeesText}>
                {t("additionalFees") + " " + Math.round(parseInt(state.basketData?.fee_amount)) + " DKK"}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.cardItemRoot}>
          <Grid className={classes.cardItemWrapper}>
            {state.basketData?.campaign_code ? (
              <div>
                {state.basketData?.total_discount !== "0.00" ? (
                  <div>
                    <Grid item md={12} className={classes.additionalFeesText}>
                      <Typography className={classes.additionalFeesText}>
                        {t("youAppliedCode")}{" "}
                        <span
                          className={`${classes.additionalFeesText} ${classes.discountLine}`}
                        >
                          {state.basketData?.campaign_code}
                        </span>
                        {state.basketData?.total_discount?.length &&
                          state.basketData?.service_details?.length ? (
                          <span>
                            <span className={classes.additionalFeesText}>
                              {t("withDiscount")}
                            </span>
                            <span
                              className={`${classes.additionalFeesText} ${classes.discountLine}`}
                            >
                              {state.basketData?.total_discount + " DKK. "}
                            </span>
                            <span className={classes.additionalFeesText}>
                              {t("discountExplanation")}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Grid item md={12} className={classes.additionalFeesText}>
                      <Typography className={classes.additionalFeesText}>
                        {t("campaignCode")}
                        {": "}
                        <span
                          className={`${classes.additionalFeesText} ${classes.discountLine}`}
                        >
                          {state.basketData?.campaign_code}
                        </span>
                        {state.basketData?.total_discount?.length &&
                          state.basketData?.service_details?.length ? (
                          <span>
                            <span className={classes.additionalFeesText}>
                              {t("withDiscountPriceZero")}
                            </span>
                            <span
                              className={`${classes.additionalFeesText} ${classes.discountLine}`}
                            >
                              {state.basketData?.total_discount + " DKK"}
                            </span>
                            <span className={classes.additionalFeesText}>
                              {t("discountExplanationPriceZero")}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Grid>
                  </div>
                )}
              </div>
            ) : null}
          </Grid>
        </Grid>
      </>}
    </Grid>
  );
};

export default ServicesContentMobile;
