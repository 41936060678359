import { Grid, useMediaQuery } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TyreSelection from "../SubComponents/TyreSelection";
import TyreSpecs from "../SubComponents/TyreSpecs";
import { DropdownsSelectionWrapperType } from "../Types/Types";
import { useStylesTyres } from "../Styles/TyresStyles";

const DropdownsSelectionWrapper: FC<DropdownsSelectionWrapperType> = (
  props
) => {
  const { setShow, setShowTyreSelection, showTyreSelection, serviceId } = props;

  const [showDropDowns, setShowDropDowns] = useState(false);
  const classes = useStylesTyres();
  const matches = useMediaQuery("(min-width: 1480px)");

  useEffect(() => {
    if (showTyreSelection == false) {
      setShowDropDowns(true);
    }
  }, [showTyreSelection]);

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Grid className={classes.dropdownsWrapper}>
      {showTyreSelection && (
        <Grid>
          <TyreSelection
            matches={matches}
            setShow={setShow}
            setShowTyreSelection={setShowTyreSelection}
          />
        </Grid>
      )}
      {showDropDowns && (
        <Grid>
          <TyreSpecs
            setShow={setShow}
            setShowDropDowns={setShowDropDowns}
            serviceId={serviceId}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DropdownsSelectionWrapper;
