import { Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import TyreCartOptionsMobile from "./SubComponents/TyreCartOptionsMobile";
import DropdownsSelectionWrapperMobile from "./DropdownsSelectionWrapperMobile";
import BasketMobile from "../../../../Summary/BasketMobile/BasketMobile";
import TrustBox from "../../../../TrustBox/TrustBox";
import { useHistory } from "react-router-dom";
import { useStylesTyres } from "../Styles/TyresStyles";

const TyresWrapperMobile: FC = () => {
  const [show, setShow] = useState(false);
  const [showTyreSelection, setShowTyreSelection] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const classes = useStylesTyres();

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div>
      <TrustBox />
      <Grid item container>
        {!show ? (
          <>
            <TyreCartOptionsMobile
              setShow={setShow}
              setServiceId={setServiceId}
              setShowTyreSelection={setShowTyreSelection}
            />
          </>
        ) : (
          <Grid
            item
            container
            className={`${classes.root} ${classes.wrapperMobileMargin}`}
          >
            <DropdownsSelectionWrapperMobile
              setShow={setShow}
              serviceId={serviceId}
              setShowTyreSelection={setShowTyreSelection}
              showTyreSelection={showTyreSelection}
            />
          </Grid>
        )}
        <BasketMobile />
      </Grid>
    </div>
  );
};

export default TyresWrapperMobile;
