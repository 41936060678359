import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { ConversionRateForm } from '../../Types/Types'
import { Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useStylesDesktop } from '../Styles/ZipCodeVehicleStyle';
import { GlobalContext } from '../../../context/GlobalContext';
import InputField from '../../library-components/InputFields/InputFields';
import { useTranslation } from 'react-i18next';

const ConversionRate: FC<ConversionRateForm> = ({ cvrRef, cvr, setCvr }) => {
    const { state, dispatch } = useContext(GlobalContext);
    const [formError, setFormError] = useState("");
    const classes = useStylesDesktop();
    useEffect(() => {
        setCvr(state.cvr || "");
    }, [state.returningCustomer?.node, state.clearVehicleForm]);

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            style={{ width: !isMobile ? "28vw" : "80vw" }}
        >
            <FormControl fullWidth className={classes.formControl}>
                <InputField
                    inputRef={cvrRef}
                    id="standard-basic"
                    error={formError ? true : false}
                    onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCvr(e.target.value);
                        dispatch({ type: "CVR", payload: e.target.value })
                        if (e.target.value.trim() === "" || e.target.value.length === 1)
                            setFormError("");
                    }}
                    onBlur={(e) => {
                        if (e.target.value.trim() === "" && e.target.value.length === 0) {
                            setFormError("Indtast CVR");
                        }
                    }}
                    classesProp={classes.textField}
                    required={true}
                    variant="outlined"
                    label={"CVR"}
                    value={cvr}
                    placeholder='Indtast CVR'
                />{formError.includes("Indtast CVR") ? (
                    <FormHelperText
                        id="component-helper-text"
                        className={classes.formControlHelperTextZipCodeRequiredError}
                        classes={{ root: classes.formControlHelperTextRoot }}
                    >
                        {formError + " "}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </Box>
    )
}

export default ConversionRate