import {
  DialogContent,
  DialogTitle,
  Dialog,
  useTheme,
  Typography,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { SimpleButton } from "../Buttons/Buttons";
import { useDialogStyleCampaignCode } from "./Styles/DialogStyle";
import { DialogProps } from "./Types/DialogTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCampaignById } from "../../../API/graphql-queries/Queries";
import InputField from "../InputFields/InputFields";
import { useLazyQuery } from "react-apollo";
import { Close } from "@mui/icons-material";
import {  isMobile } from "react-device-detect";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";


const CampaignCodeDialog: FC<DialogProps> = (props) => {
  const classes = useDialogStyleCampaignCode();
  const { state } = useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const { open, close, save } = props;
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState("");
  const [loadCampaignById, result] = useLazyQuery<Partial<any>>(
    getCampaignById,
    {
      fetchPolicy: "no-cache",
    }
  );

  const location = useLocation();

  // useEffect(() => {
  //   if (location.search) {
  //     if (code.trim() !== "") {
  //       Apply();
  //     } else if (code.trim() === "") {
  //       Submit();
  //     }
  //   }
  // }, [code])
  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      Apply();
    }
  };
  useEffect(() => {
    loadCampaignFromUrl();
  }, [])

  useEffect(() => {
    if (!state?.servicePlanExist && state.basketData?.campaign_code?.toLowerCase() === "serviceplan") {
      setCode("")
      if (save) {
        save({
          variables: {
            campaign_code: "",
            ticket_id: state.ticket?.node?.ticket_id || "",
          },
        });
      }
    }
  }, [])
  const loadCampaignFromUrl = async () => {
    // Get the value of the `couponCode` query parameter
    let couponCode;
    if (state?.servicePlanExist) {
      couponCode = "serviceplan"
    } else {
      couponCode = state.queryParams?.coupon_code
    }
    if (couponCode) {
      setCode(couponCode);
      // // console.log('coupon', couponCode)
      // Apply();
      if (couponCode.trim() !== "") {
        loadCampaignById({
          variables: {
            campaign_code: couponCode,
          },
        });
      } else if (couponCode.trim() === "") {
        //setCode("");
        Submit();
      }
    }
  }

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      // if (result?.data?.getCampaignById?.node === null) {
      //   alert(result?.data?.getCampaignById?.message);
      // }
      if (result?.data?.getCampaignById?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


  const closeDialog = (data: any) => {
    if (state.basketData?.campaign_code)
      setCode(state.basketData?.campaign_code);
    close(data);
    setIsError(false);
  };

  const Apply = async () => {
    if (code.trim() !== "") {
      loadCampaignById({
        variables: {
          campaign_code: code,
        },
      });
    } else if (code.trim() === "") {
      setCode("");
      Submit();
    }
  };

  const Submit = () => {
    if (save)
      save({
        variables: {
          campaign_code: result?.data?.getCampaignById?.node?.campaign_code !== "" ? result?.data?.getCampaignById?.node?.campaign_code : "",
          ticket_id: state.ticket?.node?.ticket_id || "",
        },
      });
    close(!open);
  };

  useEffect(() => {
    if (result?.data?.getCampaignById?.status === "SUCCESS") {
      setIsError(false);
      Submit();
    } else if (result?.data?.getCampaignById?.status === "ERROR") {
      setIsError(true);
    } else if (result?.data?.getCampaignById?.status === "NOT_FOUND") {
      setIsError(true);
    }
  }, [result?.data]);

  useEffect(() => {
    if (state.basketData?.campaign_code)
      setCode(state.basketData?.campaign_code);
  }, []);

  return (
    <Dialog
      open={open}
      className={!isMobile ? classes.root : classes.rootMobile}
      onClose={closeDialog}
      fullWidth={true}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E5E5E5",
          paddingBottom: 2,
          paddingTop: 5,
          paddingRight: "1.5%",
          WebkitJustifyContent: "space-between",
          WebkitAlignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            WebkitJustifyContent: "flex-start",
            WebkitAlignItems: "center",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Typography
            fontFamily={"Poppins"}
            fontSize={16}
            fontWeight={600}
            color="#353535"
          >
            Indtast kampagnekode
          </Typography>
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            WebkitJustifyContent: "flex-end",
            WebkitAlignItems: "center",
          }}
        >
          <IconButton onClick={() => closeDialog(!open)}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        style={{
          paddingTop: "15px",
          display: "flex",
          justifyContent: "space-between",
          WebkitJustifyContent: "space-between",
          alignItems: "center",
          WebkitAlignItems: "center",
        }}
      >
        <Grid
          item
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            WebkitJustifyContent: "space-between",
            alignItems: "center",
            WebkitAlignItems: "center",
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <Grid item md={9} maxWidth={"100%"} onKeyDown={handleEnterKeyPress}>
            <InputField
              id={"campaign-field"}
              // style={{ width: !isMobile ? "70%" : "95vw" }}
              focused
              required={false}
              label={t("campaignCode")}
              variant="outlined"
              value={code}
              onChange={(e: InputEvent) => {
                const value = (e.target as HTMLInputElement).value;
                setCode(value);
              }}
            />
            {isError ? (
              <FormHelperText
                id="component-helper-text"
                style={{
                  width: "100%",
                  color: "#d32f2f",
                  display: "flex",
                  flexDirection: "column",
                  WebkitFlexDirection: "column",
                }}
              >
                {t("campaignCodeError")}
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              WebkitJustifyContent: "center",
              WebkitAlignItems: "center",
              paddingBottom: isError ? "7%" : "",
            }}
          >
            <SimpleButton
              style={{
                width: "96px",
                height: "44px",
              }}
              onClick={() => {
                Apply();
              }}
            >
              {t("add")}
            </SimpleButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CampaignCodeDialog;
