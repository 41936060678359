import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
  useStylesDesktopPrePurchaseChecklist,
  useStylesDesktopPrePurchaseCompareTable,
} from "../../../Styles/PrePurchaseStyles";

const PrePurChkListTable10: FC = () => {
  const { t } = useTranslation();
  const classes = useStylesDesktopPrePurchaseChecklist();
  const styles = useStylesDesktopPrePurchaseCompareTable();
  const rows = [
    {
      title: t("table10.table10_1"),
    },
    {
      title: "",
    },
  ];
  return (
    <Grid>
      <TableContainer component={Grid} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead></TableHead>
          <TableBody>
            {rows.map((item) => (
              <TableRow
                key={item.title}
                className={styles.tableRowLastChildBorder}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: item.title.trim() === "" ? "bold" : "normal",
                    display: item.title.trim() == "" ? "flex" : "table-cell",
                    justifyContent:
                      item.title.trim() == "" ? "flex-start" : "inherit",
                    alignItems: item.title.trim() == "" ? "center" : "inherit",
                    padding: "1px",
                    border: "none",
                    fontSize: !isMobile ? "14px" : "10px",
                  }}
                >
                  {item.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PrePurChkListTable10;
