import React, { FC, useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import ActionCard from "../library-components/ActionCard/ActionCard";
import { CardStyleType } from "../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import Header from "./SubComponents/Header";
import Content from "./SubComponents/Content";
import Assistance from "./SubComponents/AssistanceSection";
import TrustBox from "../TrustBox/TrustBox";
import { isMobile } from "react-device-detect";
// import { useHistory } from "react-router-dom";
import { useStylesDesktop } from "./Styles/ZipCodeVehicleStyle";
import { GlobalContext } from "../../context/GlobalContext";

const Vehicle: FC = () => {
  const cardStyle: CardStyleType = {
    header: {
      padding: 0,
    },
    content: {},
    actions: {},
    container: {
      display: "flex",
      flexDirection: "column",
      // maxHeight: "90vh", raf
      width: isMobile ? "90vw" : "",
      // height: "80vh",
    },
    boxHeight: {},
    boxWidth: {
      width: isMobile ? "" : "38.333333333333336vw",
      // height: "81.02564102564102vh",
      // maxHeight: "90vh", , raf
      // height: "80vh",
      display: "flex",
      flexDirection: "column",
    },
  };

  const matches = useMediaQuery("(max-width: 1500px)");
  const matchesMobile = useMediaQuery("(max-width: 350px)");

  // const history = useHistory();

  const { state } = useContext(GlobalContext);

  const classes = useStylesDesktop();

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "auto" });
  // }, [history.location]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Grid className={classes.root}>
      <TrustBox />
      <div className={classes.innerContainer}>
        <ActionCard
          Header={
            <Header
              error={state.returningCustomerError.error}
              matches={matches}
              matchesMobile={matchesMobile}
            />
          }
          Content={<Content />}
          Actions={isMobile ? "" : <Assistance />}
          style={cardStyle}
        />
      </div>
    </Grid>
  );
};

export default Vehicle;
