import { Grid } from "@mui/material";
import React, { FC } from "react";
import BasketMobile from "../../../../Summary/BasketMobile/BasketMobile";
import TrustBox from "../../../../TrustBox/TrustBox";
import { PrePurchaseInspectionsWrapperProps } from "../interfaces/interfaces";
import PrePurchaseInspectionsCompareTable from "../SubComponents/PrePurchaseInspectionsCompareTable";
import PrePurchaseInspectionsCartOptionsTabMobile from "./SubComponents/PrePurchaseInspectionsCartOptionsTabMobile";
import { useStylesPrePurchase } from "../Styles/PrePurchaseStyles";

const PrePurchaseInspectionMobile: FC<PrePurchaseInspectionsWrapperProps> = (
  props
) => {
  const { items } = props;
  const classes = useStylesPrePurchase();

  return (
    <Grid item container className={classes.root}>
      <TrustBox />
      <Grid>
        <PrePurchaseInspectionsCartOptionsTabMobile  items={items} />
        <PrePurchaseInspectionsCompareTable />
      </Grid>
      <BasketMobile />
    </Grid>
  );
};

export default PrePurchaseInspectionMobile;
