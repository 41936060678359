import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import WheelSizeTutorialImg from "../../../../../../assets/illustrations/WheelSizeTutorialImg.svg";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";
import { ComputeServiceDataResponse } from "../../../../../../interfaces-and-unionTypes/Interfaces";
import { computeService } from "../../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { TyreSpectType } from "../../Types/Types";
import { TyreData } from "../../../../../../interfaces-and-unionTypes/UnionTypes";
import { SimpleButton } from "../../../../../library-components/Buttons/Buttons";
import TyreDropdownsMobile from "./TyreDropdownsMobile";
import {
  useStylesTyresSpecs,
  useStylesTyresButtons,
} from "../../Styles/TyresStyles";
import { useHistory } from "react-router-dom";
import ServiceLoaderDialog from "../../../../../library-components/Dialogs/ServiceLoaderDialog";

const TyreSpecsMobile: FC<TyreSpectType> = (props) => {
  const { setShowDropDowns, setShow, serviceId } = props;

  const { t } = useTranslation();

  const { state, dispatch } = useContext(GlobalContext);

  const [numTyres, setNumTyres] = useState(0);
  const [hasError, setHasError] = useState(false);

  const classes = useStylesTyresSpecs();
  const styles = useStylesTyresButtons();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
      setShow(false);
      setShowDropDowns(false);
      dispatch({ type: "TYRES_SELECTED", payload: undefined });
      dispatch({ type: "TYRE_DROPDOWNS_DATA", payload: undefined });
      setLoading(false);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const arr = [
    {
      title: t("widthExplanationTitle"),
      description: t("widthExplanationDescription"),
    },
    {
      title: t("profileExplanationTitle"),
      description: t("profileExplanationDescription"),
    },
    {
      title: t("rimExplanationTitle"),
      description: t("rimExplanationDescription"),
    },
  ];

  const handleAddClick = (serviceId: string, mileage: string) => {
    setLoading(true);
    const tyreData: TyreData = {
      no_back_tyre: state.tyresSelected?.noBackTyres,
      no_front_tyre: state.tyresSelected?.noFrontTyres,
      is_Front_Right: state.tyresSelected?.isFrontRight,
      is_Front_Left: state.tyresSelected?.isFrontLeft,
      is_Back_Right: state.tyresSelected?.isBackRight,
      is_Back_Left: state.tyresSelected?.isBackLeft,
      profile: state.tyreDropdownsData?.profile,
      rim: state.tyreDropdownsData?.rim,
      season: state.tyreDropdownsData?.season,
      tyre_type: state.tyreDropdownsData?.tyreType || "",
      width: state.tyreDropdownsData?.width,
    };
    computedService({
      variables: {
        is_selected: 1,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: mileage,
        tyre_data: tyreData,
      },
    });
  };

  const handleCancelClick = () => {
    setShow(false);
    setShowDropDowns(false);
  };

  useEffect(() => {
    const numBackTyres = state.tyresSelected.noBackTyres;
    const numFrontTyres = state.tyresSelected.noFrontTyres;
    setNumTyres(numBackTyres + numFrontTyres);
  }, [state.tyresSelected]);

  useEffect(() => {
    const numBackTyres = state.tyresSelected.noBackTyres;
    const numFrontTyres = state.tyresSelected.noFrontTyres;
    setNumTyres(numBackTyres + numFrontTyres);
  }, []);

  return (
    <Grid item container md={12} className={classes.root}>
      <Grid item container className={classes.wrapper}>
        <Grid item container className={classes.titleWrapperMobile}>
          <Typography
            fontSize={"11pt"}
            fontWeight={800}
            fontFamily={"Poppins, Bold"}
            className={classes.text}
          >
            {t("tyreDropdownsTitle1") +
              " " +
              numTyres +
              " " +
              t("tyreDropdownsTitle2")}
          </Typography>
        </Grid>
        <Grid item container>
          <TyreDropdownsMobile hasError={hasError} setHasError={setHasError} />
        </Grid>
      </Grid>

      <Grid item container className={classes.buttonsWrapper} md={12}>
        <div className={classes.buttonsPadding}>
          <SimpleButton
            classes={`${styles.cancelButton} ${classes.cancelButton}`}
            onClick={() => {
              handleCancelClick();
            }}
          >
            {t("cancel")}
          </SimpleButton>
        </div>
        <div>
          <SimpleButton
            classes={`${styles.continueButton} ${classes.continueButton}`}
            onClick={() => {
              if (
                state.tyreDropdownsData?.profile !== 0 &&
                state.tyreDropdownsData?.rim != 0 &&
                state.tyreDropdownsData?.width !== 0 &&
                state.tyreDropdownsData?.season?.trim() !== ""
              ) {
                handleAddClick(serviceId, "0");
                setHasError(false);
              } else {
                setHasError(true);
              }
            }}
          >
            {t("add")}
          </SimpleButton>
        </div>
      </Grid>
      <Grid className={classes.findTyreSizeTitleWrapper}>
        <Typography className={classes.text}>
          {t("findTyreSizeTitle")}{" "}
        </Typography>
      </Grid>
      <Grid className={classes.wheelImageWrapper}>
        <img src={WheelSizeTutorialImg} className={classes.wheelImageSize} />
      </Grid>
      <Grid>
        <Typography className={classes.explanationText}>
          {t("findTyreSizeExplanation")}
        </Typography>
      </Grid>
      <Grid item container md={12} className={classes.explanationItemsWrapper}>
        {arr.map((item, index) => (
          <Grid
            key={index}
            item
            container
            md={6}
            className={classes.explanationItemWrapper}
          >
            <Typography
              component={"div"}
              fontSize={16}
              fontFamily={"Poppins"}
              fontWeight={700}
            >
              {item.title}
              <Typography
                component={"p"}
                fontFamily={"Poppins"}
                fontSize={12}
                className={classes.explanationItemsText}
              >
                {item.description}
              </Typography>
            </Typography>
          </Grid>
        ))}
      </Grid>
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </Grid>
  );
};

export default TyreSpecsMobile;
