export const sendEvent = (
  category: string,
  action: string,
  label: string,
  value: string,
  eventName: string
): void => {
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: category,
      action: action,
      label: label,
      value: value,
    },
  });
};
