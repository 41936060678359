import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";

export const useStylesWindshieldTabPanel = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  box: {
    padding: "3vh 0 0 0",
  },
});

export const useStylesWindshield = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: !isMobile ? "0px 0% 0px 3%" : "3% 1% 0px 1%",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: !isMobile ? "center" : "",
    width: !isMobile ? "100%" : "",
  },
  insuranceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: !isMobile ? "2% 4.5% 2% 3%" : "2% 4.5% 5% 3%",
    width: !isMobile ? "100%" : "",
  },
  label: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: !isMobile ? "40%" : "100%",
    marginBottom: !isMobile ? "" : "2%",
  },
  labelTextMobile: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
  },
  formControl: {
    width: !isMobile ? "27vw" : "100%",
  },
  menuItem: {
    height: "36px",
    maxHeight: "auto",
  },
  cardWrapper: {
    background: !isMobile
      ? "#F7F6F4 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box",
    display: "flex",
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    width: !isMobile ? "26vw" : "100%",
    height: "19.3vh",
    flexDirection: "column !important" as "column",
    margin: "2% !important",
    boxShadow: !isMobile ? "" : "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: !isMobile ? "" : "5%",
  },
  infoButton: {
    margin: "0px !important",
    padding: "0px !important",
  },
  infoButtonIcon: {
    color: "#8D88FF",
  },
  cardText: {
    height: !isMobile ? "9vh" : "6vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: !isMobile ? "flex-start" : "center",
    paddingTop: !isMobile ? "2%" : "",
    lineHeight: !isMobile ? "1.2" : "",
  },
  cardPrice: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardPriceTextWeight: {
    fontWeight: "700 !important",
  },
  addButton: {
    background: "#8D88FF",
    color: "white !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  removeButton: {
    background: "#f72c2ca3 !important",
    color: "#ffffff !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
});
