import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    useTheme,
    Typography,
    CircularProgress,
    TextField,
} from "@mui/material";
import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import { useDialogStylesReturningCustomer } from "./Styles/DialogStyle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GlobalContext } from "../../../context/GlobalContext";
import { computeService } from "../../../API/graphql-queries/Queries";
import { ComputeServiceDataResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useLazyQuery } from "react-apollo";
import ServiceLoaderDialog from "./ServiceLoaderDialog";
import { useHistory } from "react-router-dom";
type DialogProps = {
    title?: string;
    open: boolean;
    save: (data: any) => void;
    close: (data: any) => void;
    setAndetSave: (data: any) => void;
    content?: string | any;
    actions?: string[];
    flag?: string;
    andetComment: string;

};
const AndetServiceDialog: FC<DialogProps> = (props) => {
    const classes = useDialogStylesReturningCustomer();
    const { t } = useTranslation();
    const { state, dispatch } = useContext(GlobalContext);
    const history = useHistory();
    // const [andetText, setAndetText] = useState(state?.andetComment)
    const theme = useTheme();
    const [error, setError] = useState("");
    const [serviceId, setServiceId] = useState("")
    const [theSub, setTheSub] = useState()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { open, close, content, save, andetComment, setAndetSave } = props;

    const closeDialog = (data: any) => {
        if (loading) return;
        close(basket);
    };
    const handleClose = () => {
        setAndetSave("save");
        close(false);
    };
    const [basket, setBasket] = useState(state.basketData?.service_details || []);
    const [loading, setLoading] = useState(false);

    const [computedService, result] = useLazyQuery<
        Partial<ComputeServiceDataResponse>
    >(computeService, {
        onCompleted: (data) => {
            dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
            setBasket(data.computeService?.node?.service_details);
            setLoading(false);
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        const foundServiceDetail = content?.service_details?.find(
            (serviceDetail: any) => serviceDetail?.service_dsc === "Andet"
        );
        if (foundServiceDetail) {
            setTheSub(foundServiceDetail)
            setServiceId(foundServiceDetail?.service_id)
        }
    }, [content])

    useEffect(() => {
        if (result?.data === null) {
            alert(result?.error?.message);
        } else {
            if (result?.data?.computeService?.node === null) {
                alert(result?.data?.computeService?.message);
            }
            if (result?.data?.computeService?.message === "Session expired") {
                alert("Session expired");
                history.push("/");
            }
        }
    }, [result?.data]);

    useEffect(() => {
        if (basket.find((el: any) => el.sa_id === state.serviceId)) {
            dispatch({
                type: "REMOVE_SERVICE_FROM_BASKET",
                payload: "",
            });
            const removed = basket.filter((el: any) => el.sa_id !== state.serviceId);
            setBasket(removed);
        }
    }, [state.serviceId, state.basketData]);
    return (
        <Dialog
            open={open}
            className={classes.root}
            onClose={closeDialog}
            fullWidth={fullScreen}
        >
            <DialogTitle
                style={{
                    display: "flex",
                    justifyContent: "center",
                    WebkitJustifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    WebkitAlignContent: "center",
                    WebkitAlignItems: "center",
                }}
            >
                <Typography
                    style={{
                        textAlign: "center",
                    }}
                >
                    {content?.subcategory_dsc}
                </Typography>
            </DialogTitle>
            <DialogContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    fontFamily: "Poppins",
                }}
            >
                {/* !loading */}
                {content?.service_details?.map((sub: any) => {
                    return (
                        <div key={sub.service_dsc} style={{ width: "100%" }}>
                            <Typography
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <TextField
                                    label={"Hvad du vil have et tilbud på?"}
                                    required={true}
                                    value={andetComment}
                                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        const value = e.target.value;
                                        save(value);
                                        dispatch({
                                            type: "ANDET_COMMENT",
                                            payload: value,
                                        });
                                        setError("");
                                    }}
                                    style={{
                                        width: "100%",
                                        marginTop: "1rem",
                                    }}
                                    multiline
                                    rows={2}
                                />
                            </Typography>

                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "10vh",
                    fontFamily: "Poppins",
                }}
            >
                {
                    <LoadingButton
                        style={{
                            width: 155,
                            height: 47,
                            background: "#8D88FF",
                            color: "#F6F6FF",
                            font: "22px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                        }}
                        onClick={handleClose}
                        role={"progressbar"}
                        loading={loading}
                        loadingIndicator={
                            <CircularProgress style={{ color: "white" }} size={32} />
                        }
                    >
                        {!loading && t("continueButtonLabel")}
                    </LoadingButton>
                }
            </DialogActions>
            <ServiceLoaderDialog
                open={loading}
                close={() => {
                    return;
                }}
            />
        </Dialog>
    );
};

export default AndetServiceDialog