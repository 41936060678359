export const ZIP_CODE_CITY = "ZIP_CODE_CITY";
export const TICKET_ID = "TICKET_ID";
export const VEHICLE_CHECKPOINT = "VEHICLE_CHECKPOINT";
export const RETURNING_CUSTOMER = "RETURNING_CUSTOMER";
export const ANDET_COMMENT = "ANDET_COMMENT";
export const CUSTOMER_TYPE = "CUSTOMER_TYPE";
export const CVR = "CVR";
export const LICENCE_PLATE_AUTIG = "LICENCE_PLATE_AUTIG";
export const RETURNING_CUSTOMER_ERROR = "RETURNING_CUSTOMER_ERROR";
export const CAMPAIGNS = "CAMPAIGNS";
export const CLEAR_ZIP_CODE_AND_LICENCE_FORM =
  "CLEAR_ZIP_CODE_AND_LICENCE_FORM";
export const SUBMIT_TICKET_RESPONSE = "SUBMIT_TICKET_RESPONSE";
export const SUBMIT_TICKET_INFORMATIONS = "SUBMIT_TICKET_INFORMATIONS";
export const ERROR_MESSAGE_DIALOG = "ERROR_MESSAGE_DIALOG";
export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";
export const REMOVE_SERVICE_FROM_BASKET = "REMOVE_SERVICE_FROM_BASKET";
export const TYRES_SELECTED = "TYRES_SELECTED";
export const TYRE_DROPDOWNS_DATA = "TYRE_DROPDOWNS_DATA";
export const CONTACT_DETAILS_RESPONSE = "CONTACT_DETAILS_RESPONSE";
export const CONTACT_DETAILS_FROM_URL = "CONTACT_DETAILS_FROM_URL";

export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const CALENDAR_DATE = "CALENDAR_DATE";
export const INSURANCE_COMPANY = "INSURANCE_COMPANY";
export const INSURANCE_COMPANIES = "INSURANCE_COMPANIES";
export const QUERY_PARAMS = "QUERY_PARAMS";
export const QUERY_PHONE_NUMBER = "QUERY_PHONE_NUMBER";
export const QUERY_PHONE_NUMBER_USED = "QUERY_PHONE_NUMBER_USED";

export const GOOGLE_ADS_PARAMS = "GOOGLE_ADS_PARAMS";

//Timeslot
export const FILL_TIMESLOTS = "FILL_TIMESLOTS";
export const SELECT_TIMESLOT = "SELECT_TIMESLOT";
export const UNSELECT_TIMESLOT = "UNSELECT_TIMESLOT";
export const REMOVE_FIRST_ELEMENT = "REMOVE_FIRST_ELEMENT";
export const EMPTY_SELECTED_TIMESLOTS = "EMPTY_SELECTED_TIMESLOTS";
export const SELECTED_DATE = "SELECTED_DATE";
export const ZIP_CODE_CHANGED = "ZIP_CODE_CHANGED";
export const ADDRESS_CHANGED = "ADDRESS_CHANGED";
export const SERVICE_PLAN_EXIST = "SERVICE_PLAN_EXIST";
