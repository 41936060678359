/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { computeService } from "../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { ComputeServiceDataResponse } from "../../../../../interfaces-and-unionTypes/Interfaces";
import { ButtonProps } from "../../../../../interfaces-and-unionTypes/UnionTypes";
import { SimpleButton } from "../../../../library-components/Buttons/Buttons";
import ServiceLoaderDialog from "../../../../library-components/Dialogs/ServiceLoaderDialog";
import { useStylesWindshield } from "../Styles/WindshieldStyles";

const AddRemoveButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  const { serviceId, mileage, defaultActive  } = props;
  const classes = useStylesWindshield();
  const history = useHistory();

  const { state, dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [remove, setRemove] = useState(false);
  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      if (data.computeService?.node !== null)
        dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
      setLoading(false);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  const [basket, setBasket] = useState(state.basketData?.service_details || []);

  const handleAddClick = (serviceId: string, mileage: string) => {
    setLoading(true);
    computedService({
      variables: {
        is_selected: 1,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: mileage,
        tyre_data: null,
        insurance_company: state?.insuranceCompany || "",
      },
    });
    if (result.data?.computeService?.node !== null) {
      setRemove(true);
    }
  };

  const handleRemoveClick = (serviceId: string, mileage: string) => {
    setLoading(true);

    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: mileage,
        tyre_data: null,
      },
    });
    setRemove(false);
    const removed = basket.filter((el: any) => el.sa_id !== serviceId);
    setBasket(removed);
  };
  useEffect(() => {
    if (state.serviceId === serviceId) {
      setRemove(false);
      dispatch({
        type: "REMOVE_SERVICE_FROM_BASKET",
        payload: "",
      });

      const removed = basket.filter((el: any) => el.sa_id !== serviceId);
      setBasket(removed);
    }
  }, [state.serviceId, state.basketData]);

  useEffect(() => {
    if(basket.find((el: any) => el.sa_id === serviceId)) {
      setRemove(true)
    }
  },[])


  return (
    <div>
      {!remove && !basket.find((el: any) => el.sa_id === serviceId) ? (
        <SimpleButton
          onClick={() => handleAddClick(serviceId, mileage)}
          classes={classes.addButton}
        >
          {t("windshiledAddButton")}
        </SimpleButton>
      ) : (
        <SimpleButton
          onClick={() => handleRemoveClick(serviceId, mileage)}
          classes={classes.removeButton}
        >
          {t("windshiledRemoveButton")}
        </SimpleButton>
      )}
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </div>
  );
};

export default AddRemoveButton;
