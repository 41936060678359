import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { isMobile } from "react-device-detect";
import PageNotFound from "../../../assets/illustrations/404NotFound.svg";
import { SimpleButton } from "../Buttons/Buttons";

const PageNotFoundError: FC = () => {
  return (
    <Grid
      item
      container
      md={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "7%",
      }}
    >
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          width: !isMobile ? "60%" : "95%",
        }}
      >
        <Grid
          item
          container
          md={12}
          sx={{
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            width: !isMobile ? "60%" : "95%",
          }}
        >
          <Grid
            item
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5%",
              paddingBottom: "3%",
            }}
          >
            <img src={PageNotFound} />
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "4%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                // variant="h5"
                fontFamily={"Poppins, ExtraBold"}
                fontWeight={1000}
                fontSize={"26px"}
                color="#1A1D1E"
              >
                PAGE NOT FOUND
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: !isMobile ? "" : "72%",
              }}
            >
              <Typography>
                {"Why don't you try one of these pages instead?"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: !isMobile ? "" : "80%",
            }}
          >
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                paddingBottom: "8%",
                width: "100%",
                paddingLeft: !isMobile ? "28%" : "",
                paddingRight: !isMobile ? "28%" : "",
              }}
            >
              <SimpleButton
                style={{
                  flexGrow: 1,
                  fontWeight: "bold",
                  marginRight: !isMobile ? "" : "5%",
                }}
              >
                Go back home
              </SimpleButton>
              <SimpleButton
                style={{
                  flexGrow: 1,
                  border: "1px solid",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Kontakt os
              </SimpleButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageNotFoundError;
