import React, { FC, useContext, useEffect, useState } from "react";
import ActionCard from "../library-components/ActionCard/ActionCard";
import Header from "./SubComponents/Header";
import Content from "./SubComponents/Content";
import Buttons from "./SubComponents/Actions";
import { CardStyleType } from "../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import { Grid } from "@mui/material";
import { useStylesDesktopServices } from "./Style/ServicesStyle";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";

const cardStyle: CardStyleType = {
  header: {
    padding: 0,
  },
  content: undefined,
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0 3% 0",
  },
  container: {
    // maxHeight: "78vh",
  },
  boxHeight: undefined,
  boxWidth: {
    width: "69vw",
  },
};

const Services: FC = () => {
  const classes = useStylesDesktopServices();
  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");
  const [isExternal, setExternal] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    if (state?.servicePlanExist) {
      // dispatch({
      //   type: "C",
      //   payload: "serviceplan"
      // })
    }
  }, [])
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <Grid item container md={12} className={classes.root}>
      <ActionCard
        Header={<Header isExternal={isExternal} />}
        Content={<Content isExternal={isExternal} setExternal={setExternal} />}
        Actions={<Buttons />}
        style={cardStyle}
      />
    </Grid>
  );
};

export default Services;
