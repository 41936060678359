import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const DetailsContentTechnical: FC = () => {
  const { state } = useContext(GlobalContext);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const classes = useStylesDesktopSummaryCheckout();

  useEffect(() => {
    setFullName(state.ticketInformationsData?.fullName || "");
    setEmail(state.ticketInformationsData?.email || "");
    setComment(state.ticketInformationsData?.comment || "");
    setPhoneNumber(state.ticketInformationsData?.number || "");
  }, []);

  return (
    <Grid className={classes.cardRoot}>
      <Grid className={classes.cardWrapper}>
        <Typography className={classes.cardTitleText}>
          {"Kontaktoplysninger"}
        </Typography>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Fulde navn</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Email</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Telefonnummer</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {"+45 " + phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Hvordan kan vi hjælpe dig?</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {comment}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContentTechnical;
