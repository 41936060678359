import { Grid } from "@mui/material";
import React, { FC } from "react";
import WindshieldTabPanel from "../WindshieldTabPanel";
import WinshieldGlassTab from "../SubComponents/WindshieldGlassTab";
import { WindshieldWrapperProps } from "../interfaces/interfaces";

const WindshieldWrapper: FC<WindshieldWrapperProps> = (props) => {
  const { value } = props;

  return (
    <Grid>
      <WindshieldTabPanel index={4} value={value}>
        <>
          <WinshieldGlassTab />
        </>
      </WindshieldTabPanel>
    </Grid>
  );
};

export default WindshieldWrapper;
