export const widths = [
  {
    width: 140,
  },
  {
    width: 145,
  },
  {
    width: 150,
  },
  {
    width: 155,
  },
  {
    width: 160,
  },
  {
    width: 165,
  },
  {
    width: 170,
  },
  {
    width: 175,
  },
  {
    width: 180,
  },
  {
    width: 185,
  },
  {
    width: 190,
  },
  {
    width: 195,
  },
  {
    width: 200,
  },
  {
    width: 205,
  },
  {
    width: 210,
  },
  {
    width: 215,
  },
  {
    width: 220,
  },
  {
    width: 225,
  },
  {
    width: 230,
  },
  {
    width: 235,
  },
  {
    width: 240,
  },
  {
    width: 245,
  },
  {
    width: 250,
  },
  {
    width: 255,
  },
  {
    width: 260,
  },
  {
    width: 265,
  },
  {
    width: 270,
  },
  {
    width: 275,
  },
  {
    width: 280,
  },
  {
    width: 285,
  },
  {
    width: 290,
  },
  {
    width: 295,
  },
  {
    width: 300,
  },
];

export const profiles = [
  {
    profile: 30,
  },
  {
    profile: 35,
  },
  {
    profile: 40,
  },
  {
    profile: 45,
  },
  {
    profile: 50,
  },
  {
    profile: 55,
  },
  {
    profile: 60,
  },
  {
    profile: 65,
  },
  {
    profile: 70,
  },
  {
    profile: 75,
  },
  {
    profile: 80,
  },
  {
    profile: 85,
  },
];

export const rims = [
  {
    rim: 13,
  },
  {
    rim: 14,
  },
  {
    rim: 15,
  },
  {
    rim: 16,
  },
  {
    rim: 17,
  },
  {
    rim: 18,
  },
  {
    rim: 19,
  },
  {
    rim: 20,
  },
  {
    rim: 21,
  },
  {
    rim: 22,
  },
];

export const tyreItems = [
  {
    title: "Wheel change",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1092",
    important: false,
  },
  {
    title: "Tyre change (new tyres)",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "55780",
    important: true,
  },
  {
    title: "Tyre change",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "646",
    important: false,
  },
  {
    title: "Tyre change 2",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "647000",
    important: false,
  },
];

export const windshieldItems = [
  {
    title: "Windscreen replacement (front)",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1092",
  },
  {
    title: "Windscreen replacement (rear)",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "780",
  },
  {
    title: "Windscreen replacement (side)",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "646",
  },
  {
    title: "Stone chip repair",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "780",
  },
];

export const prePurchaseInspectionsItems = [
  {
    title: "Basic Pre-purchase Inspection",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "646",
  },
  {
    title: "Standard Pre-purchase Inspection",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "780",
  },
  {
    title: "Premium Pre-purchase Inspection",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1 092",
  },
];

export const servicingAndMOTItems = [
  {
    title: "MOT with collection & delivery",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "646",
  },
  {
    title: "Major Service",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "780",
  },
  {
    title: "Interim Service",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1 092",
  },
  {
    title: "Major Service - Electrical cars",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1 092",
  },
  {
    title: "Interim Service - Electrical cars",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1 092",
  },
  {
    title: "Full Service",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed...",
    price: "1 092",
  },
];
