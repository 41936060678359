import { Grid, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { InputEvent } from "../../../../Types/Types";
import { LeaveNoteProps } from "../../Types/LeaveNoteTypes";

const LeaveNoteInput: FC<LeaveNoteProps> = (props) => {
  const { note, setNote } = props;

  const [noteToMechanic, setNoteToMechanic] = useState("");

  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      md={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        style={{
          width: !isMobile ? "94%" : "100%",
        }}
        multiline
        value={note}
        label={t("mechanicNoteInputLabel")}
        placeholder={t("mechanicNotePlaceholder")}
        focused={!isMobile ? true : undefined}
        // inputProps={{ maxLength: 500 }}
        required={false}
        onChange={(event: InputEvent) => {
          setNoteToMechanic(event.target.value);
          setNote(event.target.value);
        }}
      />
    </Grid>
  );
};

export default LeaveNoteInput;
