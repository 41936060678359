import { useEffect } from "react";

import { useLocation } from "react-router";

// unused, bias code

export default function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
