import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { LinkButton } from "../../../../library-components/Buttons/Buttons";
import { TechnicalAssistanceDialog } from "../../../../library-components/Dialogs/TechincalAssistance/TechnicalAssistanceDialog";
import Repairs from "../Repairs";
import { useStylesDesktopRepairs } from "../Styles/RepairsStyles";
type RepairsWrapperPropType = {
  value: number;
};
const RepairsWrapper: FC<RepairsWrapperPropType> = (props) => {
  const { value } = props;
  const { dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStylesDesktopRepairs();

  return (
    <Grid>
      <Repairs value={value} index={0}></Repairs>
      <TechnicalAssistanceDialog
        open={openDialog}
        close={(value) => {
          if (value === true) {
            dispatch({
              type: "CLEAR_ZIP_CODE_AND_LICENCE_FORM",
              payload: undefined,
            });
          }
          setOpenDialog(!openDialog);
        }}
        flag={"S"}
      />
    </Grid>
  );
};

export default RepairsWrapper;
