/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useState } from "react";
import { EmailForm, InputEvent } from "../../Types/Types";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";
import InputField from "../../library-components/InputFields/InputFields";
import { useTranslation } from "react-i18next";
import { Box, FormControl, FormHelperText } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

const ContentEmail: FC<EmailForm> = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { emailError, emailRef, setEmail, email, setEmailError } = props;

  const classes = useStylesDesktop();

  const history = useHistory();

  useEffect(() => {
    setEmail(state.returningCustomer?.node?.email || "");
  }, [state.returningCustomer?.node]);

  useEffect(() => {
    setEmailError(emailError);
    setEmail(state.returningCustomer?.node?.email || "");
  }, []);

  // useEffect(() => {
  //     setEmailError(emailError);
  // }, [emailError]);

  useEffect(() => {
    setEmailError("");
  }, [state.returningCustomer?.node]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ width: !isMobile ? "28vw" : "80vw" }}
    >
      <FormControl fullWidth className={classes.formControl}>
        <InputField
          inputRef={emailRef}
          id="standard-basic"
          error={emailError ? true : false}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={(e: InputEvent) => {
            setEmail(e.target.value);
          }}
          classesProp={classes.textField}
          required={false}
          variant="outlined"
          label={t("emailDin")}
          value={email}
          placeholder={t("insertEmail")}
        />
        {emailError && (
          <FormHelperText
            id="component-helper-text"
            className={classes.formControlHelperTextZipCodeRequiredError}
            classes={{ root: classes.formControlHelperTextRoot }}
          >
            {emailError + " "}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ContentEmail;
