import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useLazyQuery } from "react-apollo";
import { ComputeServiceDataResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { computeService } from "../../../API/graphql-queries/Queries";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { t } from "i18next";
import { isAFullDiscountApplied } from "../../../services/price.service";

const Basket2: React.FC = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) =>
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node }),
    fetchPolicy: "no-cache",
  });

  const handleRemoveClick = (serviceId?: string) => {
    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: "0",
        tyre_data: null,
      },
    });
    dispatch({
      type: "REMOVE_FROM_BASKET",
      payload: { serviceId, ...result?.data?.computeService?.node },
    });

    dispatch({
      type: "REMOVE_SERVICE_FROM_BASKET",
      payload: serviceId,
    });
  };

  const isCheckoutPage = window.location.href.includes("checkout");

  const basketItems = state.basketData?.service_details?.map((item: any) => {
    return (
      <div className="basket-items--item" key={item.sa_id}>
        {!isCheckoutPage && (
          <CancelOutlinedIcon
            onClick={() => {
              handleRemoveClick(item.sa_id);
            }}
          />
        )}
        <div className="basket-items--details">
          <p>{item.sa_dsc}</p>
          { item.sa_missing ?
            <p className="basket-items--details-price">Tilbud</p> :
            !isAFullDiscountApplied(state) && <p className="basket-items--details-price">{Number(item.sa_price).toLocaleString('da-dk')} DKK</p>
          }
        </div>
      </div>
    );
  });

  const driversFee = (
    <div className="basket-items--details">
      <p>{t("additionalFeesContactDetails3")}</p>
      <p>{ !isAFullDiscountApplied(state) && Math.round(parseInt(state.basketData?.fee_amount))} DKK</p>
    </div>
  );
  return (
    <>
      <div className="basket-items">
        <div>{basketItems}</div>
      {isCheckoutPage && driversFee}
      </div>
    </>
  );
};

export default Basket2;
