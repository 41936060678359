import React, { FC, useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  profiles,
  rims,
  widths,
} from "../../SubComponents/placeholderMockups/mockup";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { useStylesTyresDropdowns } from "../../Styles/TyresStyles";

export type TyreDropdownsType = {
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
};

const TyreDropdowns: FC<TyreDropdownsType> = (props) => {
  const { dispatch } = useContext(GlobalContext);

  const { hasError, setHasError } = props;

  const [width, setWidth] = useState(0);
  const [profile, setProfile] = useState(0);
  const [rim, setRim] = useState(0);
  const [tyreType, setTyreType] = useState("");
  const [season, setSeason] = useState("");

  const classes = useStylesTyresDropdowns();
  const { t } = useTranslation();

  const seasons = [
    t("seasonSummerOption"),
    t("seasonWinterOption"),
    t("seasonAllSeasonOption"),
  ];

  const tyreTypes = [t("standardTypeOption"), t("premiumTypeOption")];

  const forms = [
    {
      title: t("widthLabel"),
      values: widths,
    },
    {
      title: t("profileLabel"),
      values: profiles,
    },
    {
      title: t("rimsLabel"),
      values: rims,
    },
    {
      title: t("seasonLabel"),
      values: seasons,
    },
    {
      title: t("tyreTypeLabel"),
      values: tyreTypes,
    },
  ];

  const handleChange = (form: string, event: SelectChangeEvent) => {
    if (form.trim() === t("widthLabel")) {
      setWidth(parseInt(event.target.value));
    }
    if (form.trim() === t("profileLabel")) {
      setProfile(parseInt(event.target.value));
    }
    if (form.trim() === t("rimsLabel")) {
      setRim(parseInt(event.target.value));
    }
    if (form.trim() === t("seasonLabel")) {
      setSeason(event.target.value);
    }
    if (form.trim() === t("tyreTypeLabel").trim()) {
      setTyreType(event.target.value);
    }
  };

  useEffect(() => {
    dispatch({
      type: "TYRE_DROPDOWNS_DATA",
      payload: {
        width: width,
        profile: profile,
        rim: rim,
        season: season,
        tyreType: tyreType,
      },
    });
  }, [tyreType, width, rim, season, profile]);

  return (
    <Grid minWidth={120} item container md={12} className={classes.root}>
      {forms.map((selectForm) => {
        return (
          <Grid
            key={selectForm.title}
            item
            container
            md={4}
            className={classes.wrapper}
          >
            <FormControl fullWidth key={selectForm.title} variant="outlined">
              <InputLabel id={`${selectForm.title}-select-label`}>
                {selectForm.title}
              </InputLabel>
              <Select
                labelId={`${selectForm.title}-select-label`}
                id={`${selectForm.title}-select`}
                label={selectForm.title}
                variant="outlined"
                fullWidth={true}
                onChange={(event: SelectChangeEvent) => {
                  handleChange(selectForm.title, event);
                  setHasError(false);
                }}
                required={
                  selectForm.title.trim() === t("tyreTypeLabel").trim()
                    ? false
                    : true
                }
                inputProps={{ "aria-label": "controlled" }}
                error={
                  selectForm.title.trim() === t("tyreTypeLabel").trim()
                    ? false
                    : hasError
                }
              >
                <MenuItem
                  key={selectForm.title}
                  defaultValue={""}
                  className={classes.menuItem}
                ></MenuItem>
                {selectForm.title.trim() === "Bredde" &&
                  widths.map((x) => {
                    return (
                      <MenuItem key={x.width} value={x.width.toString()}>
                        {x.width.toString()}
                      </MenuItem>
                    );
                  })}
                {selectForm.title.trim() === "Profil" &&
                  profiles.map((x) => {
                    return (
                      <MenuItem key={x.profile} value={x.profile.toString()}>
                        {x.profile.toString()}
                      </MenuItem>
                    );
                  })}
                {selectForm.title.trim() === "Fælgstørrelse" &&
                  rims.map((x) => {
                    return (
                      <MenuItem key={x.rim} value={x.rim}>
                        {x.rim.toString()}
                      </MenuItem>
                    );
                  })}
                {selectForm.title.trim() === "Sæson" &&
                  seasons.map((x, index) => {
                    return (
                      <MenuItem key={index} value={x.toString()}>
                        {x.toString()}
                      </MenuItem>
                    );
                  })}
                {selectForm.title.trim() === t("tyreTypeLabel").trim() &&
                  tyreTypes.map((x, index) => {
                    return (
                      <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        );
      })}
      <div className={classes.errorWrapper}>
        {hasError ? (
          <FormHelperText className={classes.errorText}>
            {t("dropdownsError")}
          </FormHelperText>
        ) : null}
      </div>
    </Grid>
  );
};

export default TyreDropdowns;
