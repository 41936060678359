import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const VehicleContent: FC = () => {
  const { state } = useContext(GlobalContext);

  const [licencePlate, setLicencePlate] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const classes = useStylesDesktopSummaryCheckout();

  useEffect(() => {
    setLicencePlate(
      state.licence?.node?.licence_plate ||
        state.returningCustomer?.node?.licence_plate ||
        ""
    );
    setYear(state.ticket?.node?.year || "");
    setMake(state.ticket?.node?.make || "");
    setModel(state.ticket?.node?.model || "");
  }, []);

  return (
    <Grid className={classes.cardRoot}>
      <Grid className={classes.cardWrapper}>
        <Typography className={classes.cardTitleText}>{"Bil"}</Typography>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Registreringsnummer</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {licencePlate}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Årgang</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {year}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Mærke</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {make}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.cardItemRoot}>
        <Grid className={classes.cardItemWrapper}>
          <Typography>Model</Typography>
          <Grid className={classes.cardItemValueWrapper}>
            <Typography className={classes.cardItemValueText}>
              {model}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleContent;
