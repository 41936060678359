import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  LinkButton,
  SimpleButton,
} from "../../../../library-components/Buttons/Buttons";
import InfoDialog from "../../../../library-components/Dialogs/InfoDialog/InfoDialog";
import ServiceInclusionChecklist from "./ChecklistComponent/ServiceInclusionChecklist";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { useStylesDesktopServicingTables } from "../Styles/ServicingStyles";

const ServicingAndMotTables: FC = () => {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const motortype = state.ticket?.node?.motortype;
  const classes = useStylesDesktopServicingTables();

  const [open, setOpen] = useState(false);

  const rowsCompare = [
    // {
    //   tag: "L",
    //   title: t("table1-servicing.noChecks"),
    //   link_basic: t("table1-servicing.basicNoChecks"),
    //   link_guarantee: t("table1-servicing.guaranteeNoChecks"),
    //   link_el: t("table1-servicing.elNoChecks"),
    // },
    {
      tag: "HB",
      title: t("table4-servicing.ourUniqueServices"),
    },
    {
      tag: "FD",
      title: t("table4-servicing.brakeClean"),
      basic: false,
      guarantee: false,
      el: true,
      star: "*",
    },
    {
      tag: "F",
      title: "Gennemgang af bilens tilstand",
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      tag: "F",
      title: t("table4-servicing.diagnisticInspection"),
      basic: true,
      guarantee: true,
      el: true,
    },
    // {
    //   tag: "HP",
    //   priceBasic: t("table4-servicing.priceBasic"),
    //   priceGuarantee: t("table4-servicing.priceGuarantee"),
    //   priceEl: t("table4-servicing.priceBasic"),
    // },
    {
      tag: "HLB",
      title: t("table2-servicing.partsIncludedTitleBold"),
      continued: (
        <span style={{ fontWeight: "normal" }}>
          {t("table2-servicing.partsIncludedTitle")}
        </span>
      ),
    },
    // {
    //   tag: "CL",
    //   titlee: t("table2-servicing.partsIncludedTitle"),
    // },
    {
      tag: "F",
      title: t("table2-servicing.engineOil"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      tag: "F",
      title: t("table2-servicing.oilFilter"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      tag: "F",
      title: t("table2-servicing.airFilter"),
      basic: false,
      guarantee: true,
      el: false,
      star: "*",
    },
    {
      tag: "F",
      title: t("table2-servicing.pollenFilter"),
      basic: false,
      guarantee: true,
      el: true,
      star: "*",
    },
    {
      tag: "F",
      title: t("table2-servicing.sparkPlugs"),
      basic: false,
      guarantee: true,
      el: false,
      star: "*",
    },
    {
      tag: "F",
      title: t("table2-servicing.fuelFilter"),
      basic: false,
      guarantee: true,
      el: false,
      star: "*",
    },
    {
      tag: "H",
      title: t("table3-servicing.topUpsIncludedTitle"),
    },
    {
      tag: "F",
      title: t("table3-servicing.screenWash"),
      basic: true,
      guarantee: true,
      el: true,
    },
    {
      tag: "F",
      title: t("table3-servicing.antifreeze"),
      basic: true,
      guarantee: true,
      el: false,
    },
    {
      tag: "F",
      title: t("table3-servicing.brakeFluid"),
      basic: false,
      guarantee: true,
      el: true,
      star: "*",
    },
    {
      tag: "F",
      title: t("table3-servicing.boxOil"),
      basic: false,
      guarantee: true,
      el: false,
      star: "*",
    },
  ];

  return (
    <Grid className={classes.root}>
      <TableContainer component={Grid}>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHeadCells}>
                {t("table1-servicing.shortServicelist")}
              </TableCell>
              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={classes.tableHeadCells}>
                  {!isMobile
                    ? t("table1-servicing.basic")
                    : t("table1-servicing.basic")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() !== "el" ? (
                <TableCell align="center" className={classes.tableHeadCells}>
                  {!isMobile
                    ? t("table1-servicing.guarantee")
                    : t("table1-servicing.guaranteeMobile")}
                </TableCell>
              ) : (
                ""
              )}

              {motortype?.toLowerCase() == "el" ? (
                <TableCell align="center" className={classes.tableHeadCells}>
                  {t("table1-servicing.el")}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsCompare.map((item, index) => (
              <React.Fragment key={index}>
                {(motortype?.toLowerCase() === "el" && item.el === true) ||
                  item.tag.includes("H") ||
                  // item.tag.includes("L") ||
                  (motortype?.toLowerCase() !== "el" && item.tag !== "FD") ? (
                  <TableRow
                    key={index}
                    className={classes.tableRowLastChildBorder}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: item.tag.includes("H") ? "bold" : "normal",
                        display: item.tag.includes("H")
                          ? item?.continued
                            ? "block"
                            : "flex"
                          : "table-cell",
                        justifyContent: item.tag.includes("H")
                          ? "flex-start"
                          : "inherit",
                        alignItems: item.tag.includes("H")
                          ? "flex-end"
                          : "inherit",
                        fontSize: item.tag.includes("H")
                          ? !isMobile
                            ? "15px"
                            : "12px"
                          : !isMobile
                            ? "14px"
                            : "10px",

                        height: item.tag == "H" ? "60px" : "",
                        width:
                          item.tag == "HLB" ? (isMobile ? "81vw" : "48vw") : "", 
                        marginTop: item.tag == "HB" && item.title==="Vores unikke tjenester" ? "40px" : item?.continued
                          ? isMobile
                            ? "20px"
                            : "40px"
                          : "",
                      }}
                    >
                      {item.title}
                      {item?.continued}
                      {/* {item.tag === "L" && (
                        <>
                          <br />
                          <LinkButton
                            classes={classes.checklistLinkSize}
                            onClick={() => setOpen(true)}
                          >
                            {t("table1-servicing.viewChecklist")}
                          </LinkButton>
                        </>
                      )} */}
                    </TableCell>

                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: item.tag.includes("H")
                            ? "bold"
                            : "normal",
                          fontSize: item.tag.includes("H")
                            ? !isMobile
                              ? "15px"
                              : "12px"
                            : !isMobile
                              ? "14px"
                              : "10px",
                        }}
                      >
                        {item.basic ? (
                          <DoneIcon className={classes.doneIcon} />
                        ) : item.basic == false ? (
                          <CloseIcon className={classes.closeIcon} />
                        ) :
                          // item.link_basic ? (
                          //   item.link_basic
                          // ) : 
                          (
                            ""
                          )}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {motortype?.toLowerCase() !== "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: item.tag.includes("H")
                            ? "bold"
                            : "normal",
                          fontSize: item.tag.includes("H")
                            ? !isMobile
                              ? "15px"
                              : "12px"
                            : !isMobile
                              ? "14px"
                              : "10px",
                        }}
                      >
                        {item.guarantee ? (
                          <DoneIcon className={classes.doneIcon} />
                        ) : item.guarantee == false ? (
                          <CloseIcon className={classes.closeIcon} />
                        ) :
                          // item.link_guarantee ? (
                          //   item.link_guarantee
                          // ) : 
                          (
                            ""
                          )}
                        {item?.star ? (
                          <span
                            style={{
                              position: "absolute",
                              fontSize: "18px",
                            }}
                          >
                            {item?.star}
                          </span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {motortype?.toLowerCase() == "el" ? (
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: item.tag.includes("H")
                            ? "bold"
                            : "normal",
                          fontSize: item.tag.includes("H")
                            ? !isMobile
                              ? "15px"
                              : "12px"
                            : !isMobile
                              ? "14px"
                              : "10px",
                        }}
                      >
                        {item.el ? (
                          <DoneIcon className={classes.doneIcon} />
                        ) : item.el == false ? (
                          <CloseIcon className={classes.closeIcon} />
                        ) :
                          // item.link_el ? (
                          //   item.link_el
                          // ) : 
                          (
                            ""
                          )}
                        {item?.star ? (
                          <span
                            style={{
                              position: "absolute",
                              fontSize: "18px",
                            }}
                          >
                            {item?.star}
                          </span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ) : null}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item container md={12} className={classes.note}>
        <Typography
          fontFamily={"Poppins, Regular"}
          fontSize={14}
          color="#353535"
          textAlign={"left"}
        >
          {t("table4-servicing.onlyAtServiceTime")}
        </Typography>
      </Grid>
      <InfoDialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        title={t("dialogTitleServicing")}
        content={<ServiceInclusionChecklist />}
        actions={
          <SimpleButton
            onClick={() => setOpen(false)}
            classes={classes.checklistCloseButton}
          >
            <Typography className={classes.checklistCloseButtonText}>
              {t("closeButtonDialog")}
            </Typography>
          </SimpleButton>
        }
        actionsStyle={classes.checklistCloseButtonWrapper}
      ></InfoDialog>
    </Grid>
  );
};

export default ServicingAndMotTables;
