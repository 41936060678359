import React from "react";
import { Stack, Alert } from "@mui/material";

export const MuiAlert = () => {
  return (
    <Stack spacing={2}>
      <Alert severity="info">
        Bemærk venligst, at prisen er ændret pga. ændring af postnummer.
      </Alert>
    </Stack>
  );
};
