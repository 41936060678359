import { makeStyles } from "@mui/styles";

export const useStylesDesktopContent = makeStyles({
  wrapperFlex: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  root: {
    padding: "1% 4% 1% 4%",
  },
  wrapper: {
    flexDirection: "row",
  },
  button: {
    width: "12vw",
    height: "19vh",
    flexDirection: "column",
  },
  imageWrapper: {
    alignItems: "flex-start !important",
  },
  image: {
    height: 30,
    width: 30,
    paddingBottom: "3px",
    paddingTop: "1%",
  },
  cardText: {
    alignItems: "baseline !important",
    padding: 0,
    textTransform: "capitalize",
    flexGrow: 1,
  },
});
