import React, { FC, useEffect, useState } from "react";
import ActionCard from "../library-components/ActionCard/ActionCard";
import { CardStyleType } from "../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import { Grid } from "@mui/material";
import { useStylesDesktopServices } from "./Style/ServicesStyle";
import ServicesClusterTabsWrapper from "./ServicesClusterTabsWrapper";
import { useHistory } from "react-router-dom";

const cardStyle: CardStyleType = {
  header: {
    padding: 0,
  },
  content: undefined,
  actions: {},
  container: undefined,
  boxHeight: undefined,
  boxWidth: {
    width: "65vw",
  },
};

const ServicesCluster: FC = () => {
  const classes = useStylesDesktopServices();

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"});
  }, [history.location, currLoc]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"});
  }, []);

  return (
      <Grid item container md={7} className={classes.rootCluster}>
        <ActionCard
            Header={null}
            Content={<ServicesClusterTabsWrapper/>}
            Actions={null}
            style={cardStyle}
        />
      </Grid>
  );
};

export default ServicesCluster;
