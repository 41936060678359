import React, { useEffect, useState } from "react";
declare global {
  interface Window {
    Trustpilot: any;
  }
}
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      style={{
        marginTop: "20px",
        // marginBottom: "15px",
      }}
    >
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="da-DK"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="5f72d110bc819a00018e9a45"
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-text-color="#000000"
        style={{ width: "100vw" }}
      >
        <a
          href="https://dk.trustpilot.com/review/omnicarservice.dk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
export default TrustBox;
