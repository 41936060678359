/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from "react";
import "./App.css";
import Routes from "../../Routes/Router";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../Styles/mui-theme";
import ErrorDialog from "../library-components/Dialogs/ErrorDialog.tsx/ErrorDialog";
import { GlobalContext } from "../../context/GlobalContext";
import { useStyles } from "./styles";
import "../../i18n";
import ReactGA from "react-ga";
ReactGA.initialize("UA-98802810-4");

const App: React.FC<any> = () => {
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        {
          (window.ononline = () => {
            dispatch({
              type: "ERROR_MESSAGE_DIALOG",
              payload: { message: "Du er online igen.", showDialog: false },
            });
          })
        }
        {
          (window.onoffline = () => {
            dispatch({
              type: "ERROR_MESSAGE_DIALOG",
              payload: {
                message: "Det ser ud til, at du er offline.",
                showDialog: true,
              },
            });
          })
        }
        <Routes />
        <ErrorDialog />
      </div>
    </ThemeProvider>
  );
};

export default App;
