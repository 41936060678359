import { Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import DetailsContentTechnicalMobile from "./SubComponents/DetailsContentTechnicalMobile";
import HeaderTechnicalMobile from "./SubComponents/HeaderTechnicalMobile";
import { useStylesMobileSummaryCheckout } from "../Styles/SummaryCheckoutStyles";

const SummaryCheckoutTechnicalMobile: FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const classes = useStylesMobileSummaryCheckout();

  useEffect(() => {
    scrollToTop();
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <Grid item container className={classes.root}>
      <Grid item container className={classes.headerRoot}>
        <HeaderTechnicalMobile />
      </Grid>
      <Grid item container className={classes.contentRoot}>
        <Grid item container>
          <Grid item container className={classes.contentWrapper}>
            <DetailsContentTechnicalMobile />
          </Grid>
          <Grid className={classes.actionsRoot}>
            <Typography fontSize={12}>
              {"Gå til "}
              <LinkButton
                onClick={() => {
                  scrollToTop();
                  window.location.reload();
                }}
                classes={classes.link}
              >
                {"hjemmeside"}
              </LinkButton>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryCheckoutTechnicalMobile;
