import React from 'react';
import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles({
    root: {
        display: "flex",
        fontFamily: "Poppins",
        justifyContent: "center",
        alignItems: "center",
        WebkitJustifyContent: "center",
        WebkitAlignContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
});
