import { makeStyles } from "@mui/styles";

export const useStylesDesktopServices = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    detailsBottomPadding: {
        paddingBottom: "1%",
    }
});
