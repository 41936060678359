import { createContext } from "react";
import { AllActions } from "./Actions";
import { StateType } from "./Types";


const values = {
  state: {
    cvr:"",
    customerType:"private",
    coupon_url: "",
    zip_code: "",
    userLocation: {
      message: "",
      node: {
        city: "",
        country: "",
        price_ind: "",
        zip_code: "",
      },
      status: "",
    },
    ticket: {
      message: "",
      node: {
        city: "",
        country: "",
        make: "",
        model: "",
        ticket_id: "",
        year: "",
        zip_code: "",
      },
      status: "",
    },
    tyreDropdownsData: {
      width: 0,
      profile: 0,
      rim: 0,
      season: "",
      tyreType: "",
    },
    vehicleCheckpoint: {
      IN_PROGRESS: false,
      COMPLETED: false,
      name: "",
    },
    licence: {
      message: "",
      node: {
        year: "",
        make: "",
        model: "",
        autig_id: "",
        vehicle_id: "",
        engine_type: "",
        engine_code: "",
        licence_plate: "",
        gasoline_diesel: "",
        vin_number: "",
      },
      status: "",
    },
    tyresSelected: {
      noFrontTyres: 0,
      noBackTyres: 0,
      isBackLeft: false,
      isBackRight: false,
      isFrontLeft: false,
      isFrontRight: false,
    },
    returningCustomer: {
      message: "",
      node: {
        city: "",
        licence_plate: "",
        ticket_id: "",
        zip_code: "",
        email: "",
      },
      status: "",
    },
    returningCustomerError: {
      error: "",
    },
    clearVehicleForm: false,
    submitTicketRes: {},
    errorMessageDialog: {
      message: "",
      showDialog: false,
    },
    basketData: {},
    serviceId: "",
    contactDetails: {
      message: "",
      status: "",
      node: {
        address: "",
        address2: "",
        email: "",
        full_name: "",
        phone: "",
      },
    },
    contactDetailsInfo: {
      fullName: "",
      email: "",
      strAddress1: "",
      strAddress2: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
    },
    calendarDate: {
      day: 0,
      month: 0,
      year: 0,
    },
    insuranceCompany: "",
    googleAdsParams: {
      gclid: "",
      utm_campaign: "",
      utm_medium: "",
      utm_source: "",
    },
    timeslot: {},
    andetComment:"",
    slots: [{}],
    selectedTimeslot: {},
    selectedTimeslots: [{}],
    datePickerChoice: null,
    zipCodeChanged: "",
    addressChanged: "",
    servicePlanExist: false,
    // insuranceCompanies: [{}]
  } as StateType,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: (action: AllActions) => { },

};


export const GlobalContext = createContext(values);
GlobalContext.displayName = "GlobalContext";
