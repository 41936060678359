import React from "react";
import { Card, CardActions, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CardProps } from "./PropTypesInterface/ActionCardPropTypeInterface";
import { Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

const useStyles: any = makeStyles({
  card: {},
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardShadow: {},
});

const ActionCard: React.FC<CardProps> = (props) => {
  const { Header, Content, Actions, style } = props;

  const classes = useStyles();

  return (
    <Box
      sx={{
        boxShadow: style?.boxShadow
          ? style?.boxShadow
          : "0 8px 40px -12px rgba(0,0,0,0.3)",
        height: style?.boxHeight,
        width: style?.boxWidth,
      }}
    >
      <Card variant={"outlined"} style={style?.container}>
        <CardHeader
          title={Header}
          style={style?.header}
          className={classes.header}
        />
        <CardContent style={style?.content}>{Content}</CardContent>
        <CardActions style={style?.actions}>{Actions}</CardActions>
      </Card>
    </Box>
  );
};

export default ActionCard;
