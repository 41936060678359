import AppSync from "../../AppSyncConfig/AppSync"

const ApolloConfig = {
  uri: AppSync.ApiUri,
  headers: {
    "X-Api-Key": AppSync.ApiKey
  },
  // cache: new InMemoryCache(),
  name: 'Omnicar',
  version: "1.0",
}
export default ApolloConfig;