import gql from "graphql-tag";

export const getAutigData = gql`
  query getAutigData($licence_plate: String!) {
    getAutigData(licence_plate: $licence_plate) {
      message
      node {
        year
        make
        model
        autig_id
        vehicle_id
        engine_type
        engine_code
        licence_plate
        gasoline_diesel
        vin_number
      }
      status
    }
  }
`;

export const getLocationById = gql`
  query getLocationById($zip_code: String!) {
    getLocationById(zip_code: $zip_code) {
      message
      node {
        city
        country
        price_ind
        zip_code
      }
      status
    }
  }
`;

export const updateTicketLocationVehicle = gql`
  mutation updateTicketLocationVehicle(
    $ticket_id: String!
    $licence_plate: String!
    $zip_code: String!
  ) {
    updateTicketLocationVehicle(
      ticket_id: $ticket_id
      ticketLocationVehicle: {
        zip_code: $zip_code
        licence_plate: $licence_plate
      }
    ) {
      message
      node {
        city
        country
        make
        model
        ticket_id
        year
        zip_code
        motortype
        delete_basket
      }
      status
    }
  }
`;

export const updateTicketReturningCustomer = gql`
  mutation updateTicketReturningCustomer(
    $ticket_id: String!
    $phone_number: String!
  ) {
    updateTicketReturningCustomer(
      ticket_id: $ticket_id
      ticketReturningCustomer: { phone_number: $phone_number }
    ) {
      message
      node {
        city
        licence_plate
        ticket_id
        zip_code
        email
        fleet_manager_for {
            id
            name
            discount_coupon
            payer
        }
      }
      status
    }
  }
`;

export const submitTicket = gql`
  mutation submitTicket(
    $flag: String!
    $ticket_id: String!
    $address: String!
    $address2: String!
    $commentVal: String
    $email: String!
    $fullName: String!
    $phone: String!
    $preferredDate: String
    $preferredTime: String
    $prefferedTimeSlots: [String]
    $marketingPermission: String
    $service_dsc: String!
    $service_id: String!
    $zip_code: String!
    $licence_plate: String!
    $utmCampaign: String
    $utmSource: String
    $utmMedium: String
    $gclid: String
    $otherPayer: String
  ) {
    submitTicket(
      flag: $flag
      ticket_id: $ticket_id
      ticketSubmit: {
        contactData: {
          address: $address
          address2: $address2
          commentVal: $commentVal
          email: $email
          fullName: $fullName
          phone: $phone
          preferredDate: $preferredDate
          preferredTime: $preferredTime
          prefferedTimeSlots: $prefferedTimeSlots
          marketingPermission: $marketingPermission
          utmCampaign: $utmCampaign
          utmSource: $utmSource
          utmMedium: $utmMedium
          gclid: $gclid
          otherPayer: $otherPayer
        }
        servicesData: { service_dsc: $service_dsc, service_id: $service_id }
        locationVehicleData: {
          zip_code: $zip_code
          licence_plate: $licence_plate
        }
      }
    ) {
      message
      node {
        om_message
        success
        ticket_id
      }
      status
    }
  }
`;

export const getServiceCluster = gql`
  query getServiceCluster(
    $service_cluster: ServiceClusterType!
    $ticket_id: String!
  ) {
    getServiceCluster(
      serviceClusterInput: { service_cluster: $service_cluster }
      ticket_id: $ticket_id
    ) {
      message
      node {
        category_dsc
        service_subcategory {
          subcategory_dsc
          service_details {
            service_dsc
            service_id
            fix_price
            mileage
            total_price
            tyre_spec
            service_long_dsc
            hide_price
          }
        }
      }
      status
    }
  }
`;

export const getServiceById = gql`
  query getServiceById($service_id: String!, $ticket_id: String!) {
    getServiceById(service_id: $service_id, ticket_id: $ticket_id) {
      message
      node {
        more_info
      }
      status
    }
  }
`;

export const contactDetailsScreen = gql`
  query contactDetailsScreen($ticket_id: String!) {
    contactDetailsScreen(ticket_id: $ticket_id) {
      message
      status
      node {
        address
        address2
        calendar_days
        email
        full_name
        phone
      }
    }
  }
`;

export const computeService = gql`
  query computeService(
    $is_selected: Int!
    $service_id: String!
    $ticket_id: String!
    $mileage: String
    $tyre_data: TyreData
    $insurance_company: String
  ) {
    computeService(
      is_selected: $is_selected
      service_id: $service_id
      ticket_id: $ticket_id
      mileage: $mileage
      tyre_data: $tyre_data
      insurance_company: $insurance_company
    ) {
      status
      message
      node {
        campaign_code
        total_discount
        fee_amount
        location_text
        ticket_id
        total_price
        vehicle_text
        min_price
        root_missing
        service_details {
          sa_dsc
          sa_hours
          sa_id
          sa_missing
          sa_parts
          sa_price
        }
      }
    }
  }
`;

export const getInsuranceCompany = gql`
  query getInsuranceCompany {
    getInsuranceCompany {
      insuranceCompanyDsc
      insuranceCompanyId
    }
  }
`;

export const getCampaignCluster = gql`
  query getCampaignCluster {
    getCampaignCluster {
      campaign_code
    }
  }
`;

export const getCampaignById = gql`
  query getCampaignById($campaign_code: String!) {
    getCampaignById(campaign_code: $campaign_code) {
      status
      message
      node {
        campaign_code
        campaign_code_tag
        fixed_price
        work_labour
        spareparts
        fee
        service_id
        valid
      }
    }
  }
`;

export const plannerMatrics = gql`
  query plannerMatrics(
    $calendar_date: String!
    $mobile_web: String!
    $ticket_id: String!
    $zip_code: String!
    $user_address: String
  ) {
    plannerMatrics(
      calendar_date: $calendar_date
      mobile_web: $mobile_web
      ticket_id: $ticket_id
      zip_code: $zip_code
      user_address: $user_address
    ) {
      node {
        date
        date_dsc
        slots {
          enabled
          endTime
          id
          startTime
        }
      }
      status
      message
    }
  }
`;

export const computeCampaign = gql`
  query computeCampaign($ticket_id: String!, $campaign_code: String!) {
    computeCampaign(ticket_id: $ticket_id, campaign_code: $campaign_code) {
      status
      message
      node {
        campaign_code
        total_discount
        fee_amount
        location_text
        ticket_id
        total_price
        vehicle_text
        min_price
        root_missing
        service_details {
          sa_dsc
          sa_hours
          sa_id
          sa_missing
          sa_parts
          sa_price
        }
      }
    }
  }
`;

export const zapierPost = gql`
  mutation zapierPost(
    # $zendesk_ticket_ID: String
    $zendesk_ticket_ID: String
    $address: String
    $city: String
    $email: String
    $gclid: String
    $name: String
    $number_plate: String
    $phone_number: String
    $price_of_booking: String
    $utm_campaign: String
    $utm_medium: String
    $utm_source: String
    $zip_code: String
    $success: String
    $marketingPermission: String
  ) {
    zapierPost(
      # Zendesk_ticket_ID: $zendesk_ticket_ID
      zendesk_ticket_ID: $zendesk_ticket_ID
      address: $address
      city: $city
      email: $email
      gclid: $gclid
      name: $name
      number_plate: $number_plate
      phone_number: $phone_number
      price_of_booking: $price_of_booking
      utm_campaign: $utm_campaign
      utm_medium: $utm_medium
      utm_source: $utm_source
      zip_code: $zip_code
      success: $success
      marketingPermission: $marketingPermission
    ) {
      message
      node {
        zapierStatus
      }
      status
    }
  }
`;

export const updateZipCode = gql`
  mutation updateZipCode($ticket_id: String!, $zip_code: String!) {
    updateZipCode(ticket_id: $ticket_id, zip_code: $zip_code) {
      message
      status
      node {
        campaign_code
        fee_amount
        location_text
        min_price
        root_missing
        service_details {
          sa_dsc
          sa_hours
          sa_id
          sa_missing
          sa_parts
          sa_price
        }
        ticket_id
        total_discount
        total_price
        vehicle_text
      }
    }
  }
`;
