import { Grid, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import PaperPlane from "../../../../assets/illustrations/awesome-paper-plane.svg";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStylesMobileSummaryCheckout } from "../../Styles/SummaryCheckoutStyles";

const HeaderTechnicalMobile: FC = () => {
  const { state } = useContext(GlobalContext);
  const classes = useStylesMobileSummaryCheckout();

  return (
    <Grid item container md={12} className={classes.headerWrapper}>
      <Grid item container md={12} className={classes.headerImage}>
        <img src={PaperPlane} className={classes.headerImageWidth} />
      </Grid>
      <div className={classes.headerTitleWrapper}>
        <Typography className={classes.headerTitleText}>
          {"Tak for din forespørgsel!"}
        </Typography>
      </div>
      {state.submitTicketRes?.node &&
      state.submitTicketRes?.node?.om_message?.trim() !== "" ? (
        <div className={classes.headerTitleWrapper}>
          <Typography className={classes.missingItemText}>
            {"Minimumskøb på 698 DKK for online booking er ikke opnået."}
          </Typography>
        </div>
      ) : null}
      <div className={classes.headerDescription}>
        <Typography className={classes.headerDescriptionText}>
          {
            "Vi kontakter dig inden for 24 timer på hverdage med et godt tilbud."
          }
        </Typography>
      </div>
    </Grid>
  );
};

export default HeaderTechnicalMobile;
