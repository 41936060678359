/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import VehicleWrapper from "../components/wrappers/VehicleWrapper";
import ServicesWrapper from "../components/wrappers/ServicesWrapper";
import ClusterWrapper from "../components/wrappers/ClusterWrapper";
import ServiceDetailsCalendar from "../components/ServiceDetailsCalendar/ServiceDetailsCalendar";
import SummaryCheckout from "../components/SummaryCheckout/SummaryCheckout";
import TyresWrapperMobile from "../components/ServicesCluster/TabPanels/TyresTabPanel/TyresMobile/TyresWrapperMobile";
import { isMobile } from "react-device-detect";
import ServicesWrapperMobile from "../components/Services/ServicesMobile/ServicesWrapperMobile";
import RepairsWrapperMobile from "../components/ServicesCluster/TabPanels/RepairsTabPanel/RepairsTabPanelMobile/wrappersMobile/RepairsWrapperMobile";
import WindshieldMobile from "../components/ServicesCluster/TabPanels/WindshieldGlassTabPanel/WindshieldMobile/WindshieldMobile";
import PrepurchaseInspectionMobile from "../components/ServicesCluster/TabPanels/PrePurchaseInspections/PrepurchaseInspectionsMobile/PrepurchaseInspectionMobile";
import ServicingAndMotMobile from "../components/ServicesCluster/TabPanels/ServicingAndMotTabPanel/ServicingAndMotTabPanelMobile/ServicingAndMotMobile";
import TechnicalAssistanceMobile from "../components/library-components/Dialogs/TechincalAssistance/TechnicalAssistanceMobile/TechnicalAssistanceMobile";
import BasketMobileContent from "../components/Summary/BasketMobile/BasketMobileContent";
import SummaryCheckoutMobile from "../components/SummaryCheckout/SummaryCheckoutMobile/SummaryCheckoutMobile";
import ReturningCustomerMobile from "../components/library-components/Dialogs/ReturningCustomer/ReturningCustomerMobile/ReturningCustomerMobile";
import ServicesClusterTabsMobileWrapper from "../components/ServicesCluster/ServicesClusterTabsMobileWrapper";

export interface RouteI {
  route: string;
  component: React.FC<any>;
  exact: boolean;
}

export const Routes: RouteI[] = [
  {
    route: "/",
    component: VehicleWrapper,
    exact: true,
  },
  {
    route: "/?:slug",
    component: VehicleWrapper,
    exact: true,
  },
  {
    route: "/services",
    component: !isMobile ? ServicesWrapper : ServicesWrapperMobile,
    exact: true,
  },
  {
    route: "/services/repairs",
    component: !isMobile ? ClusterWrapper : ServicesClusterTabsMobileWrapper,
    exact: true,
  },
  {
    route: "/services/glass",
    component: !isMobile ? ClusterWrapper : ServicesClusterTabsMobileWrapper,
    exact: true,
  },
  {
    route: "/services/servicing",
    component: !isMobile ? ClusterWrapper : ServicesClusterTabsMobileWrapper,
    exact: true,
  },
  {
    route: "/services/tyres",
    component: !isMobile ? ClusterWrapper : ServicesClusterTabsMobileWrapper,
    exact: true,
  },
  {
    route: "/basket",
    component: !isMobile ? React.Fragment : BasketMobileContent,
    exact: true,
  },
  {
    route: "/services/inspections",
    component: !isMobile ? ClusterWrapper : ServicesClusterTabsMobileWrapper,
    exact: true,
  },
  {
    route: "/assistance",
    component: TechnicalAssistanceMobile,
    exact: true,
  },
  {
    route: "/services/checkout",
    component: ServiceDetailsCalendar,
    exact: true,
  },
  {
    route: "/returning",
    component: ReturningCustomerMobile,
    exact: true,
  },
  {
    route: "/summary",
    component: !isMobile ? SummaryCheckout : SummaryCheckoutMobile,
    exact: true,
  },
];
