import {
  Grid,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import ActionCard from "../../../ActionCard/ActionCard";
import Header from "./SubComponents/Header";
import Content from "./SubComponents/Content";
import Actions from "./SubComponents/Actions";
import { CardStyleType } from "../../../ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import { isMobile } from "react-device-detect";
import TrustBox from "../../../../TrustBox/TrustBox";

const cardStylesMobile: CardStyleType = {
  header: {
    padding: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "90vh",
    width: isMobile ? "90vw" : "100%",
    justifyContent: "center",
    alignItems: "center",
    // height: "80vh",
  },
  boxHeight: {},
  boxWidth: {
    // height: "81.02564102564102vh",
    maxHeight: "90vh",
    // height: "80vh",
    display: "flex",
    flexDirection: "column",
  },
};

const ReturningCustomerMobile: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [number, setNumber] = useState("");

  const [error, setError] = useState(state.returningCustomerError?.error);

  useEffect(() => {
    dispatch({ type: "RETURNING_CUSTOMER_ERROR", payload: { error } });
  }, [error]);
  
  useEffect(()=> {
    setNumber(state.queryPhoneNumber?.phoneNumber)
  }, [state.queryPhoneNumber])

  return (
    <Grid
      item
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <TrustBox />
      </div>
      <ActionCard
        Header={<Header />}
        Content={<Content setNumber={setNumber} number={number} />}
        Actions={<Actions number={number} setNumber={setNumber} />}
        style={cardStylesMobile}
      />
    </Grid>
  );
};

export default ReturningCustomerMobile;
