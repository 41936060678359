/* eslint-disable @typescript-eslint/no-explicit-any */
import {BoldTextTypes, TabPanelProps} from "../interfaces/TabPanelInterfaces";
import {FormControlLabel, Grid, Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {FC, useContext, useEffect, useState} from "react";
import {computeService, getServiceCluster,} from "../../../../API/graphql-queries/Queries";
import {useLazyQuery} from "react-apollo";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import {GlobalContext} from "../../../../context/GlobalContext";
import RepairsServiceDialog from "../../../library-components/Dialogs/RepairsServiceDialog";
import ServicesInfoDialog from "../../../library-components/Dialogs/ServicesInfoDialog";
import {ServiceClusterType} from "../../enumerations/enumerations";
import Unchecked from "../../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../../assets/illustrations/checkboxChecked.svg";
import {ComputeServiceDataResponse} from "../../../../interfaces-and-unionTypes/Interfaces";
import ServiceLoaderDialog from "../../../library-components/Dialogs/ServiceLoaderDialog";
import {useStylesDesktopRepairs} from "./Styles/RepairsStyles";
import {useHistory} from "react-router-dom";
import AndetServiceDialog from "../../../library-components/Dialogs/AndetServiceDialog";

const Repairs: FC<TabPanelProps> = (props) => {
    const {index, value} = props;
    const history = useHistory();
    const [element, setElement] = useState("");
    const classes = useStylesDesktopRepairs();
    const [loadServiceCluster, resultLoad] =
        useLazyQuery<Partial<any>>(getServiceCluster);
    const {state, dispatch} = useContext(GlobalContext);
    const [subcategories, setSubcategories] = useState([]);
    const [infoCategory, setInfoCategory] = useState("");
    const [serviceDsc, setServiceDsc] = useState("");
    const [andetComment, setAndetComment] = useState(state?.andetComment);
    const [andetService, setAndetService] = useState('')

    const [readyToRender, setReadyToRender] = useState(false)
    useEffect(() => {
        loadServiceCluster({
            variables: {
                service_cluster: ServiceClusterType.Repairs,
                ticket_id: state.ticket?.node?.ticket_id || "",
            },
        });
    }, []);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAndetDialog, setOpenAndetDialog] = useState(false);
    const [openServiceInfoDialog, setOpenServiceInfoDialog] = useState(false);
    const [basket, setBasket] = useState(state.basketData?.service_details || []);
    const [andetSave, setAndetSave] = useState("");

    const [computedService, result] = useLazyQuery<
        Partial<ComputeServiceDataResponse>
    >(computeService, {
        onCompleted: (data) => {
            dispatch({type: "ADD_TO_BASKET", payload: data.computeService?.node});
            setBasket(data.computeService?.node?.service_details || []);
            setLoading(false);
        },
        fetchPolicy: "no-cache",
    });
    useEffect(() => {
        function checkSaDsc() {
            for (const entry of state?.basketData?.service_details) {
                if (entry?.sa_dsc === "Andet") {
                    return true;
                }
            }
            return false;
        }

        if (state?.basketData?.service_details) {
            if (!checkSaDsc()) {
                setAndetComment("")
                dispatch({type: "ANDET_COMMENT", payload: ""})
            }
        }
    }, [state?.basketData?.service_details])
    useEffect(() => {
        if (andetComment !== "" && !openAndetDialog && andetService !== "" && andetSave === "save") {
            setLoading(true);
            handleAddClick(andetService, 1);
            setAndetSave("");
        }
    }, [openAndetDialog])

    useEffect(() => {
        if (resultLoad?.data === null) {
            alert(resultLoad?.error?.message);
        } else {
            if (resultLoad?.data?.getServiceCluster?.node === null) {
                alert(resultLoad?.data?.getServiceCluster?.message);
            }
            if (resultLoad?.data?.getServiceCluster?.message === "Session expired") {
                alert("Session expired");
                history.push("/");
            }
        }
        if (state.queryParams) {
            resultLoad?.data?.getServiceCluster?.node?.map((el: any) => {
                el.service_subcategory?.map((sub: any) => {
                    if (sub.subcategory_dsc === "Andet") {
                        if (state.queryParams?.service_ids?.includes(sub?.service_details[0].service_id)) {
                            setOpenAndetDialog(true);
                            setSubcategories(sub);
                            setAndetService(sub.service_details[0].service_id)
                        }
                    }
                })
            })
        }
        setReadyToRender(resultLoad?.data?.getServiceCluster?.node?.length>0)
    }, [resultLoad?.data]);


    useEffect(() => {
        if (basket?.find((el: any) => el?.sa_id === state?.serviceId)) {
            dispatch({
                type: "REMOVE_SERVICE_FROM_BASKET",
                payload: "",
            });
            const removed = basket?.filter((el: any) => el?.sa_id !== state?.serviceId);
            setBasket(removed);
        }
    }, [state.serviceId, state.basketData]);

    const handleAddClick = (serviceId: string, checked: number) => {
        computedService({
            variables: {
                is_selected: checked,
                service_id: serviceId,
                ticket_id: state.ticket?.node.ticket_id || "",
                milage: "0",
                tyre_data: null,
            },
        });
    };

    const handleDialog = (serviceId: string, dsc: string) => {
        setInfoCategory(serviceId);
        setServiceDsc(dsc);
        setOpenServiceInfoDialog(true);
    };


    const [loading, setLoading] = useState(false);

    const exsistsInBasket = (serviceId: string) => {
        return !!basket?.find((el: any) => el.sa_id === serviceId);
    };

    const checkDefaultExpanded = (el:any): boolean  => {
        if(el.service_subcategory) {
            for (const sub of el.service_subcategory) {
                if(sub.service_details) {
                    for (const sd of sub.service_details) {
                        if (exsistsInBasket(sd.service_id)) {
                            return true;
                        }
                        if (state.queryParams?.service_ids?.includes(sd.service_id)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    const checkChecked = (sub: any) => {
        if (sub?.service_details.length > 0) {
            for (let i = 0; i < sub?.service_details.length; i++) {
                if (exsistsInBasket(sub?.service_details[i].service_id)) {
                    return true;
                }
                if (state.queryParams?.service_ids?.includes(sub?.service_details[i].service_id)) {
                    return true;
                }
            }
        }
        return false;
    };



    return (
        <Grid
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            item
            container
            md={12}
            className={classes.root}
        >
            {value === index && (
                <div className={classes.wrapper}>
                    <div className={classes.itemsWrapper}>
                        <div className={classes.wrapperMargin}>
                            {readyToRender && resultLoad?.data?.getServiceCluster?.node?.map((el: any) => {
                                return (
                                    <Accordion
                                        defaultExpanded={checkDefaultExpanded(el)}
                                        key={el.category_dsc}
                                        className={classes.accordion}
                                        onChange={() => {
                                            setElement(el.category_dsc);
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className={classes.accordionSummary}
                                        >
                                            <Typography
                                                fontWeight={500}
                                                fontSize={12}
                                            >
                                                {el.category_dsc}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {el.service_subcategory?.map((sub: any) => {
                                                return (
                                                    <Typography
                                                        className={classes.detailsText}
                                                        key={sub.subcategory_dsc}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    className={classes.checkbox}
                                                                    icon={<img src={Unchecked}/>}
                                                                    checkedIcon={<img src={Checked}/>}
                                                                    defaultChecked={checkChecked(sub)}
                                                                    onChange={(e: any) => {
                                                                        if (sub?.service_details?.length > 1) {
                                                                            setOpenDialog(true);
                                                                            setSubcategories(sub);
                                                                        } else if (sub.subcategory_dsc === "Andet" && e.target.checked) {
                                                                            setOpenAndetDialog(true);
                                                                            setSubcategories(sub);
                                                                            setAndetService(sub.service_details[0].service_id)
                                                                        } else {
                                                                            setLoading(true);
                                                                            handleAddClick(
                                                                                sub?.service_details[0].service_id,
                                                                                e.target.checked ? 1 : 0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <Typography fontWeight={600} fontSize={12}
                                                                            component="span">
                                                                    {sub.subcategory_dsc}
                                                                </Typography>
                                                            }
                                                        />{" "}
                                                        {sub.subcategory_dsc !== "Other" && (
                                                            <InfoIcon
                                                                onClick={() => {
                                                                    handleDialog(
                                                                        sub.service_details[0].service_id,
                                                                        sub.subcategory_dsc
                                                                    );
                                                                }}
                                                                className={classes.infoIcon}
                                                            />
                                                        )}
                                                    </Typography>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            <RepairsServiceDialog
                open={openDialog}
                close={(value) => {
                    setOpenDialog(!openDialog);
                    setBasket(value);
                }}
                content={subcategories}
            />
            <AndetServiceDialog
                open={openAndetDialog}
                close={() => {
                    setOpenAndetDialog(!openAndetDialog);
                }}
                save={(value) => {
                    setAndetComment(value)
                }}
                andetComment={andetComment}
                content={subcategories}
                setAndetSave={(value) => setAndetSave(value)}
            />
            <ServicesInfoDialog
                open={openServiceInfoDialog}
                close={() => {
                    setOpenServiceInfoDialog(!openServiceInfoDialog);
                }}
                content={{service_id: infoCategory, service_dsc: serviceDsc}}
            />
            <ServiceLoaderDialog
                open={loading}
                close={() => {
                    return;
                }}
            />
        </Grid>
    );
};

export default Repairs;
