import React, { useContext, useEffect, useState, FC } from "react";
import { useStylesDesktop } from "./NavbarStyle";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  ClickAwayListener,
  Grid,
  Step,
  StepButton,
  Stepper,
  Tooltip,
} from "@mui/material";
import { GlobalContext } from "../../../context/GlobalContext";
import { checkpoints } from "./checkpoints";
const ProgressTrackDesktop: FC = () => {
  const classes = useStylesDesktop();
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const { state } = useContext(GlobalContext);

  const [activeStep, setActiveStep] = useState(-1);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const location = useLocation();

  const totalSteps = () => {
    return checkpoints.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? checkpoints.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    handleComplete();
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  useEffect(() => {
    let current = 0;
    if (location.pathname === "/" || location.pathname === "/services") {
      if (activeStep !== 0) {
        setActiveStep(0);
        current = 0;
      }
    } else if (
      location.pathname.includes("repairs") ||
      location.pathname.includes("glass") ||
      location.pathname.includes("servicing") ||
      location.pathname.includes("tyres") ||
      location.pathname.includes("inspections")
    ) {
      if (activeStep !== 1) {
        setActiveStep(1);
        current = 1;
      }
    } else if (location.pathname.includes("checkout")) {
      if (activeStep !== 2) {
        setActiveStep(2);
        current = 2;
      }
    } else if (location.pathname.includes("summary")) {
      if (activeStep !== 3) {
        setActiveStep(3);
        current = 3;
      }
    }
    for (let i = 0; i <= current; i++) {
      completed[i] = true;
    }
  }, [location]);

  const protectedRoutes = () => {
    if (
      state.ticket?.node?.ticket_id?.trim() === "" ||
      (state.userLocation?.node === null && state.licence?.node === null)
    ) {
      return checkpoints.map((label, index) => (
        <Step key={label.route} completed={completed[index]}>
          <Link key={label.route} to={{ pathname: "/" }}>
            <StepButton color="inherit"></StepButton>
          </Link>
        </Step>
      ));
    } else {
      return checkpoints.map((label, index) => (
        <Step key={label.route} completed={completed[index]}>
          <Link
            key={label.route}
            to={{
              pathname:
                (!state.basketData ||
                  !state.basketData?.service_details ||
                  state.basketData?.service_details?.length < 1) &&
                index === 3
                  ? ""
                  : ((state.basketData === null ||
                      state.basketData === undefined) &&
                      // state.basketData === {}
                      index == 3) ||
                    (completed[3] === true &&
                      window.location.pathname.includes("summary"))
                  ? ""
                  : label.route,
            }}
          >
            {index === 3 ? (
              <Grid
                item
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "2%",
                }}
              >
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      arrow={true}
                      title={
                        "Tilføj noget til kurven for at forstætte til checkout"
                      }
                    >
                      <div>
                        <StepButton
                          color="inherit"
                          onClick={() => {
                            if (
                              (!state.basketData ||
                                !state.basketData?.service_details ||
                                state.basketData?.service_details?.length <
                                  1) &&
                              index === 3
                            ) {
                              handleTooltipOpen();
                            }
                            // handleStep(index);
                          }}
                        ></StepButton>
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </Grid>
            ) : (
              <StepButton
                color="inherit"
                onClick={() => {
                  // if (
                  //   (!state.basketData ||
                  //     !state.basketData?.service_details ||
                  //     state.basketData?.service_details?.length < 1) &&
                  //   index === 3
                  // ) {
                  //   handleTooltipOpen();
                  // }
                  handleStep(index);
                }}
              ></StepButton>
            )}
          </Link>
        </Step>
      ));
    }
  };

  return (
    <Grid item container md={9} className={classes.root}>
      <Box className={classes.stepperBox}>
        <Stepper className={classes.stepper} nonLinear activeStep={activeStep}>
          {protectedRoutes()}
        </Stepper>
      </Box>
    </Grid>
  );
};

export default ProgressTrackDesktop;
