import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { InputFieldProps } from "./Types/InputFieldTypes";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Styles/mui-theme";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    width: isMobile ? "80vw" : "27vw",
    "& .MuiOutlinedInput-input": {
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px",
    },
    // "& .Mui-focused": {
    //   borderColor: theme.palette.primary.main
    // }
  },
});

const formStyles = makeStyles({
  root: {
    marginLeft: "3px",
  },
});

const InputField: React.FC<InputFieldProps> = (props) => {
  const styles = useStyles();

  const formStyle = formStyles();

  return (
    <TextField
      classes={{
        root: `${
          (styles.root,
          props?.classesProp !== undefined ? props?.classesProp : styles.root)
        }`,
      }}
      type={props.type}
      variant={props.variant}
      label={props.label}
      placeholder={props.placeholder}
      onKeyPress={props.onKeyPress}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onBlurCapture={props.onBlurCapture}
      onFocusCapture={props.onFocusCapture}
      disabled={props.disabled}
      required={props.required}
      value={props.value}
      inputProps={{
        ...props.inputProps,
        maxLength: props.maxLength,
        style: props.style,
      }}
      InputProps={props.InputProps}
      error={props.error}
      helperText={props.helperText}
      multiline={props.multiline}
      rows={props.rows}
      inputRef={props.inputRef}
      focused={props.focused}
    />
  );
};

export default InputField;
