import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import carImage from "../../../assets/illustrations/not-sure-whats-wrong-car.svg";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";
import { TechnicalAssistanceDialog } from "../../library-components/Dialogs/TechincalAssistance/TechnicalAssistanceDialog";
import { useStylesDesktop } from "../Styles/ZipCodeVehicleStyle";

const Assistance: FC = () => {
  const classes = useStylesDesktop();
  const { t } = useTranslation();
  const { dispatch } = useContext(GlobalContext);

  const [openDialog, setOpenDialog] = useState(false);

  const header = () => {
    return (
      <>
        <div className={`${classes.width100percent} ${classes.flexCenter}`}>
          <Typography
            fontSize="14px"
            fontFamily={"Poppins, SemiBold"}
            fontWeight={"bold"}
          >
            {t("notSureWhatsWrongTitle-zipCode")}
          </Typography>
        </div>
      </>
    );
  };

  const content = () => {
    return (
      <div className={classes.flexCenter}>
        <Typography
          textAlign={"center"}
          fontFamily={"Poppins, SemiBold"}
          fontSize={12}
        >
          {t("notSureWhatsWrongDescription-zipCode")}
          <span>
            <LinkButton
              onClick={() => {
                setOpenDialog(true);
              }}
              classes={`${classes.linkStyle}`}
            >
              {t("formular")}
            </LinkButton>
          </span>
          {t("notSureWhatsWrongDescription-zipCodeContinued")}
        </Typography>
      </div>
    );
  };

  const actions = () => {
    return (
      <div className={`${classes.actionsWrapperPadding} ${classes.flexCenter}`}>
        <Typography
          fontFamily={"Poppins, Normal"}
          fontSize={"14px"}
          dangerouslySetInnerHTML={{
            __html: t("notSureWhatsWrongAction-zipCode"),
          }}
        ></Typography>
      </div>
    );
  };

  return (
    <Grid
      className={`${(classes.zeroMarginPadding, classes.flexCenter)}`}
      item
      container
      md={12}
    >
      <div className={classes.border}></div>
      <Grid
        item
        container
        className={`${classes.flexCenter}, ${classes.paddingBottomHeaderGrid}`}
        style={{}}
      >
        {header()}
      </Grid>
      <Grid
        item
        container
        className={`${classes.flexCenter}, ${classes.gridContent}`}
      >
        {content()}
      </Grid>
      <Grid
        item
        container
        className={`${classes.flexCenter}, ${classes.paddingBottomActionsGrid}`}
      >
        {actions()}
      </Grid>
      <TechnicalAssistanceDialog
        open={openDialog}
        close={(value) => {
          if (value === true) {
            dispatch({
              type: "CLEAR_ZIP_CODE_AND_LICENCE_FORM",
              payload: undefined,
            });
          }
          setOpenDialog(!openDialog);
        }}
        flag={"V"}
      />
    </Grid>
  );
};

export default Assistance;
