import { makeStyles } from "@mui/styles";

export const useStylesDesktopHeader = makeStyles({
  root: {
    paddingTop: "6vh",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  subtitleWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2vh",
  },
});
