import { Grid } from "@mui/material";
import React, { FC} from "react";
import { PrePurchaseInspectionsWrapperProps } from "../interfaces/interfaces";
import PrePurchaseInspectionsPanel from "../PrePurchaseInspectionsPanel";
import PrePurchaseInspectionsCartOptionsTab from "../SubComponents/PrePurchaseInspectionsCartOptionsTab";
import PrePurchaseInspectionsCompareTable from "../SubComponents/PrePurchaseInspectionsCompareTable";

const PrePurchaseInspectionWrapper: FC<PrePurchaseInspectionsWrapperProps> = (
  props
) => {
  const { value, items } = props;

  return (
    <Grid>
      <PrePurchaseInspectionsPanel index={3} value={value} >
        <>
          <PrePurchaseInspectionsCartOptionsTab items={items} />
          <PrePurchaseInspectionsCompareTable />
        </>
      </PrePurchaseInspectionsPanel>
    </Grid>
  );
};

export default PrePurchaseInspectionWrapper;
