import { makeStyles } from "@mui/styles";
import { isDesktop } from "react-device-detect";

export const useStyles = makeStyles({
  main: {
    background: isDesktop ? "#F5F5F5" : "#FFFFFF",
    paddingBottom: "13vh",
    minHeight: "100vh",
  },
});
