import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const Header: FC = () => {
  const { t } = useTranslation();
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        WebkitJustifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        WebkitAlignContent: "center",
        WebkitAlignItems: "center",
      }}
    >
      <Typography
        style={{
          textAlign: "center",
          fontWeight: 600,
          marginTop: "20px",
        }}
      >
        {t("dialogTitleB2bCustomer")}
      </Typography>
    </Grid>
  );
};

export default Header;
